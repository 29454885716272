import {
    Component, EventEmitter, Input, OnChanges, Output, SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { ApolloService, getFiles_auth_files, getProductFiles_product_files } from 'src/gql-generated/generated';
import { UserService } from 'src/app/services/user.service';
import * as JSZip from 'jszip';
import { FileService } from 'src/app/services/file.service';
import { SmartSelectSettings } from '../../smart-select/smart-select.component';

@Component({
    selector: 'app-product-attachments',
    templateUrl: './product-attachments.component.html',
    styleUrls: ['./product-attachments.component.scss'],
})
export class ProductAttachmentsComponent implements OnChanges {
    /**
     * Formgroup containing a formcontrol for each technical part
     */
    attachmentsFormGroup = new FormGroup({
        TESTING_CONDITIONS: new FormControl<getFiles_auth_files | null>(null, Validators.required),
        CALCULATIONS: new FormControl<getFiles_auth_files | null>(null, Validators.required),
        GENERAL_DESCRIPTION: new FormControl<getFiles_auth_files | null>(null, Validators.required),
        MESURED_TECHNICAL_PARAMETERS: new FormControl<getFiles_auth_files | null>(null, Validators.required),
        REFERENCES_TO_HARMONISED_STANDARDS: new FormControl<getFiles_auth_files | null>(null, Validators.required),
        SPECIFIC_PRECAUTIONS: new FormControl<getFiles_auth_files | null>(null, Validators.required),
        // ADDITIONAL_PART is the only technical part that is not requried
        ADDITIONAL_PART: new FormControl<getFiles_auth_files | null>(null),
    });

    /**
     * Defines the order of the listed technical parts
     */
    technicalPartOrder = ['TESTING_CONDITIONS', 'CALCULATIONS', 'GENERAL_DESCRIPTION', 'MESURED_TECHNICAL_PARAMETERS',
        'REFERENCES_TO_HARMONISED_STANDARDS', 'SPECIFIC_PRECAUTIONS', 'ADDITIONAL_PART'];

    /**
     * Mapping of technical part to human readable name
     */
    technicalPartDescriptions = {
        TESTING_CONDITIONS: 'Testing conditions',
        CALCULATIONS: 'Calculations',
        GENERAL_DESCRIPTION: 'General description',
        MESURED_TECHNICAL_PARAMETERS: 'Measured technical parameters',
        REFERENCES_TO_HARMONISED_STANDARDS: 'References to harmonised standards',
        SPECIFIC_PRECAUTIONS: 'Specific precautions',
        ADDITIONAL_PART: 'Additional part',
    };

    /**
     * Will contain a SmartSelect for each technical part after techical parts are loaded
     */
    smartSelects: { [key: string]: SmartSelectSettings } = {};

    /**
     * Product id
     */
    @Input() productId?: string;

    /**
     * Let parent component reload data
     */
    @Output() refresh = new EventEmitter<void>();

    /**
     * Current productFiles for product
     */
    productFiles?: getProductFiles_product_files[];

    constructor(
        private apollo: ApolloService,
        private route: ActivatedRoute,
        private snackBar: MatSnackBar,
        private user: UserService,
        private fileService: FileService,
    ) {

    }

    /**
     * Attempts to save updated productFiles
     */
    async updateFiles(): Promise<void> {
        try {
            // Determine which technical parts have been updated
            const updatedTechnicalParts = Object.entries(this.attachmentsFormGroup.value)
                .filter(([key, value]) => !this.productFiles?.find(
                    (productFile) => productFile.file_id === value?.id && productFile.technical_part === key,
                ));

            // Update product files. This will delete all existing product files for the product and insert new ones.
            // This will also insert an update with the old and new file names for each changed technical part.
            // All operations happen in a single mutation to avoid partial/broken updates
            const result = await firstValueFrom(this.apollo.updateProductFiles({
                // Product id for which all productFiles will be deleted
                productId: this.productId,

                // Create update with all changed technical parts
                update: {
                    user_id: this.user.profile.id,
                    product_id: this.productId,
                    UpdateChanges: {
                        data: updatedTechnicalParts.map(([key, value]) => ({
                            key,
                            previous_value: this.productFiles?.find((productFile) => productFile.technical_part === key)?.File.name || null,
                            value: value?.name || null,
                        })),
                    },
                },

                // New product files
                newProductFiles: Object.entries(this.attachmentsFormGroup.value).map(([key, value]) => ({
                    file_id: value?.id,
                    product_id: this.productId,
                    technical_part: key,
                })).filter((productFile) => productFile.file_id),
            }));
            if (result.data?.insert_product_files) {
                this.snackBar.open('Attachments saved', 'Close');
                this.refresh.next();
            } else {
                this.snackBar.open('Could not save attachments', 'Close');
            }
        } catch (e) {
            this.snackBar.open('Could not save attachments', 'Close');
        }
    }

    /**
     * Creates new smartSelects for all technical parts
     */
    refreshSmartSelects(): void {
        for (const key of Object.keys(this.attachmentsFormGroup.controls)) {
            this.smartSelects[key] = {
                control: (this.attachmentsFormGroup.controls as any)[key],
                label: (this.technicalPartDescriptions as any)[key],
                query: this.apollo.getFiles.bind(this.apollo),
                queryErrorMessage: 'Could not load files',
                invalidControlErrorMessage: 'Field is required',
            };
        }
    }

    async downloadZip(): Promise<void> {
        // Find unique files used by product
        const uniqueFiles = [...new Map(Object.values(this.attachmentsFormGroup.value).filter(Boolean).map((file) => [file?.id, file])).values()];

        // Create blobs for all files
        const blobs = await Promise.all(uniqueFiles.map((file) => this.fileService.getFileAsBlob(file!.path)));

        // Initialize zip
        const zip = new JSZip();

        // Add attachments
        uniqueFiles.forEach((file, index) => zip.file(`attachments/${file?.name}`, blobs[index]!));

        // Add product XML file
        zip.file('products.xml', 'fake XML contents');

        // Generate and download zip
        zip.generateAsync({ type: 'blob' }).then((blob) => {
            // Zip name
            const zipName = 'test.zip';

            // Temporarily add an <a> element and click it to download the zip
            if (blob) {
                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(blob);
                downloadLink.setAttribute('download', zipName);
                document.body.appendChild(downloadLink);
                downloadLink.click();
                downloadLink.remove();
            } else {
                this.snackBar.open(`Could not download ${zipName}`, 'Close');
            }
        });
    }

    ngOnChanges(): void {
        if (this.productId) {
            // Get product files if URL contains product id
            firstValueFrom(this.apollo.getProductFiles({
                productId: this.productId,
            })).then((result) => {
                if (result.data.product_files) {
                    // Store productFiles
                    this.productFiles = result.data.product_files;

                    // Initialize formgroup with correct values
                    this.productFiles.forEach((productFile) => {
                        if (this.attachmentsFormGroup.get(productFile.technical_part)) {
                            this.attachmentsFormGroup.get(productFile.technical_part)!.setValue(productFile.File);
                        }
                    });

                    // Update smartSelects
                    this.refreshSmartSelects();
                }
            }).catch(() => {
                this.snackBar.open('Could not load product attachments', 'Close');
            });
        }
    }
}
