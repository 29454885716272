import {
    Component, EventEmitter, Input, Output, TemplateRef, ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-dynamic-dialog',
    templateUrl: './dynamic-dialog.component.html',
    styleUrls: ['./dynamic-dialog.component.scss'],
})
export class DynamicDialogComponent {
    /**
     * Reference to template
     */
    @ViewChild('template') template?: TemplateRef<any>;

    /**
     * Max width of dialog
     */
    @Input() maxWidth = 400;

    /**
     * Do not allow the user to close the dialog by clicking the backdrop
     */
    @Input() disableClose = false;

    /**
     * Emit value on close
     */
    @Output() onClose = new EventEmitter<any>();

    constructor(private dialog: MatDialog) { }

    /**
     * Opens the dialog
     */
    async open(): Promise<any> {
        if (this.template) {
            const result = await firstValueFrom(this.dialog.open(this.template, {
                autoFocus: false,
                maxWidth: this.maxWidth,
                disableClose: this.disableClose,
            }).afterClosed());
            this.onClose.next(result);
            return result;
        }
        return false;
    }
}
