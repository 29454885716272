<ng-container *ngIf="item" [ngSwitch]="item.typeProperty.data_type">

    <div fxLayout="row" fxLayoutAlign="start stretch">

        <div fxLayout="row" fxLayoutAlign="space-between start" class="property-row">

            <!-- Text input fields -->
            <mat-form-field *ngSwitchCase="'text'" fxFlex>
                <mat-label i18n>{{item.typeProperty.description}}</mat-label>
                <input matInput [formControl]="item.control">
            </mat-form-field>

            <!-- Number input fields -->
            <mat-form-field *ngSwitchCase="'number'" fxFlex>
                <mat-label i18n>{{item.typeProperty.description}}</mat-label>
                <input matInput type="number" [step]="1" [required]="item.typeProperty.required"
                    [formControl]="item.control">
            </mat-form-field>

            <!-- Boolean input fields -->
            <mat-radio-group class="radio-group" aria-label="Select an option" *ngSwitchCase="'boolean'"
                [formControl]="item.control" fxFlex>
                <p class="checktext" [class.error]="item.control.invalid && item.control.touched">
                    {{item.typeProperty.description}}<span *ngIf="item.typeProperty.required">*</span></p>
                <mat-radio-button value="0">False</mat-radio-button>
                <mat-radio-button value="1">True</mat-radio-button>
                <mat-radio-button *ngIf="!item.typeProperty.required">NULL</mat-radio-button>
            </mat-radio-group>

            <!-- List input fields -->
            <mat-form-field *ngSwitchCase="'list'" fxFlex>
                <mat-label>{{item.typeProperty.description}}</mat-label>
                <mat-select [formControl]="item.control" [required]="item.typeProperty.required">
                    <mat-option>--</mat-option>
                    <mat-option *ngFor="let item of item.typeProperty.ItemList?.Items"
                        [value]="item.value">{{item.value}}</mat-option>
                </mat-select>
            </mat-form-field>

            <!-- Number input fields -->
            <mat-form-field *ngSwitchCase="'date'" fxFlex>
                <mat-label>{{item.typeProperty.description}}</mat-label>
                <input [formControl]="item.control" matInput [matDatepicker]="picker"
                    [required]="item.typeProperty.required">
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <button mat-stroked-button *ngIf="item.integer !== 0 && item.typeProperty.multiple" class="delete-button"
                (click)="delete.next()">Delete</button>
        </div>


        <!-- Message for when the csv has changed-->
        <div class="notification" fxLayout="row" fxLayoutAlign="space-between center"
            *ngIf="item.newCsvValue && item.productProperty" fxFlex>
            <div class="notification-text">
                <p>CSV value has changed to <span class="accent-color">{{item.newCsvValue}}</span>
                    since the last edit of this product</p>

                <span class="selectable-text" (click)="useNew()" i18n>Use CSV value</span>
                <span class="selectable-text" (click)="keepOld()" i18n>Keep current</span>
            </div>
            <mat-icon>error_outline</mat-icon>
        </div>

    </div>

    <!-- A list of subitems is conditionaly displayed in case the parent input has the required value -->
    <ng-container *ngIf="item.subItems">
        <ng-container *ngFor="let subitem of item.subItems">
            <app-product-detail-input class="subfields"
                *ngIf="!subitem.typeProperty.parent_property_value || item.control.value == subitem.typeProperty.parent_property_value"
                [item]="subitem"></app-product-detail-input>
        </ng-container>
    </ng-container>

</ng-container>