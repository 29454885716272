/* eslint-disable */
import type { OperationVariables } from '@apollo/client/core';
import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
import * as ApolloCore from '@apollo/client/core';
export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  bigint: { input: number; output: number; }
  citext: { input: string; output: string; }
  jsonb: { input: any; output: any; }
  timestamp: { input: any; output: any; }
  timestamptz: { input: string; output: string; }
  uuid: { input: string; output: string; }
}

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export interface Boolean_Comparison_Exp {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export interface Int_Comparison_Exp {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export interface String_Comparison_Exp {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
}

export interface Auth_Account_Roles_Aggregate_Bool_Exp {
  count?: InputMaybe<Auth_Account_Roles_Aggregate_Bool_Exp_Count>;
}

export interface Auth_Account_Roles_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<Auth_Account_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Auth_Account_Roles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** order by aggregate values of table "auth.account_roles" */
export interface Auth_Account_Roles_Aggregate_Order_By {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Auth_Account_Roles_Max_Order_By>;
  min?: InputMaybe<Auth_Account_Roles_Min_Order_By>;
}

/** input type for inserting array relation for remote table "auth.account_roles" */
export interface Auth_Account_Roles_Arr_Rel_Insert_Input {
  data: Array<Auth_Account_Roles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Account_Roles_On_Conflict>;
}

/** Boolean expression to filter rows from the table "auth.account_roles". All fields are combined with a logical 'AND'. */
export interface Auth_Account_Roles_Bool_Exp {
  Context?: InputMaybe<Auth_Contexts_Bool_Exp>;
  _and?: InputMaybe<Array<Auth_Account_Roles_Bool_Exp>>;
  _not?: InputMaybe<Auth_Account_Roles_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Account_Roles_Bool_Exp>>;
  account?: InputMaybe<Auth_Accounts_Bool_Exp>;
  account_id?: InputMaybe<Uuid_Comparison_Exp>;
  context_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  roleByRole?: InputMaybe<Auth_Roles_Bool_Exp>;
}

/** unique or primary key constraints on table "auth.account_roles" */
export enum Auth_Account_Roles_Constraint {
  /** unique or primary key constraint on columns "id" */
  AccountRolesPkey = 'account_roles_pkey'
}

/** input type for inserting data into table "auth.account_roles" */
export interface Auth_Account_Roles_Insert_Input {
  Context?: InputMaybe<Auth_Contexts_Obj_Rel_Insert_Input>;
  account?: InputMaybe<Auth_Accounts_Obj_Rel_Insert_Input>;
  account_id?: InputMaybe<Scalars['uuid']['input']>;
  context_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  roleByRole?: InputMaybe<Auth_Roles_Obj_Rel_Insert_Input>;
}

/** order by max() on columns of table "auth.account_roles" */
export interface Auth_Account_Roles_Max_Order_By {
  account_id?: InputMaybe<Order_By>;
  context_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
}

/** order by min() on columns of table "auth.account_roles" */
export interface Auth_Account_Roles_Min_Order_By {
  account_id?: InputMaybe<Order_By>;
  context_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
}

/** on_conflict condition type for table "auth.account_roles" */
export interface Auth_Account_Roles_On_Conflict {
  constraint: Auth_Account_Roles_Constraint;
  update_columns?: Array<Auth_Account_Roles_Update_Column>;
  where?: InputMaybe<Auth_Account_Roles_Bool_Exp>;
}

/** Ordering options when selecting data from "auth.account_roles". */
export interface Auth_Account_Roles_Order_By {
  Context?: InputMaybe<Auth_Contexts_Order_By>;
  account?: InputMaybe<Auth_Accounts_Order_By>;
  account_id?: InputMaybe<Order_By>;
  context_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  roleByRole?: InputMaybe<Auth_Roles_Order_By>;
}

/** primary key columns input for table: auth.account_roles */
export interface Auth_Account_Roles_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** select columns of table "auth.account_roles" */
export enum Auth_Account_Roles_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role'
}

/** input type for updating data in table "auth.account_roles" */
export interface Auth_Account_Roles_Set_Input {
  account_id?: InputMaybe<Scalars['uuid']['input']>;
  context_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "auth_account_roles" */
export interface Auth_Account_Roles_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Auth_Account_Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Auth_Account_Roles_Stream_Cursor_Value_Input {
  account_id?: InputMaybe<Scalars['uuid']['input']>;
  context_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "auth.account_roles" */
export enum Auth_Account_Roles_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role'
}

export interface Auth_Account_Roles_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Account_Roles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Account_Roles_Bool_Exp;
}

export interface Auth_Accounts_Aggregate_Bool_Exp {
  bool_and?: InputMaybe<Auth_Accounts_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Auth_Accounts_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Auth_Accounts_Aggregate_Bool_Exp_Count>;
}

export interface Auth_Accounts_Aggregate_Bool_Exp_Bool_And {
  arguments: Auth_Accounts_Select_Column_Auth_Accounts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Auth_Accounts_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface Auth_Accounts_Aggregate_Bool_Exp_Bool_Or {
  arguments: Auth_Accounts_Select_Column_Auth_Accounts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Auth_Accounts_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface Auth_Accounts_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<Auth_Accounts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Auth_Accounts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** order by aggregate values of table "auth.accounts" */
export interface Auth_Accounts_Aggregate_Order_By {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Auth_Accounts_Max_Order_By>;
  min?: InputMaybe<Auth_Accounts_Min_Order_By>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface Auth_Accounts_Append_Input {
  custom_register_data?: InputMaybe<Scalars['jsonb']['input']>;
}

/** input type for inserting array relation for remote table "auth.accounts" */
export interface Auth_Accounts_Arr_Rel_Insert_Input {
  data: Array<Auth_Accounts_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Accounts_On_Conflict>;
}

/** Boolean expression to filter rows from the table "auth.accounts". All fields are combined with a logical 'AND'. */
export interface Auth_Accounts_Bool_Exp {
  _and?: InputMaybe<Array<Auth_Accounts_Bool_Exp>>;
  _not?: InputMaybe<Auth_Accounts_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Accounts_Bool_Exp>>;
  account_roles?: InputMaybe<Auth_Account_Roles_Bool_Exp>;
  account_roles_aggregate?: InputMaybe<Auth_Account_Roles_Aggregate_Bool_Exp>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  cron_states?: InputMaybe<Auth_Cron_State_Bool_Exp>;
  cron_states_aggregate?: InputMaybe<Auth_Cron_State_Aggregate_Bool_Exp>;
  custom_register_data?: InputMaybe<Jsonb_Comparison_Exp>;
  default_role?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<Citext_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  login_tokens?: InputMaybe<Auth_Login_Tokens_Bool_Exp>;
  login_tokens_aggregate?: InputMaybe<Auth_Login_Tokens_Aggregate_Bool_Exp>;
  mfa_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  new_email?: InputMaybe<Citext_Comparison_Exp>;
  otp_secret?: InputMaybe<String_Comparison_Exp>;
  password_hash?: InputMaybe<String_Comparison_Exp>;
  refresh_tokens?: InputMaybe<Auth_Refresh_Tokens_Bool_Exp>;
  refresh_tokens_aggregate?: InputMaybe<Auth_Refresh_Tokens_Aggregate_Bool_Exp>;
  role?: InputMaybe<Auth_Roles_Bool_Exp>;
  ticket?: InputMaybe<Uuid_Comparison_Exp>;
  ticket_expires_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
}

/** unique or primary key constraints on table "auth.accounts" */
export enum Auth_Accounts_Constraint {
  /** unique or primary key constraint on columns "email" */
  AccountsEmailKey = 'accounts_email_key',
  /** unique or primary key constraint on columns "new_email" */
  AccountsNewEmailKey = 'accounts_new_email_key',
  /** unique or primary key constraint on columns "id" */
  AccountsPkey = 'accounts_pkey',
  /** unique or primary key constraint on columns "user_id" */
  AccountsUserIdKey = 'accounts_user_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface Auth_Accounts_Delete_At_Path_Input {
  custom_register_data?: InputMaybe<Array<Scalars['String']['input']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface Auth_Accounts_Delete_Elem_Input {
  custom_register_data?: InputMaybe<Scalars['Int']['input']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface Auth_Accounts_Delete_Key_Input {
  custom_register_data?: InputMaybe<Scalars['String']['input']>;
}

/** input type for inserting data into table "auth.accounts" */
export interface Auth_Accounts_Insert_Input {
  account_roles?: InputMaybe<Auth_Account_Roles_Arr_Rel_Insert_Input>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  cron_states?: InputMaybe<Auth_Cron_State_Arr_Rel_Insert_Input>;
  custom_register_data?: InputMaybe<Scalars['jsonb']['input']>;
  default_role?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['citext']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  login_tokens?: InputMaybe<Auth_Login_Tokens_Arr_Rel_Insert_Input>;
  mfa_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  new_email?: InputMaybe<Scalars['citext']['input']>;
  otp_secret?: InputMaybe<Scalars['String']['input']>;
  password_hash?: InputMaybe<Scalars['String']['input']>;
  refresh_tokens?: InputMaybe<Auth_Refresh_Tokens_Arr_Rel_Insert_Input>;
  role?: InputMaybe<Auth_Roles_Obj_Rel_Insert_Input>;
  ticket?: InputMaybe<Scalars['uuid']['input']>;
  ticket_expires_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** order by max() on columns of table "auth.accounts" */
export interface Auth_Accounts_Max_Order_By {
  created_at?: InputMaybe<Order_By>;
  default_role?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  new_email?: InputMaybe<Order_By>;
  otp_secret?: InputMaybe<Order_By>;
  password_hash?: InputMaybe<Order_By>;
  ticket?: InputMaybe<Order_By>;
  ticket_expires_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
}

/** order by min() on columns of table "auth.accounts" */
export interface Auth_Accounts_Min_Order_By {
  created_at?: InputMaybe<Order_By>;
  default_role?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  new_email?: InputMaybe<Order_By>;
  otp_secret?: InputMaybe<Order_By>;
  password_hash?: InputMaybe<Order_By>;
  ticket?: InputMaybe<Order_By>;
  ticket_expires_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
}

/** input type for inserting object relation for remote table "auth.accounts" */
export interface Auth_Accounts_Obj_Rel_Insert_Input {
  data: Auth_Accounts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Accounts_On_Conflict>;
}

/** on_conflict condition type for table "auth.accounts" */
export interface Auth_Accounts_On_Conflict {
  constraint: Auth_Accounts_Constraint;
  update_columns?: Array<Auth_Accounts_Update_Column>;
  where?: InputMaybe<Auth_Accounts_Bool_Exp>;
}

/** Ordering options when selecting data from "auth.accounts". */
export interface Auth_Accounts_Order_By {
  account_roles_aggregate?: InputMaybe<Auth_Account_Roles_Aggregate_Order_By>;
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  cron_states_aggregate?: InputMaybe<Auth_Cron_State_Aggregate_Order_By>;
  custom_register_data?: InputMaybe<Order_By>;
  default_role?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  login_tokens_aggregate?: InputMaybe<Auth_Login_Tokens_Aggregate_Order_By>;
  mfa_enabled?: InputMaybe<Order_By>;
  new_email?: InputMaybe<Order_By>;
  otp_secret?: InputMaybe<Order_By>;
  password_hash?: InputMaybe<Order_By>;
  refresh_tokens_aggregate?: InputMaybe<Auth_Refresh_Tokens_Aggregate_Order_By>;
  role?: InputMaybe<Auth_Roles_Order_By>;
  ticket?: InputMaybe<Order_By>;
  ticket_expires_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
}

/** primary key columns input for table: auth.accounts */
export interface Auth_Accounts_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface Auth_Accounts_Prepend_Input {
  custom_register_data?: InputMaybe<Scalars['jsonb']['input']>;
}

/** select columns of table "auth.accounts" */
export enum Auth_Accounts_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomRegisterData = 'custom_register_data',
  /** column name */
  DefaultRole = 'default_role',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  MfaEnabled = 'mfa_enabled',
  /** column name */
  NewEmail = 'new_email',
  /** column name */
  OtpSecret = 'otp_secret',
  /** column name */
  PasswordHash = 'password_hash',
  /** column name */
  Ticket = 'ticket',
  /** column name */
  TicketExpiresAt = 'ticket_expires_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "auth_accounts_aggregate_bool_exp_bool_and_arguments_columns" columns of table "auth.accounts" */
export enum Auth_Accounts_Select_Column_Auth_Accounts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  MfaEnabled = 'mfa_enabled'
}

/** select "auth_accounts_aggregate_bool_exp_bool_or_arguments_columns" columns of table "auth.accounts" */
export enum Auth_Accounts_Select_Column_Auth_Accounts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  MfaEnabled = 'mfa_enabled'
}

/** input type for updating data in table "auth.accounts" */
export interface Auth_Accounts_Set_Input {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  custom_register_data?: InputMaybe<Scalars['jsonb']['input']>;
  default_role?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['citext']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  mfa_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  new_email?: InputMaybe<Scalars['citext']['input']>;
  otp_secret?: InputMaybe<Scalars['String']['input']>;
  password_hash?: InputMaybe<Scalars['String']['input']>;
  ticket?: InputMaybe<Scalars['uuid']['input']>;
  ticket_expires_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** Streaming cursor of the table "auth_accounts" */
export interface Auth_Accounts_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Auth_Accounts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Auth_Accounts_Stream_Cursor_Value_Input {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  custom_register_data?: InputMaybe<Scalars['jsonb']['input']>;
  default_role?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['citext']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  mfa_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  new_email?: InputMaybe<Scalars['citext']['input']>;
  otp_secret?: InputMaybe<Scalars['String']['input']>;
  password_hash?: InputMaybe<Scalars['String']['input']>;
  ticket?: InputMaybe<Scalars['uuid']['input']>;
  ticket_expires_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** update columns of table "auth.accounts" */
export enum Auth_Accounts_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomRegisterData = 'custom_register_data',
  /** column name */
  DefaultRole = 'default_role',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  MfaEnabled = 'mfa_enabled',
  /** column name */
  NewEmail = 'new_email',
  /** column name */
  OtpSecret = 'otp_secret',
  /** column name */
  PasswordHash = 'password_hash',
  /** column name */
  Ticket = 'ticket',
  /** column name */
  TicketExpiresAt = 'ticket_expires_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export interface Auth_Accounts_Updates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Auth_Accounts_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Auth_Accounts_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Auth_Accounts_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Auth_Accounts_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Auth_Accounts_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Accounts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Accounts_Bool_Exp;
}

/** Boolean expression to filter rows from the table "auth.contexts". All fields are combined with a logical 'AND'. */
export interface Auth_Contexts_Bool_Exp {
  AccountRoles?: InputMaybe<Auth_Account_Roles_Bool_Exp>;
  AccountRoles_aggregate?: InputMaybe<Auth_Account_Roles_Aggregate_Bool_Exp>;
  Groups?: InputMaybe<Auth_Groups_Bool_Exp>;
  Groups_aggregate?: InputMaybe<Auth_Groups_Aggregate_Bool_Exp>;
  Users?: InputMaybe<Users_Bool_Exp>;
  Users_aggregate?: InputMaybe<Users_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Auth_Contexts_Bool_Exp>>;
  _not?: InputMaybe<Auth_Contexts_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Contexts_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "auth.contexts" */
export enum Auth_Contexts_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContextsPkey = 'contexts_pkey'
}

/** input type for inserting data into table "auth.contexts" */
export interface Auth_Contexts_Insert_Input {
  AccountRoles?: InputMaybe<Auth_Account_Roles_Arr_Rel_Insert_Input>;
  Groups?: InputMaybe<Auth_Groups_Arr_Rel_Insert_Input>;
  Users?: InputMaybe<Users_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}

/** input type for inserting object relation for remote table "auth.contexts" */
export interface Auth_Contexts_Obj_Rel_Insert_Input {
  data: Auth_Contexts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Contexts_On_Conflict>;
}

/** on_conflict condition type for table "auth.contexts" */
export interface Auth_Contexts_On_Conflict {
  constraint: Auth_Contexts_Constraint;
  update_columns?: Array<Auth_Contexts_Update_Column>;
  where?: InputMaybe<Auth_Contexts_Bool_Exp>;
}

/** Ordering options when selecting data from "auth.contexts". */
export interface Auth_Contexts_Order_By {
  AccountRoles_aggregate?: InputMaybe<Auth_Account_Roles_Aggregate_Order_By>;
  Groups_aggregate?: InputMaybe<Auth_Groups_Aggregate_Order_By>;
  Users_aggregate?: InputMaybe<Users_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
}

/** primary key columns input for table: auth.contexts */
export interface Auth_Contexts_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** select columns of table "auth.contexts" */
export enum Auth_Contexts_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "auth.contexts" */
export interface Auth_Contexts_Set_Input {
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "auth_contexts" */
export interface Auth_Contexts_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Auth_Contexts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Auth_Contexts_Stream_Cursor_Value_Input {
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "auth.contexts" */
export enum Auth_Contexts_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export interface Auth_Contexts_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Contexts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Contexts_Bool_Exp;
}

export interface Auth_Cron_State_Aggregate_Bool_Exp {
  count?: InputMaybe<Auth_Cron_State_Aggregate_Bool_Exp_Count>;
}

export interface Auth_Cron_State_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<Auth_Cron_State_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Auth_Cron_State_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** order by aggregate values of table "auth.cron_state" */
export interface Auth_Cron_State_Aggregate_Order_By {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Auth_Cron_State_Max_Order_By>;
  min?: InputMaybe<Auth_Cron_State_Min_Order_By>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface Auth_Cron_State_Append_Input {
  data?: InputMaybe<Scalars['jsonb']['input']>;
}

/** input type for inserting array relation for remote table "auth.cron_state" */
export interface Auth_Cron_State_Arr_Rel_Insert_Input {
  data: Array<Auth_Cron_State_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Cron_State_On_Conflict>;
}

/** Boolean expression to filter rows from the table "auth.cron_state". All fields are combined with a logical 'AND'. */
export interface Auth_Cron_State_Bool_Exp {
  _and?: InputMaybe<Array<Auth_Cron_State_Bool_Exp>>;
  _not?: InputMaybe<Auth_Cron_State_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Cron_State_Bool_Exp>>;
  account_id?: InputMaybe<Uuid_Comparison_Exp>;
  channel?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
}

/** unique or primary key constraints on table "auth.cron_state" */
export enum Auth_Cron_State_Constraint {
  /** unique or primary key constraint on columns "id" */
  CronStatePkey = 'cron_state_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface Auth_Cron_State_Delete_At_Path_Input {
  data?: InputMaybe<Array<Scalars['String']['input']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface Auth_Cron_State_Delete_Elem_Input {
  data?: InputMaybe<Scalars['Int']['input']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface Auth_Cron_State_Delete_Key_Input {
  data?: InputMaybe<Scalars['String']['input']>;
}

/** input type for inserting data into table "auth.cron_state" */
export interface Auth_Cron_State_Insert_Input {
  account_id?: InputMaybe<Scalars['uuid']['input']>;
  channel?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
}

/** order by max() on columns of table "auth.cron_state" */
export interface Auth_Cron_State_Max_Order_By {
  account_id?: InputMaybe<Order_By>;
  channel?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
}

/** order by min() on columns of table "auth.cron_state" */
export interface Auth_Cron_State_Min_Order_By {
  account_id?: InputMaybe<Order_By>;
  channel?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
}

/** on_conflict condition type for table "auth.cron_state" */
export interface Auth_Cron_State_On_Conflict {
  constraint: Auth_Cron_State_Constraint;
  update_columns?: Array<Auth_Cron_State_Update_Column>;
  where?: InputMaybe<Auth_Cron_State_Bool_Exp>;
}

/** Ordering options when selecting data from "auth.cron_state". */
export interface Auth_Cron_State_Order_By {
  account_id?: InputMaybe<Order_By>;
  channel?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
}

/** primary key columns input for table: auth.cron_state */
export interface Auth_Cron_State_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface Auth_Cron_State_Prepend_Input {
  data?: InputMaybe<Scalars['jsonb']['input']>;
}

/** select columns of table "auth.cron_state" */
export enum Auth_Cron_State_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Channel = 'channel',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "auth.cron_state" */
export interface Auth_Cron_State_Set_Input {
  account_id?: InputMaybe<Scalars['uuid']['input']>;
  channel?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
}

/** Streaming cursor of the table "auth_cron_state" */
export interface Auth_Cron_State_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Auth_Cron_State_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Auth_Cron_State_Stream_Cursor_Value_Input {
  account_id?: InputMaybe<Scalars['uuid']['input']>;
  channel?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
}

/** update columns of table "auth.cron_state" */
export enum Auth_Cron_State_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Channel = 'channel',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id'
}

export interface Auth_Cron_State_Updates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Auth_Cron_State_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Auth_Cron_State_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Auth_Cron_State_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Auth_Cron_State_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Auth_Cron_State_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Cron_State_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Cron_State_Bool_Exp;
}

/** Boolean expression to filter rows from the table "auth.feature_required_features". All fields are combined with a logical 'AND'. */
export interface Auth_Feature_Required_Features_Bool_Exp {
  Feature?: InputMaybe<Auth_Features_Bool_Exp>;
  RequiredFeature?: InputMaybe<Auth_Features_Bool_Exp>;
  _and?: InputMaybe<Array<Auth_Feature_Required_Features_Bool_Exp>>;
  _not?: InputMaybe<Auth_Feature_Required_Features_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Feature_Required_Features_Bool_Exp>>;
  feature_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  required_feature_id?: InputMaybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "auth.feature_required_features" */
export enum Auth_Feature_Required_Features_Constraint {
  /** unique or primary key constraint on columns "id" */
  FeatureRequiredFeaturesPkey = 'feature_required_features_pkey'
}

/** input type for inserting data into table "auth.feature_required_features" */
export interface Auth_Feature_Required_Features_Insert_Input {
  Feature?: InputMaybe<Auth_Features_Obj_Rel_Insert_Input>;
  RequiredFeature?: InputMaybe<Auth_Features_Obj_Rel_Insert_Input>;
  feature_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  required_feature_id?: InputMaybe<Scalars['String']['input']>;
}

/** on_conflict condition type for table "auth.feature_required_features" */
export interface Auth_Feature_Required_Features_On_Conflict {
  constraint: Auth_Feature_Required_Features_Constraint;
  update_columns?: Array<Auth_Feature_Required_Features_Update_Column>;
  where?: InputMaybe<Auth_Feature_Required_Features_Bool_Exp>;
}

/** Ordering options when selecting data from "auth.feature_required_features". */
export interface Auth_Feature_Required_Features_Order_By {
  Feature?: InputMaybe<Auth_Features_Order_By>;
  RequiredFeature?: InputMaybe<Auth_Features_Order_By>;
  feature_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  required_feature_id?: InputMaybe<Order_By>;
}

/** primary key columns input for table: auth.feature_required_features */
export interface Auth_Feature_Required_Features_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** select columns of table "auth.feature_required_features" */
export enum Auth_Feature_Required_Features_Select_Column {
  /** column name */
  FeatureId = 'feature_id',
  /** column name */
  Id = 'id',
  /** column name */
  RequiredFeatureId = 'required_feature_id'
}

/** input type for updating data in table "auth.feature_required_features" */
export interface Auth_Feature_Required_Features_Set_Input {
  feature_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  required_feature_id?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "auth_feature_required_features" */
export interface Auth_Feature_Required_Features_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Auth_Feature_Required_Features_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Auth_Feature_Required_Features_Stream_Cursor_Value_Input {
  feature_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  required_feature_id?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "auth.feature_required_features" */
export enum Auth_Feature_Required_Features_Update_Column {
  /** column name */
  FeatureId = 'feature_id',
  /** column name */
  Id = 'id',
  /** column name */
  RequiredFeatureId = 'required_feature_id'
}

export interface Auth_Feature_Required_Features_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Feature_Required_Features_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Feature_Required_Features_Bool_Exp;
}

/** order by aggregate values of table "auth.features" */
export interface Auth_Features_Aggregate_Order_By {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Auth_Features_Max_Order_By>;
  min?: InputMaybe<Auth_Features_Min_Order_By>;
}

/** Boolean expression to filter rows from the table "auth.features". All fields are combined with a logical 'AND'. */
export interface Auth_Features_Bool_Exp {
  GroupFeatures?: InputMaybe<Auth_Group_Features_Bool_Exp>;
  GroupFeatures_aggregate?: InputMaybe<Auth_Group_Features_Aggregate_Bool_Exp>;
  RequiredByFeatures?: InputMaybe<Auth_Features_Bool_Exp>;
  RequiredFeatures?: InputMaybe<Auth_Features_Bool_Exp>;
  _and?: InputMaybe<Array<Auth_Features_Bool_Exp>>;
  _not?: InputMaybe<Auth_Features_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Features_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "auth.features" */
export enum Auth_Features_Constraint {
  /** unique or primary key constraint on columns "id" */
  FeaturesPkey = 'features_pkey'
}

/** input type for inserting data into table "auth.features" */
export interface Auth_Features_Insert_Input {
  GroupFeatures?: InputMaybe<Auth_Group_Features_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}

/** order by max() on columns of table "auth.features" */
export interface Auth_Features_Max_Order_By {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
}

/** order by min() on columns of table "auth.features" */
export interface Auth_Features_Min_Order_By {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
}

/** input type for inserting object relation for remote table "auth.features" */
export interface Auth_Features_Obj_Rel_Insert_Input {
  data: Auth_Features_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Features_On_Conflict>;
}

/** on_conflict condition type for table "auth.features" */
export interface Auth_Features_On_Conflict {
  constraint: Auth_Features_Constraint;
  update_columns?: Array<Auth_Features_Update_Column>;
  where?: InputMaybe<Auth_Features_Bool_Exp>;
}

/** Ordering options when selecting data from "auth.features". */
export interface Auth_Features_Order_By {
  GroupFeatures_aggregate?: InputMaybe<Auth_Group_Features_Aggregate_Order_By>;
  RequiredByFeatures_aggregate?: InputMaybe<Auth_Features_Aggregate_Order_By>;
  RequiredFeatures_aggregate?: InputMaybe<Auth_Features_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
}

/** primary key columns input for table: auth.features */
export interface Auth_Features_Pk_Columns_Input {
  id: Scalars['String']['input'];
}

/** select columns of table "auth.features" */
export enum Auth_Features_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "auth.features" */
export interface Auth_Features_Set_Input {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "auth_features" */
export interface Auth_Features_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Auth_Features_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Auth_Features_Stream_Cursor_Value_Input {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "auth.features" */
export enum Auth_Features_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export interface Auth_Features_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Features_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Features_Bool_Exp;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface Auth_Files_Append_Input {
  languages?: InputMaybe<Scalars['jsonb']['input']>;
}

/** Boolean expression to filter rows from the table "auth.files". All fields are combined with a logical 'AND'. */
export interface Auth_Files_Bool_Exp {
  ProductFiles?: InputMaybe<Product_Files_Bool_Exp>;
  ProductFiles_aggregate?: InputMaybe<Product_Files_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Auth_Files_Bool_Exp>>;
  _not?: InputMaybe<Auth_Files_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Files_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  languages?: InputMaybe<Jsonb_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  path?: InputMaybe<String_Comparison_Exp>;
  size?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
}

/** unique or primary key constraints on table "auth.files" */
export enum Auth_Files_Constraint {
  /** unique or primary key constraint on columns "path" */
  FilesPathKey = 'files_path_key',
  /** unique or primary key constraint on columns "id" */
  FilesPkey = 'files_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface Auth_Files_Delete_At_Path_Input {
  languages?: InputMaybe<Array<Scalars['String']['input']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface Auth_Files_Delete_Elem_Input {
  languages?: InputMaybe<Scalars['Int']['input']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface Auth_Files_Delete_Key_Input {
  languages?: InputMaybe<Scalars['String']['input']>;
}

/** input type for incrementing numeric columns in table "auth.files" */
export interface Auth_Files_Inc_Input {
  size?: InputMaybe<Scalars['bigint']['input']>;
}

/** input type for inserting data into table "auth.files" */
export interface Auth_Files_Insert_Input {
  ProductFiles?: InputMaybe<Product_Files_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  languages?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** input type for inserting object relation for remote table "auth.files" */
export interface Auth_Files_Obj_Rel_Insert_Input {
  data: Auth_Files_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Files_On_Conflict>;
}

/** on_conflict condition type for table "auth.files" */
export interface Auth_Files_On_Conflict {
  constraint: Auth_Files_Constraint;
  update_columns?: Array<Auth_Files_Update_Column>;
  where?: InputMaybe<Auth_Files_Bool_Exp>;
}

/** Ordering options when selecting data from "auth.files". */
export interface Auth_Files_Order_By {
  ProductFiles_aggregate?: InputMaybe<Product_Files_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  languages?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  path?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
}

/** primary key columns input for table: auth.files */
export interface Auth_Files_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface Auth_Files_Prepend_Input {
  languages?: InputMaybe<Scalars['jsonb']['input']>;
}

/** select columns of table "auth.files" */
export enum Auth_Files_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Languages = 'languages',
  /** column name */
  Name = 'name',
  /** column name */
  Path = 'path',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "auth.files" */
export interface Auth_Files_Set_Input {
  id?: InputMaybe<Scalars['uuid']['input']>;
  languages?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** Streaming cursor of the table "auth_files" */
export interface Auth_Files_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Auth_Files_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Auth_Files_Stream_Cursor_Value_Input {
  id?: InputMaybe<Scalars['uuid']['input']>;
  languages?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** update columns of table "auth.files" */
export enum Auth_Files_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Languages = 'languages',
  /** column name */
  Name = 'name',
  /** column name */
  Path = 'path',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface Auth_Files_Updates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Auth_Files_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Auth_Files_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Auth_Files_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Auth_Files_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Auth_Files_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Auth_Files_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Files_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Files_Bool_Exp;
}

export interface Auth_Group_Features_Aggregate_Bool_Exp {
  count?: InputMaybe<Auth_Group_Features_Aggregate_Bool_Exp_Count>;
}

export interface Auth_Group_Features_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<Auth_Group_Features_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Auth_Group_Features_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** order by aggregate values of table "auth.group_features" */
export interface Auth_Group_Features_Aggregate_Order_By {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Auth_Group_Features_Max_Order_By>;
  min?: InputMaybe<Auth_Group_Features_Min_Order_By>;
}

/** input type for inserting array relation for remote table "auth.group_features" */
export interface Auth_Group_Features_Arr_Rel_Insert_Input {
  data: Array<Auth_Group_Features_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Group_Features_On_Conflict>;
}

/** Boolean expression to filter rows from the table "auth.group_features". All fields are combined with a logical 'AND'. */
export interface Auth_Group_Features_Bool_Exp {
  Feature?: InputMaybe<Auth_Features_Bool_Exp>;
  Group?: InputMaybe<Auth_Groups_Bool_Exp>;
  _and?: InputMaybe<Array<Auth_Group_Features_Bool_Exp>>;
  _not?: InputMaybe<Auth_Group_Features_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Group_Features_Bool_Exp>>;
  feature_id?: InputMaybe<String_Comparison_Exp>;
  group_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
}

/** unique or primary key constraints on table "auth.group_features" */
export enum Auth_Group_Features_Constraint {
  /** unique or primary key constraint on columns "id" */
  GroupFeaturesPkey = 'group_features_pkey'
}

/** input type for inserting data into table "auth.group_features" */
export interface Auth_Group_Features_Insert_Input {
  Feature?: InputMaybe<Auth_Features_Obj_Rel_Insert_Input>;
  Group?: InputMaybe<Auth_Groups_Obj_Rel_Insert_Input>;
  feature_id?: InputMaybe<Scalars['String']['input']>;
  group_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
}

/** order by max() on columns of table "auth.group_features" */
export interface Auth_Group_Features_Max_Order_By {
  feature_id?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
}

/** order by min() on columns of table "auth.group_features" */
export interface Auth_Group_Features_Min_Order_By {
  feature_id?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
}

/** on_conflict condition type for table "auth.group_features" */
export interface Auth_Group_Features_On_Conflict {
  constraint: Auth_Group_Features_Constraint;
  update_columns?: Array<Auth_Group_Features_Update_Column>;
  where?: InputMaybe<Auth_Group_Features_Bool_Exp>;
}

/** Ordering options when selecting data from "auth.group_features". */
export interface Auth_Group_Features_Order_By {
  Feature?: InputMaybe<Auth_Features_Order_By>;
  Group?: InputMaybe<Auth_Groups_Order_By>;
  feature_id?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
}

/** primary key columns input for table: auth.group_features */
export interface Auth_Group_Features_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** select columns of table "auth.group_features" */
export enum Auth_Group_Features_Select_Column {
  /** column name */
  FeatureId = 'feature_id',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "auth.group_features" */
export interface Auth_Group_Features_Set_Input {
  feature_id?: InputMaybe<Scalars['String']['input']>;
  group_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
}

/** Streaming cursor of the table "auth_group_features" */
export interface Auth_Group_Features_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Auth_Group_Features_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Auth_Group_Features_Stream_Cursor_Value_Input {
  feature_id?: InputMaybe<Scalars['String']['input']>;
  group_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
}

/** update columns of table "auth.group_features" */
export enum Auth_Group_Features_Update_Column {
  /** column name */
  FeatureId = 'feature_id',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id'
}

export interface Auth_Group_Features_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Group_Features_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Group_Features_Bool_Exp;
}

export interface Auth_Groups_Aggregate_Bool_Exp {
  count?: InputMaybe<Auth_Groups_Aggregate_Bool_Exp_Count>;
}

export interface Auth_Groups_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<Auth_Groups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Auth_Groups_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** order by aggregate values of table "auth.groups" */
export interface Auth_Groups_Aggregate_Order_By {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Auth_Groups_Max_Order_By>;
  min?: InputMaybe<Auth_Groups_Min_Order_By>;
}

/** input type for inserting array relation for remote table "auth.groups" */
export interface Auth_Groups_Arr_Rel_Insert_Input {
  data: Array<Auth_Groups_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Groups_On_Conflict>;
}

/** Boolean expression to filter rows from the table "auth.groups". All fields are combined with a logical 'AND'. */
export interface Auth_Groups_Bool_Exp {
  Context?: InputMaybe<Auth_Contexts_Bool_Exp>;
  CreatedBy?: InputMaybe<Users_Bool_Exp>;
  DeletedBy?: InputMaybe<Users_Bool_Exp>;
  GroupFeatures?: InputMaybe<Auth_Group_Features_Bool_Exp>;
  GroupFeatures_aggregate?: InputMaybe<Auth_Group_Features_Aggregate_Bool_Exp>;
  UpdatedBy?: InputMaybe<Users_Bool_Exp>;
  UserGroups?: InputMaybe<Auth_User_Groups_Bool_Exp>;
  UserGroups_aggregate?: InputMaybe<Auth_User_Groups_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Auth_Groups_Bool_Exp>>;
  _not?: InputMaybe<Auth_Groups_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Groups_Bool_Exp>>;
  context_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Uuid_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Uuid_Comparison_Exp>;
}

/** unique or primary key constraints on table "auth.groups" */
export enum Auth_Groups_Constraint {
  /** unique or primary key constraint on columns "id" */
  GroupsPkey = 'groups_pkey'
}

/** input type for inserting data into table "auth.groups" */
export interface Auth_Groups_Insert_Input {
  Context?: InputMaybe<Auth_Contexts_Obj_Rel_Insert_Input>;
  CreatedBy?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  DeletedBy?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  GroupFeatures?: InputMaybe<Auth_Group_Features_Arr_Rel_Insert_Input>;
  UpdatedBy?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  UserGroups?: InputMaybe<Auth_User_Groups_Arr_Rel_Insert_Input>;
  context_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_by?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['uuid']['input']>;
}

/** order by max() on columns of table "auth.groups" */
export interface Auth_Groups_Max_Order_By {
  context_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
}

/** order by min() on columns of table "auth.groups" */
export interface Auth_Groups_Min_Order_By {
  context_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
}

/** input type for inserting object relation for remote table "auth.groups" */
export interface Auth_Groups_Obj_Rel_Insert_Input {
  data: Auth_Groups_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Groups_On_Conflict>;
}

/** on_conflict condition type for table "auth.groups" */
export interface Auth_Groups_On_Conflict {
  constraint: Auth_Groups_Constraint;
  update_columns?: Array<Auth_Groups_Update_Column>;
  where?: InputMaybe<Auth_Groups_Bool_Exp>;
}

/** Ordering options when selecting data from "auth.groups". */
export interface Auth_Groups_Order_By {
  Context?: InputMaybe<Auth_Contexts_Order_By>;
  CreatedBy?: InputMaybe<Users_Order_By>;
  DeletedBy?: InputMaybe<Users_Order_By>;
  GroupFeatures_aggregate?: InputMaybe<Auth_Group_Features_Aggregate_Order_By>;
  UpdatedBy?: InputMaybe<Users_Order_By>;
  UserGroups_aggregate?: InputMaybe<Auth_User_Groups_Aggregate_Order_By>;
  context_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
}

/** primary key columns input for table: auth.groups */
export interface Auth_Groups_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** select columns of table "auth.groups" */
export enum Auth_Groups_Select_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "auth.groups" */
export interface Auth_Groups_Set_Input {
  context_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_by?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['uuid']['input']>;
}

/** Streaming cursor of the table "auth_groups" */
export interface Auth_Groups_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Auth_Groups_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Auth_Groups_Stream_Cursor_Value_Input {
  context_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_by?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['uuid']['input']>;
}

/** update columns of table "auth.groups" */
export enum Auth_Groups_Update_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export interface Auth_Groups_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Groups_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Groups_Bool_Exp;
}

export interface Auth_Login_Tokens_Aggregate_Bool_Exp {
  count?: InputMaybe<Auth_Login_Tokens_Aggregate_Bool_Exp_Count>;
}

export interface Auth_Login_Tokens_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<Auth_Login_Tokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Auth_Login_Tokens_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** order by aggregate values of table "auth.login_tokens" */
export interface Auth_Login_Tokens_Aggregate_Order_By {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Auth_Login_Tokens_Max_Order_By>;
  min?: InputMaybe<Auth_Login_Tokens_Min_Order_By>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface Auth_Login_Tokens_Append_Input {
  device_data?: InputMaybe<Scalars['jsonb']['input']>;
}

/** input type for inserting array relation for remote table "auth.login_tokens" */
export interface Auth_Login_Tokens_Arr_Rel_Insert_Input {
  data: Array<Auth_Login_Tokens_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Login_Tokens_On_Conflict>;
}

/** Boolean expression to filter rows from the table "auth.login_tokens". All fields are combined with a logical 'AND'. */
export interface Auth_Login_Tokens_Bool_Exp {
  _and?: InputMaybe<Array<Auth_Login_Tokens_Bool_Exp>>;
  _not?: InputMaybe<Auth_Login_Tokens_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Login_Tokens_Bool_Exp>>;
  account?: InputMaybe<Auth_Accounts_Bool_Exp>;
  account_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  device_data?: InputMaybe<Jsonb_Comparison_Exp>;
  device_id?: InputMaybe<Uuid_Comparison_Exp>;
  expires_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  refresh_tokens?: InputMaybe<Auth_Refresh_Tokens_Bool_Exp>;
  refresh_tokens_aggregate?: InputMaybe<Auth_Refresh_Tokens_Aggregate_Bool_Exp>;
}

/** unique or primary key constraints on table "auth.login_tokens" */
export enum Auth_Login_Tokens_Constraint {
  /** unique or primary key constraint on columns "device_id" */
  LoginTokensDeviceIdKey = 'login_tokens_device_id_key',
  /** unique or primary key constraint on columns "id" */
  LoginTokensPkey = 'login_tokens_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface Auth_Login_Tokens_Delete_At_Path_Input {
  device_data?: InputMaybe<Array<Scalars['String']['input']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface Auth_Login_Tokens_Delete_Elem_Input {
  device_data?: InputMaybe<Scalars['Int']['input']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface Auth_Login_Tokens_Delete_Key_Input {
  device_data?: InputMaybe<Scalars['String']['input']>;
}

/** input type for inserting data into table "auth.login_tokens" */
export interface Auth_Login_Tokens_Insert_Input {
  account?: InputMaybe<Auth_Accounts_Obj_Rel_Insert_Input>;
  account_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  device_data?: InputMaybe<Scalars['jsonb']['input']>;
  device_id?: InputMaybe<Scalars['uuid']['input']>;
  expires_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  refresh_tokens?: InputMaybe<Auth_Refresh_Tokens_Arr_Rel_Insert_Input>;
}

/** order by max() on columns of table "auth.login_tokens" */
export interface Auth_Login_Tokens_Max_Order_By {
  account_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  device_id?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
}

/** order by min() on columns of table "auth.login_tokens" */
export interface Auth_Login_Tokens_Min_Order_By {
  account_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  device_id?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
}

/** input type for inserting object relation for remote table "auth.login_tokens" */
export interface Auth_Login_Tokens_Obj_Rel_Insert_Input {
  data: Auth_Login_Tokens_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Login_Tokens_On_Conflict>;
}

/** on_conflict condition type for table "auth.login_tokens" */
export interface Auth_Login_Tokens_On_Conflict {
  constraint: Auth_Login_Tokens_Constraint;
  update_columns?: Array<Auth_Login_Tokens_Update_Column>;
  where?: InputMaybe<Auth_Login_Tokens_Bool_Exp>;
}

/** Ordering options when selecting data from "auth.login_tokens". */
export interface Auth_Login_Tokens_Order_By {
  account?: InputMaybe<Auth_Accounts_Order_By>;
  account_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  device_data?: InputMaybe<Order_By>;
  device_id?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  refresh_tokens_aggregate?: InputMaybe<Auth_Refresh_Tokens_Aggregate_Order_By>;
}

/** primary key columns input for table: auth.login_tokens */
export interface Auth_Login_Tokens_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface Auth_Login_Tokens_Prepend_Input {
  device_data?: InputMaybe<Scalars['jsonb']['input']>;
}

/** select columns of table "auth.login_tokens" */
export enum Auth_Login_Tokens_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeviceData = 'device_data',
  /** column name */
  DeviceId = 'device_id',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "auth.login_tokens" */
export interface Auth_Login_Tokens_Set_Input {
  account_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  device_data?: InputMaybe<Scalars['jsonb']['input']>;
  device_id?: InputMaybe<Scalars['uuid']['input']>;
  expires_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
}

/** Streaming cursor of the table "auth_login_tokens" */
export interface Auth_Login_Tokens_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Auth_Login_Tokens_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Auth_Login_Tokens_Stream_Cursor_Value_Input {
  account_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  device_data?: InputMaybe<Scalars['jsonb']['input']>;
  device_id?: InputMaybe<Scalars['uuid']['input']>;
  expires_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
}

/** update columns of table "auth.login_tokens" */
export enum Auth_Login_Tokens_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeviceData = 'device_data',
  /** column name */
  DeviceId = 'device_id',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Id = 'id'
}

export interface Auth_Login_Tokens_Updates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Auth_Login_Tokens_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Auth_Login_Tokens_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Auth_Login_Tokens_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Auth_Login_Tokens_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Auth_Login_Tokens_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Login_Tokens_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Login_Tokens_Bool_Exp;
}

export interface Auth_Permissions_Aggregate_Bool_Exp {
  count?: InputMaybe<Auth_Permissions_Aggregate_Bool_Exp_Count>;
}

export interface Auth_Permissions_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<Auth_Permissions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Auth_Permissions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** order by aggregate values of table "auth.permissions" */
export interface Auth_Permissions_Aggregate_Order_By {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Auth_Permissions_Max_Order_By>;
  min?: InputMaybe<Auth_Permissions_Min_Order_By>;
}

/** input type for inserting array relation for remote table "auth.permissions" */
export interface Auth_Permissions_Arr_Rel_Insert_Input {
  data: Array<Auth_Permissions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Permissions_On_Conflict>;
}

/** Boolean expression to filter rows from the table "auth.permissions". All fields are combined with a logical 'AND'. */
export interface Auth_Permissions_Bool_Exp {
  Context?: InputMaybe<Auth_Contexts_Bool_Exp>;
  Feature?: InputMaybe<Auth_Features_Bool_Exp>;
  User?: InputMaybe<Users_Bool_Exp>;
  _and?: InputMaybe<Array<Auth_Permissions_Bool_Exp>>;
  _not?: InputMaybe<Auth_Permissions_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Permissions_Bool_Exp>>;
  context_id?: InputMaybe<Uuid_Comparison_Exp>;
  feature_id?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
}

/** unique or primary key constraints on table "auth.permissions" */
export enum Auth_Permissions_Constraint {
  /** unique or primary key constraint on columns "context_id", "user_id", "feature_id" */
  PermissionsUserIdFeatureIdContextIdIdx = 'permissions_user_id_feature_id_context_id_idx',
  /** unique or primary key constraint on columns "context_id", "user_id", "feature_id" */
  PermissionsUserIdFeatureIdContextIdIdx1 = 'permissions_user_id_feature_id_context_id_idx1',
  /** unique or primary key constraint on columns "context_id", "user_id", "feature_id" */
  PermissionsUserIdFeatureIdContextIdIdx2 = 'permissions_user_id_feature_id_context_id_idx2'
}

/** input type for inserting data into table "auth.permissions" */
export interface Auth_Permissions_Insert_Input {
  Context?: InputMaybe<Auth_Contexts_Obj_Rel_Insert_Input>;
  Feature?: InputMaybe<Auth_Features_Obj_Rel_Insert_Input>;
  User?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  context_id?: InputMaybe<Scalars['uuid']['input']>;
  feature_id?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** order by max() on columns of table "auth.permissions" */
export interface Auth_Permissions_Max_Order_By {
  context_id?: InputMaybe<Order_By>;
  feature_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
}

/** order by min() on columns of table "auth.permissions" */
export interface Auth_Permissions_Min_Order_By {
  context_id?: InputMaybe<Order_By>;
  feature_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
}

/** on_conflict condition type for table "auth.permissions" */
export interface Auth_Permissions_On_Conflict {
  constraint: Auth_Permissions_Constraint;
  update_columns?: Array<Auth_Permissions_Update_Column>;
  where?: InputMaybe<Auth_Permissions_Bool_Exp>;
}

/** Ordering options when selecting data from "auth.permissions". */
export interface Auth_Permissions_Order_By {
  Context?: InputMaybe<Auth_Contexts_Order_By>;
  Feature?: InputMaybe<Auth_Features_Order_By>;
  User?: InputMaybe<Users_Order_By>;
  context_id?: InputMaybe<Order_By>;
  feature_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
}

/** select columns of table "auth.permissions" */
export enum Auth_Permissions_Select_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  FeatureId = 'feature_id',
  /** column name */
  UserId = 'user_id'
}

/** Streaming cursor of the table "auth_permissions" */
export interface Auth_Permissions_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Auth_Permissions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Auth_Permissions_Stream_Cursor_Value_Input {
  context_id?: InputMaybe<Scalars['uuid']['input']>;
  feature_id?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** update columns of table "auth.permissions" */
export enum Auth_Permissions_Update_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  FeatureId = 'feature_id',
  /** column name */
  UserId = 'user_id'
}

export interface Auth_Refresh_Tokens_Aggregate_Bool_Exp {
  count?: InputMaybe<Auth_Refresh_Tokens_Aggregate_Bool_Exp_Count>;
}

export interface Auth_Refresh_Tokens_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Auth_Refresh_Tokens_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** order by aggregate values of table "auth.refresh_tokens" */
export interface Auth_Refresh_Tokens_Aggregate_Order_By {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Auth_Refresh_Tokens_Max_Order_By>;
  min?: InputMaybe<Auth_Refresh_Tokens_Min_Order_By>;
}

/** input type for inserting array relation for remote table "auth.refresh_tokens" */
export interface Auth_Refresh_Tokens_Arr_Rel_Insert_Input {
  data: Array<Auth_Refresh_Tokens_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Refresh_Tokens_On_Conflict>;
}

/** Boolean expression to filter rows from the table "auth.refresh_tokens". All fields are combined with a logical 'AND'. */
export interface Auth_Refresh_Tokens_Bool_Exp {
  _and?: InputMaybe<Array<Auth_Refresh_Tokens_Bool_Exp>>;
  _not?: InputMaybe<Auth_Refresh_Tokens_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Refresh_Tokens_Bool_Exp>>;
  account?: InputMaybe<Auth_Accounts_Bool_Exp>;
  account_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  expires_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  login_token?: InputMaybe<Auth_Login_Tokens_Bool_Exp>;
  login_token_id?: InputMaybe<Uuid_Comparison_Exp>;
  refresh_token?: InputMaybe<Uuid_Comparison_Exp>;
  sso_provider?: InputMaybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "auth.refresh_tokens" */
export enum Auth_Refresh_Tokens_Constraint {
  /** unique or primary key constraint on columns "refresh_token" */
  RefreshTokensPkey = 'refresh_tokens_pkey'
}

/** input type for inserting data into table "auth.refresh_tokens" */
export interface Auth_Refresh_Tokens_Insert_Input {
  account?: InputMaybe<Auth_Accounts_Obj_Rel_Insert_Input>;
  account_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  expires_at?: InputMaybe<Scalars['timestamptz']['input']>;
  login_token?: InputMaybe<Auth_Login_Tokens_Obj_Rel_Insert_Input>;
  login_token_id?: InputMaybe<Scalars['uuid']['input']>;
  refresh_token?: InputMaybe<Scalars['uuid']['input']>;
  sso_provider?: InputMaybe<Scalars['String']['input']>;
}

/** order by max() on columns of table "auth.refresh_tokens" */
export interface Auth_Refresh_Tokens_Max_Order_By {
  account_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  login_token_id?: InputMaybe<Order_By>;
  refresh_token?: InputMaybe<Order_By>;
  sso_provider?: InputMaybe<Order_By>;
}

/** order by min() on columns of table "auth.refresh_tokens" */
export interface Auth_Refresh_Tokens_Min_Order_By {
  account_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  login_token_id?: InputMaybe<Order_By>;
  refresh_token?: InputMaybe<Order_By>;
  sso_provider?: InputMaybe<Order_By>;
}

/** on_conflict condition type for table "auth.refresh_tokens" */
export interface Auth_Refresh_Tokens_On_Conflict {
  constraint: Auth_Refresh_Tokens_Constraint;
  update_columns?: Array<Auth_Refresh_Tokens_Update_Column>;
  where?: InputMaybe<Auth_Refresh_Tokens_Bool_Exp>;
}

/** Ordering options when selecting data from "auth.refresh_tokens". */
export interface Auth_Refresh_Tokens_Order_By {
  account?: InputMaybe<Auth_Accounts_Order_By>;
  account_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  login_token?: InputMaybe<Auth_Login_Tokens_Order_By>;
  login_token_id?: InputMaybe<Order_By>;
  refresh_token?: InputMaybe<Order_By>;
  sso_provider?: InputMaybe<Order_By>;
}

/** primary key columns input for table: auth.refresh_tokens */
export interface Auth_Refresh_Tokens_Pk_Columns_Input {
  refresh_token: Scalars['uuid']['input'];
}

/** select columns of table "auth.refresh_tokens" */
export enum Auth_Refresh_Tokens_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  LoginTokenId = 'login_token_id',
  /** column name */
  RefreshToken = 'refresh_token',
  /** column name */
  SsoProvider = 'sso_provider'
}

/** input type for updating data in table "auth.refresh_tokens" */
export interface Auth_Refresh_Tokens_Set_Input {
  account_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  expires_at?: InputMaybe<Scalars['timestamptz']['input']>;
  login_token_id?: InputMaybe<Scalars['uuid']['input']>;
  refresh_token?: InputMaybe<Scalars['uuid']['input']>;
  sso_provider?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "auth_refresh_tokens" */
export interface Auth_Refresh_Tokens_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Auth_Refresh_Tokens_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Auth_Refresh_Tokens_Stream_Cursor_Value_Input {
  account_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  expires_at?: InputMaybe<Scalars['timestamptz']['input']>;
  login_token_id?: InputMaybe<Scalars['uuid']['input']>;
  refresh_token?: InputMaybe<Scalars['uuid']['input']>;
  sso_provider?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "auth.refresh_tokens" */
export enum Auth_Refresh_Tokens_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  LoginTokenId = 'login_token_id',
  /** column name */
  RefreshToken = 'refresh_token',
  /** column name */
  SsoProvider = 'sso_provider'
}

export interface Auth_Refresh_Tokens_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Refresh_Tokens_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Refresh_Tokens_Bool_Exp;
}

/** Boolean expression to filter rows from the table "auth.roles". All fields are combined with a logical 'AND'. */
export interface Auth_Roles_Bool_Exp {
  _and?: InputMaybe<Array<Auth_Roles_Bool_Exp>>;
  _not?: InputMaybe<Auth_Roles_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Roles_Bool_Exp>>;
  account_roles?: InputMaybe<Auth_Account_Roles_Bool_Exp>;
  account_roles_aggregate?: InputMaybe<Auth_Account_Roles_Aggregate_Bool_Exp>;
  accounts?: InputMaybe<Auth_Accounts_Bool_Exp>;
  accounts_aggregate?: InputMaybe<Auth_Accounts_Aggregate_Bool_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "auth.roles" */
export enum Auth_Roles_Constraint {
  /** unique or primary key constraint on columns "role" */
  RolesPkey = 'roles_pkey'
}

/** input type for inserting data into table "auth.roles" */
export interface Auth_Roles_Insert_Input {
  account_roles?: InputMaybe<Auth_Account_Roles_Arr_Rel_Insert_Input>;
  accounts?: InputMaybe<Auth_Accounts_Arr_Rel_Insert_Input>;
  role?: InputMaybe<Scalars['String']['input']>;
}

/** input type for inserting object relation for remote table "auth.roles" */
export interface Auth_Roles_Obj_Rel_Insert_Input {
  data: Auth_Roles_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Roles_On_Conflict>;
}

/** on_conflict condition type for table "auth.roles" */
export interface Auth_Roles_On_Conflict {
  constraint: Auth_Roles_Constraint;
  update_columns?: Array<Auth_Roles_Update_Column>;
  where?: InputMaybe<Auth_Roles_Bool_Exp>;
}

/** Ordering options when selecting data from "auth.roles". */
export interface Auth_Roles_Order_By {
  account_roles_aggregate?: InputMaybe<Auth_Account_Roles_Aggregate_Order_By>;
  accounts_aggregate?: InputMaybe<Auth_Accounts_Aggregate_Order_By>;
  role?: InputMaybe<Order_By>;
}

/** primary key columns input for table: auth.roles */
export interface Auth_Roles_Pk_Columns_Input {
  role: Scalars['String']['input'];
}

/** select columns of table "auth.roles" */
export enum Auth_Roles_Select_Column {
  /** column name */
  Role = 'role'
}

/** input type for updating data in table "auth.roles" */
export interface Auth_Roles_Set_Input {
  role?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "auth_roles" */
export interface Auth_Roles_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Auth_Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Auth_Roles_Stream_Cursor_Value_Input {
  role?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "auth.roles" */
export enum Auth_Roles_Update_Column {
  /** column name */
  Role = 'role'
}

export interface Auth_Roles_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Roles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Roles_Bool_Exp;
}

export interface Auth_User_Groups_Aggregate_Bool_Exp {
  count?: InputMaybe<Auth_User_Groups_Aggregate_Bool_Exp_Count>;
}

export interface Auth_User_Groups_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<Auth_User_Groups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Auth_User_Groups_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** order by aggregate values of table "auth.user_groups" */
export interface Auth_User_Groups_Aggregate_Order_By {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Auth_User_Groups_Max_Order_By>;
  min?: InputMaybe<Auth_User_Groups_Min_Order_By>;
}

/** input type for inserting array relation for remote table "auth.user_groups" */
export interface Auth_User_Groups_Arr_Rel_Insert_Input {
  data: Array<Auth_User_Groups_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_User_Groups_On_Conflict>;
}

/** Boolean expression to filter rows from the table "auth.user_groups". All fields are combined with a logical 'AND'. */
export interface Auth_User_Groups_Bool_Exp {
  Group?: InputMaybe<Auth_Groups_Bool_Exp>;
  User?: InputMaybe<Users_Bool_Exp>;
  _and?: InputMaybe<Array<Auth_User_Groups_Bool_Exp>>;
  _not?: InputMaybe<Auth_User_Groups_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_User_Groups_Bool_Exp>>;
  group_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
}

/** unique or primary key constraints on table "auth.user_groups" */
export enum Auth_User_Groups_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserGroupsPkey = 'user_groups_pkey'
}

/** input type for inserting data into table "auth.user_groups" */
export interface Auth_User_Groups_Insert_Input {
  Group?: InputMaybe<Auth_Groups_Obj_Rel_Insert_Input>;
  User?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  group_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** order by max() on columns of table "auth.user_groups" */
export interface Auth_User_Groups_Max_Order_By {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
}

/** order by min() on columns of table "auth.user_groups" */
export interface Auth_User_Groups_Min_Order_By {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
}

/** on_conflict condition type for table "auth.user_groups" */
export interface Auth_User_Groups_On_Conflict {
  constraint: Auth_User_Groups_Constraint;
  update_columns?: Array<Auth_User_Groups_Update_Column>;
  where?: InputMaybe<Auth_User_Groups_Bool_Exp>;
}

/** Ordering options when selecting data from "auth.user_groups". */
export interface Auth_User_Groups_Order_By {
  Group?: InputMaybe<Auth_Groups_Order_By>;
  User?: InputMaybe<Users_Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
}

/** primary key columns input for table: auth.user_groups */
export interface Auth_User_Groups_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** select columns of table "auth.user_groups" */
export enum Auth_User_Groups_Select_Column {
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "auth.user_groups" */
export interface Auth_User_Groups_Set_Input {
  group_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** Streaming cursor of the table "auth_user_groups" */
export interface Auth_User_Groups_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Auth_User_Groups_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Auth_User_Groups_Stream_Cursor_Value_Input {
  group_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** update columns of table "auth.user_groups" */
export enum Auth_User_Groups_Update_Column {
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

export interface Auth_User_Groups_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_User_Groups_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_User_Groups_Bool_Exp;
}

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export interface Bigint_Comparison_Exp {
  _eq?: InputMaybe<Scalars['bigint']['input']>;
  _gt?: InputMaybe<Scalars['bigint']['input']>;
  _gte?: InputMaybe<Scalars['bigint']['input']>;
  _in?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bigint']['input']>;
  _lte?: InputMaybe<Scalars['bigint']['input']>;
  _neq?: InputMaybe<Scalars['bigint']['input']>;
  _nin?: InputMaybe<Array<Scalars['bigint']['input']>>;
}

/** Boolean expression to compare columns of type "citext". All fields are combined with logical 'AND'. */
export interface Citext_Comparison_Exp {
  _eq?: InputMaybe<Scalars['citext']['input']>;
  _gt?: InputMaybe<Scalars['citext']['input']>;
  _gte?: InputMaybe<Scalars['citext']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['citext']['input']>;
  _in?: InputMaybe<Array<Scalars['citext']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['citext']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['citext']['input']>;
  _lt?: InputMaybe<Scalars['citext']['input']>;
  _lte?: InputMaybe<Scalars['citext']['input']>;
  _neq?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['citext']['input']>;
  _nin?: InputMaybe<Array<Scalars['citext']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['citext']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['citext']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['citext']['input']>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface Csv_Products_Append_Input {
  properties?: InputMaybe<Scalars['jsonb']['input']>;
}

/** Boolean expression to filter rows from the table "csv_products". All fields are combined with a logical 'AND'. */
export interface Csv_Products_Bool_Exp {
  _and?: InputMaybe<Array<Csv_Products_Bool_Exp>>;
  _not?: InputMaybe<Csv_Products_Bool_Exp>;
  _or?: InputMaybe<Array<Csv_Products_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  marketeer?: InputMaybe<String_Comparison_Exp>;
  naam_certificaat?: InputMaybe<String_Comparison_Exp>;
  properties?: InputMaybe<Jsonb_Comparison_Exp>;
  ref_toestel?: InputMaybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "csv_products" */
export enum Csv_Products_Constraint {
  /** unique or primary key constraint on columns "ref_toestel", "naam_certificaat", "marketeer" */
  CsvProductsMarketeerNaamCertificaatRefToestelKey = 'csv_products_marketeer_naam_certificaat_ref_toestel_key',
  /** unique or primary key constraint on columns "id" */
  CsvProductsPkey1 = 'csv_products_pkey1'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface Csv_Products_Delete_At_Path_Input {
  properties?: InputMaybe<Array<Scalars['String']['input']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface Csv_Products_Delete_Elem_Input {
  properties?: InputMaybe<Scalars['Int']['input']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface Csv_Products_Delete_Key_Input {
  properties?: InputMaybe<Scalars['String']['input']>;
}

/** input type for inserting data into table "csv_products" */
export interface Csv_Products_Insert_Input {
  id?: InputMaybe<Scalars['uuid']['input']>;
  marketeer?: InputMaybe<Scalars['String']['input']>;
  naam_certificaat?: InputMaybe<Scalars['String']['input']>;
  properties?: InputMaybe<Scalars['jsonb']['input']>;
  ref_toestel?: InputMaybe<Scalars['String']['input']>;
}

/** input type for inserting object relation for remote table "csv_products" */
export interface Csv_Products_Obj_Rel_Insert_Input {
  data: Csv_Products_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Csv_Products_On_Conflict>;
}

/** on_conflict condition type for table "csv_products" */
export interface Csv_Products_On_Conflict {
  constraint: Csv_Products_Constraint;
  update_columns?: Array<Csv_Products_Update_Column>;
  where?: InputMaybe<Csv_Products_Bool_Exp>;
}

/** Ordering options when selecting data from "csv_products". */
export interface Csv_Products_Order_By {
  id?: InputMaybe<Order_By>;
  marketeer?: InputMaybe<Order_By>;
  naam_certificaat?: InputMaybe<Order_By>;
  properties?: InputMaybe<Order_By>;
  ref_toestel?: InputMaybe<Order_By>;
}

/** primary key columns input for table: csv_products */
export interface Csv_Products_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface Csv_Products_Prepend_Input {
  properties?: InputMaybe<Scalars['jsonb']['input']>;
}

/** select columns of table "csv_products" */
export enum Csv_Products_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Marketeer = 'marketeer',
  /** column name */
  NaamCertificaat = 'naam_certificaat',
  /** column name */
  Properties = 'properties',
  /** column name */
  RefToestel = 'ref_toestel'
}

/** input type for updating data in table "csv_products" */
export interface Csv_Products_Set_Input {
  id?: InputMaybe<Scalars['uuid']['input']>;
  marketeer?: InputMaybe<Scalars['String']['input']>;
  naam_certificaat?: InputMaybe<Scalars['String']['input']>;
  properties?: InputMaybe<Scalars['jsonb']['input']>;
  ref_toestel?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "csv_products" */
export interface Csv_Products_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Csv_Products_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Csv_Products_Stream_Cursor_Value_Input {
  id?: InputMaybe<Scalars['uuid']['input']>;
  marketeer?: InputMaybe<Scalars['String']['input']>;
  naam_certificaat?: InputMaybe<Scalars['String']['input']>;
  properties?: InputMaybe<Scalars['jsonb']['input']>;
  ref_toestel?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "csv_products" */
export enum Csv_Products_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Marketeer = 'marketeer',
  /** column name */
  NaamCertificaat = 'naam_certificaat',
  /** column name */
  Properties = 'properties',
  /** column name */
  RefToestel = 'ref_toestel'
}

export interface Csv_Products_Updates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Csv_Products_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Csv_Products_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Csv_Products_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Csv_Products_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Csv_Products_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Csv_Products_Set_Input>;
  /** filter the rows which have to be updated */
  where: Csv_Products_Bool_Exp;
}

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

export interface Jsonb_Cast_Exp {
  String?: InputMaybe<String_Comparison_Exp>;
}

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export interface Jsonb_Comparison_Exp {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
}

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export interface Product_Exports_Aggregate_Bool_Exp {
  bool_and?: InputMaybe<Product_Exports_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Product_Exports_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Product_Exports_Aggregate_Bool_Exp_Count>;
}

export interface Product_Exports_Aggregate_Bool_Exp_Bool_And {
  arguments: Product_Exports_Select_Column_Product_Exports_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Product_Exports_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface Product_Exports_Aggregate_Bool_Exp_Bool_Or {
  arguments: Product_Exports_Select_Column_Product_Exports_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Product_Exports_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface Product_Exports_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<Product_Exports_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Product_Exports_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** order by aggregate values of table "product_exports" */
export interface Product_Exports_Aggregate_Order_By {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Product_Exports_Max_Order_By>;
  min?: InputMaybe<Product_Exports_Min_Order_By>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface Product_Exports_Append_Input {
  properties?: InputMaybe<Scalars['jsonb']['input']>;
}

/** input type for inserting array relation for remote table "product_exports" */
export interface Product_Exports_Arr_Rel_Insert_Input {
  data: Array<Product_Exports_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_Exports_On_Conflict>;
}

/** Boolean expression to filter rows from the table "product_exports". All fields are combined with a logical 'AND'. */
export interface Product_Exports_Bool_Exp {
  _and?: InputMaybe<Array<Product_Exports_Bool_Exp>>;
  _not?: InputMaybe<Product_Exports_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Exports_Bool_Exp>>;
  exported?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  product_id?: InputMaybe<Uuid_Comparison_Exp>;
  properties?: InputMaybe<Jsonb_Comparison_Exp>;
  time?: InputMaybe<Timestamptz_Comparison_Exp>;
}

/** unique or primary key constraints on table "product_exports" */
export enum Product_Exports_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductExportsPkey = 'product_exports_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface Product_Exports_Delete_At_Path_Input {
  properties?: InputMaybe<Array<Scalars['String']['input']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface Product_Exports_Delete_Elem_Input {
  properties?: InputMaybe<Scalars['Int']['input']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface Product_Exports_Delete_Key_Input {
  properties?: InputMaybe<Scalars['String']['input']>;
}

/** input type for inserting data into table "product_exports" */
export interface Product_Exports_Insert_Input {
  exported?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  product_id?: InputMaybe<Scalars['uuid']['input']>;
  properties?: InputMaybe<Scalars['jsonb']['input']>;
  time?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** order by max() on columns of table "product_exports" */
export interface Product_Exports_Max_Order_By {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
}

/** order by min() on columns of table "product_exports" */
export interface Product_Exports_Min_Order_By {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
}

/** on_conflict condition type for table "product_exports" */
export interface Product_Exports_On_Conflict {
  constraint: Product_Exports_Constraint;
  update_columns?: Array<Product_Exports_Update_Column>;
  where?: InputMaybe<Product_Exports_Bool_Exp>;
}

/** Ordering options when selecting data from "product_exports". */
export interface Product_Exports_Order_By {
  exported?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  properties?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
}

/** primary key columns input for table: product_exports */
export interface Product_Exports_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface Product_Exports_Prepend_Input {
  properties?: InputMaybe<Scalars['jsonb']['input']>;
}

/** select columns of table "product_exports" */
export enum Product_Exports_Select_Column {
  /** column name */
  Exported = 'exported',
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  Properties = 'properties',
  /** column name */
  Time = 'time'
}

/** select "product_exports_aggregate_bool_exp_bool_and_arguments_columns" columns of table "product_exports" */
export enum Product_Exports_Select_Column_Product_Exports_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Exported = 'exported'
}

/** select "product_exports_aggregate_bool_exp_bool_or_arguments_columns" columns of table "product_exports" */
export enum Product_Exports_Select_Column_Product_Exports_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Exported = 'exported'
}

/** input type for updating data in table "product_exports" */
export interface Product_Exports_Set_Input {
  exported?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  product_id?: InputMaybe<Scalars['uuid']['input']>;
  properties?: InputMaybe<Scalars['jsonb']['input']>;
  time?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** Streaming cursor of the table "product_exports" */
export interface Product_Exports_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Product_Exports_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Product_Exports_Stream_Cursor_Value_Input {
  exported?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  product_id?: InputMaybe<Scalars['uuid']['input']>;
  properties?: InputMaybe<Scalars['jsonb']['input']>;
  time?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** update columns of table "product_exports" */
export enum Product_Exports_Update_Column {
  /** column name */
  Exported = 'exported',
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  Properties = 'properties',
  /** column name */
  Time = 'time'
}

export interface Product_Exports_Updates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Product_Exports_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Product_Exports_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Product_Exports_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Product_Exports_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Product_Exports_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Exports_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Exports_Bool_Exp;
}

export interface Product_Files_Aggregate_Bool_Exp {
  count?: InputMaybe<Product_Files_Aggregate_Bool_Exp_Count>;
}

export interface Product_Files_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<Product_Files_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Product_Files_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** order by aggregate values of table "product_files" */
export interface Product_Files_Aggregate_Order_By {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Product_Files_Max_Order_By>;
  min?: InputMaybe<Product_Files_Min_Order_By>;
}

/** input type for inserting array relation for remote table "product_files" */
export interface Product_Files_Arr_Rel_Insert_Input {
  data: Array<Product_Files_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_Files_On_Conflict>;
}

/** Boolean expression to filter rows from the table "product_files". All fields are combined with a logical 'AND'. */
export interface Product_Files_Bool_Exp {
  File?: InputMaybe<Auth_Files_Bool_Exp>;
  Product?: InputMaybe<Products_Bool_Exp>;
  _and?: InputMaybe<Array<Product_Files_Bool_Exp>>;
  _not?: InputMaybe<Product_Files_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Files_Bool_Exp>>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  product_id?: InputMaybe<Uuid_Comparison_Exp>;
  technical_part?: InputMaybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "product_files" */
export enum Product_Files_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductFilesPkey = 'product_files_pkey'
}

/** input type for inserting data into table "product_files" */
export interface Product_Files_Insert_Input {
  File?: InputMaybe<Auth_Files_Obj_Rel_Insert_Input>;
  Product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  product_id?: InputMaybe<Scalars['uuid']['input']>;
  technical_part?: InputMaybe<Scalars['String']['input']>;
}

/** order by max() on columns of table "product_files" */
export interface Product_Files_Max_Order_By {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  technical_part?: InputMaybe<Order_By>;
}

/** order by min() on columns of table "product_files" */
export interface Product_Files_Min_Order_By {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  technical_part?: InputMaybe<Order_By>;
}

/** on_conflict condition type for table "product_files" */
export interface Product_Files_On_Conflict {
  constraint: Product_Files_Constraint;
  update_columns?: Array<Product_Files_Update_Column>;
  where?: InputMaybe<Product_Files_Bool_Exp>;
}

/** Ordering options when selecting data from "product_files". */
export interface Product_Files_Order_By {
  File?: InputMaybe<Auth_Files_Order_By>;
  Product?: InputMaybe<Products_Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  technical_part?: InputMaybe<Order_By>;
}

/** primary key columns input for table: product_files */
export interface Product_Files_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** select columns of table "product_files" */
export enum Product_Files_Select_Column {
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  TechnicalPart = 'technical_part'
}

/** input type for updating data in table "product_files" */
export interface Product_Files_Set_Input {
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  product_id?: InputMaybe<Scalars['uuid']['input']>;
  technical_part?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "product_files" */
export interface Product_Files_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Product_Files_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Product_Files_Stream_Cursor_Value_Input {
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  product_id?: InputMaybe<Scalars['uuid']['input']>;
  technical_part?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "product_files" */
export enum Product_Files_Update_Column {
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  TechnicalPart = 'technical_part'
}

export interface Product_Files_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Files_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Files_Bool_Exp;
}

export interface Product_Properties_Aggregate_Bool_Exp {
  count?: InputMaybe<Product_Properties_Aggregate_Bool_Exp_Count>;
}

export interface Product_Properties_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<Product_Properties_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Product_Properties_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** order by aggregate values of table "product_properties" */
export interface Product_Properties_Aggregate_Order_By {
  avg?: InputMaybe<Product_Properties_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Product_Properties_Max_Order_By>;
  min?: InputMaybe<Product_Properties_Min_Order_By>;
  stddev?: InputMaybe<Product_Properties_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Product_Properties_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Product_Properties_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Product_Properties_Sum_Order_By>;
  var_pop?: InputMaybe<Product_Properties_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Product_Properties_Var_Samp_Order_By>;
  variance?: InputMaybe<Product_Properties_Variance_Order_By>;
}

/** input type for inserting array relation for remote table "product_properties" */
export interface Product_Properties_Arr_Rel_Insert_Input {
  data: Array<Product_Properties_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_Properties_On_Conflict>;
}

/** order by avg() on columns of table "product_properties" */
export interface Product_Properties_Avg_Order_By {
  integer?: InputMaybe<Order_By>;
}

/** Boolean expression to filter rows from the table "product_properties". All fields are combined with a logical 'AND'. */
export interface Product_Properties_Bool_Exp {
  _and?: InputMaybe<Array<Product_Properties_Bool_Exp>>;
  _not?: InputMaybe<Product_Properties_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Properties_Bool_Exp>>;
  csv_value?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  integer?: InputMaybe<Int_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  product_id?: InputMaybe<Uuid_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "product_properties" */
export enum Product_Properties_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductPropertiesPkey = 'product_properties_pkey'
}

/** input type for incrementing numeric columns in table "product_properties" */
export interface Product_Properties_Inc_Input {
  integer?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "product_properties" */
export interface Product_Properties_Insert_Input {
  csv_value?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  integer?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  product_id?: InputMaybe<Scalars['uuid']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** order by max() on columns of table "product_properties" */
export interface Product_Properties_Max_Order_By {
  csv_value?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integer?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** order by min() on columns of table "product_properties" */
export interface Product_Properties_Min_Order_By {
  csv_value?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integer?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** on_conflict condition type for table "product_properties" */
export interface Product_Properties_On_Conflict {
  constraint: Product_Properties_Constraint;
  update_columns?: Array<Product_Properties_Update_Column>;
  where?: InputMaybe<Product_Properties_Bool_Exp>;
}

/** Ordering options when selecting data from "product_properties". */
export interface Product_Properties_Order_By {
  csv_value?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integer?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** primary key columns input for table: product_properties */
export interface Product_Properties_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** select columns of table "product_properties" */
export enum Product_Properties_Select_Column {
  /** column name */
  CsvValue = 'csv_value',
  /** column name */
  Id = 'id',
  /** column name */
  Integer = 'integer',
  /** column name */
  Key = 'key',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "product_properties" */
export interface Product_Properties_Set_Input {
  csv_value?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  integer?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  product_id?: InputMaybe<Scalars['uuid']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** order by stddev() on columns of table "product_properties" */
export interface Product_Properties_Stddev_Order_By {
  integer?: InputMaybe<Order_By>;
}

/** order by stddev_pop() on columns of table "product_properties" */
export interface Product_Properties_Stddev_Pop_Order_By {
  integer?: InputMaybe<Order_By>;
}

/** order by stddev_samp() on columns of table "product_properties" */
export interface Product_Properties_Stddev_Samp_Order_By {
  integer?: InputMaybe<Order_By>;
}

/** Streaming cursor of the table "product_properties" */
export interface Product_Properties_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Product_Properties_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Product_Properties_Stream_Cursor_Value_Input {
  csv_value?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  integer?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  product_id?: InputMaybe<Scalars['uuid']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** order by sum() on columns of table "product_properties" */
export interface Product_Properties_Sum_Order_By {
  integer?: InputMaybe<Order_By>;
}

/** update columns of table "product_properties" */
export enum Product_Properties_Update_Column {
  /** column name */
  CsvValue = 'csv_value',
  /** column name */
  Id = 'id',
  /** column name */
  Integer = 'integer',
  /** column name */
  Key = 'key',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  Value = 'value'
}

export interface Product_Properties_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Product_Properties_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Properties_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Properties_Bool_Exp;
}

/** order by var_pop() on columns of table "product_properties" */
export interface Product_Properties_Var_Pop_Order_By {
  integer?: InputMaybe<Order_By>;
}

/** order by var_samp() on columns of table "product_properties" */
export interface Product_Properties_Var_Samp_Order_By {
  integer?: InputMaybe<Order_By>;
}

/** order by variance() on columns of table "product_properties" */
export interface Product_Properties_Variance_Order_By {
  integer?: InputMaybe<Order_By>;
}

export interface Product_Type_Properties_Aggregate_Bool_Exp {
  bool_and?: InputMaybe<Product_Type_Properties_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Product_Type_Properties_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Product_Type_Properties_Aggregate_Bool_Exp_Count>;
}

export interface Product_Type_Properties_Aggregate_Bool_Exp_Bool_And {
  arguments: Product_Type_Properties_Select_Column_Product_Type_Properties_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Product_Type_Properties_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface Product_Type_Properties_Aggregate_Bool_Exp_Bool_Or {
  arguments: Product_Type_Properties_Select_Column_Product_Type_Properties_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Product_Type_Properties_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface Product_Type_Properties_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<Product_Type_Properties_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Product_Type_Properties_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** order by aggregate values of table "product_type_properties" */
export interface Product_Type_Properties_Aggregate_Order_By {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Product_Type_Properties_Max_Order_By>;
  min?: InputMaybe<Product_Type_Properties_Min_Order_By>;
}

/** input type for inserting array relation for remote table "product_type_properties" */
export interface Product_Type_Properties_Arr_Rel_Insert_Input {
  data: Array<Product_Type_Properties_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_Type_Properties_On_Conflict>;
}

/** Boolean expression to filter rows from the table "product_type_properties". All fields are combined with a logical 'AND'. */
export interface Product_Type_Properties_Bool_Exp {
  ItemList?: InputMaybe<Product_Type_Properties_List_Bool_Exp>;
  _and?: InputMaybe<Array<Product_Type_Properties_Bool_Exp>>;
  _not?: InputMaybe<Product_Type_Properties_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Type_Properties_Bool_Exp>>;
  csv_property_key?: InputMaybe<String_Comparison_Exp>;
  data_type?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  list_id?: InputMaybe<Uuid_Comparison_Exp>;
  multiple?: InputMaybe<Boolean_Comparison_Exp>;
  parent_property_id?: InputMaybe<Uuid_Comparison_Exp>;
  parent_property_value?: InputMaybe<String_Comparison_Exp>;
  product_type_id?: InputMaybe<Uuid_Comparison_Exp>;
  required?: InputMaybe<Boolean_Comparison_Exp>;
}

/** unique or primary key constraints on table "product_type_properties" */
export enum Product_Type_Properties_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductTypePropertiesPkey = 'product_type_properties_pkey'
}

/** input type for inserting data into table "product_type_properties" */
export interface Product_Type_Properties_Insert_Input {
  ItemList?: InputMaybe<Product_Type_Properties_List_Obj_Rel_Insert_Input>;
  csv_property_key?: InputMaybe<Scalars['String']['input']>;
  data_type?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  list_id?: InputMaybe<Scalars['uuid']['input']>;
  multiple?: InputMaybe<Scalars['Boolean']['input']>;
  parent_property_id?: InputMaybe<Scalars['uuid']['input']>;
  parent_property_value?: InputMaybe<Scalars['String']['input']>;
  product_type_id?: InputMaybe<Scalars['uuid']['input']>;
  required?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "product_type_properties_list". All fields are combined with a logical 'AND'. */
export interface Product_Type_Properties_List_Bool_Exp {
  Items?: InputMaybe<Product_Type_Property_Item_Bool_Exp>;
  Items_aggregate?: InputMaybe<Product_Type_Property_Item_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Product_Type_Properties_List_Bool_Exp>>;
  _not?: InputMaybe<Product_Type_Properties_List_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Type_Properties_List_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "product_type_properties_list" */
export enum Product_Type_Properties_List_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductTypePropertiesListPkey = 'product_type_properties_list_pkey'
}

/** input type for inserting data into table "product_type_properties_list" */
export interface Product_Type_Properties_List_Insert_Input {
  Items?: InputMaybe<Product_Type_Property_Item_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}

/** input type for inserting object relation for remote table "product_type_properties_list" */
export interface Product_Type_Properties_List_Obj_Rel_Insert_Input {
  data: Product_Type_Properties_List_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_Type_Properties_List_On_Conflict>;
}

/** on_conflict condition type for table "product_type_properties_list" */
export interface Product_Type_Properties_List_On_Conflict {
  constraint: Product_Type_Properties_List_Constraint;
  update_columns?: Array<Product_Type_Properties_List_Update_Column>;
  where?: InputMaybe<Product_Type_Properties_List_Bool_Exp>;
}

/** Ordering options when selecting data from "product_type_properties_list". */
export interface Product_Type_Properties_List_Order_By {
  Items_aggregate?: InputMaybe<Product_Type_Property_Item_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
}

/** primary key columns input for table: product_type_properties_list */
export interface Product_Type_Properties_List_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** select columns of table "product_type_properties_list" */
export enum Product_Type_Properties_List_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "product_type_properties_list" */
export interface Product_Type_Properties_List_Set_Input {
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "product_type_properties_list" */
export interface Product_Type_Properties_List_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Product_Type_Properties_List_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Product_Type_Properties_List_Stream_Cursor_Value_Input {
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "product_type_properties_list" */
export enum Product_Type_Properties_List_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export interface Product_Type_Properties_List_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Type_Properties_List_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Type_Properties_List_Bool_Exp;
}

/** order by max() on columns of table "product_type_properties" */
export interface Product_Type_Properties_Max_Order_By {
  csv_property_key?: InputMaybe<Order_By>;
  data_type?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  parent_property_id?: InputMaybe<Order_By>;
  parent_property_value?: InputMaybe<Order_By>;
  product_type_id?: InputMaybe<Order_By>;
}

/** order by min() on columns of table "product_type_properties" */
export interface Product_Type_Properties_Min_Order_By {
  csv_property_key?: InputMaybe<Order_By>;
  data_type?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  parent_property_id?: InputMaybe<Order_By>;
  parent_property_value?: InputMaybe<Order_By>;
  product_type_id?: InputMaybe<Order_By>;
}

/** on_conflict condition type for table "product_type_properties" */
export interface Product_Type_Properties_On_Conflict {
  constraint: Product_Type_Properties_Constraint;
  update_columns?: Array<Product_Type_Properties_Update_Column>;
  where?: InputMaybe<Product_Type_Properties_Bool_Exp>;
}

/** Ordering options when selecting data from "product_type_properties". */
export interface Product_Type_Properties_Order_By {
  ItemList?: InputMaybe<Product_Type_Properties_List_Order_By>;
  csv_property_key?: InputMaybe<Order_By>;
  data_type?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  multiple?: InputMaybe<Order_By>;
  parent_property_id?: InputMaybe<Order_By>;
  parent_property_value?: InputMaybe<Order_By>;
  product_type_id?: InputMaybe<Order_By>;
  required?: InputMaybe<Order_By>;
}

/** primary key columns input for table: product_type_properties */
export interface Product_Type_Properties_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** select columns of table "product_type_properties" */
export enum Product_Type_Properties_Select_Column {
  /** column name */
  CsvPropertyKey = 'csv_property_key',
  /** column name */
  DataType = 'data_type',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  ListId = 'list_id',
  /** column name */
  Multiple = 'multiple',
  /** column name */
  ParentPropertyId = 'parent_property_id',
  /** column name */
  ParentPropertyValue = 'parent_property_value',
  /** column name */
  ProductTypeId = 'product_type_id',
  /** column name */
  Required = 'required'
}

/** select "product_type_properties_aggregate_bool_exp_bool_and_arguments_columns" columns of table "product_type_properties" */
export enum Product_Type_Properties_Select_Column_Product_Type_Properties_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Multiple = 'multiple',
  /** column name */
  Required = 'required'
}

/** select "product_type_properties_aggregate_bool_exp_bool_or_arguments_columns" columns of table "product_type_properties" */
export enum Product_Type_Properties_Select_Column_Product_Type_Properties_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Multiple = 'multiple',
  /** column name */
  Required = 'required'
}

/** input type for updating data in table "product_type_properties" */
export interface Product_Type_Properties_Set_Input {
  csv_property_key?: InputMaybe<Scalars['String']['input']>;
  data_type?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  list_id?: InputMaybe<Scalars['uuid']['input']>;
  multiple?: InputMaybe<Scalars['Boolean']['input']>;
  parent_property_id?: InputMaybe<Scalars['uuid']['input']>;
  parent_property_value?: InputMaybe<Scalars['String']['input']>;
  product_type_id?: InputMaybe<Scalars['uuid']['input']>;
  required?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Streaming cursor of the table "product_type_properties" */
export interface Product_Type_Properties_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Product_Type_Properties_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Product_Type_Properties_Stream_Cursor_Value_Input {
  csv_property_key?: InputMaybe<Scalars['String']['input']>;
  data_type?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  list_id?: InputMaybe<Scalars['uuid']['input']>;
  multiple?: InputMaybe<Scalars['Boolean']['input']>;
  parent_property_id?: InputMaybe<Scalars['uuid']['input']>;
  parent_property_value?: InputMaybe<Scalars['String']['input']>;
  product_type_id?: InputMaybe<Scalars['uuid']['input']>;
  required?: InputMaybe<Scalars['Boolean']['input']>;
}

/** update columns of table "product_type_properties" */
export enum Product_Type_Properties_Update_Column {
  /** column name */
  CsvPropertyKey = 'csv_property_key',
  /** column name */
  DataType = 'data_type',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  ListId = 'list_id',
  /** column name */
  Multiple = 'multiple',
  /** column name */
  ParentPropertyId = 'parent_property_id',
  /** column name */
  ParentPropertyValue = 'parent_property_value',
  /** column name */
  ProductTypeId = 'product_type_id',
  /** column name */
  Required = 'required'
}

export interface Product_Type_Properties_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Type_Properties_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Type_Properties_Bool_Exp;
}

export interface Product_Type_Property_Item_Aggregate_Bool_Exp {
  count?: InputMaybe<Product_Type_Property_Item_Aggregate_Bool_Exp_Count>;
}

export interface Product_Type_Property_Item_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<Product_Type_Property_Item_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Product_Type_Property_Item_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** order by aggregate values of table "product_type_property_item" */
export interface Product_Type_Property_Item_Aggregate_Order_By {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Product_Type_Property_Item_Max_Order_By>;
  min?: InputMaybe<Product_Type_Property_Item_Min_Order_By>;
}

/** input type for inserting array relation for remote table "product_type_property_item" */
export interface Product_Type_Property_Item_Arr_Rel_Insert_Input {
  data: Array<Product_Type_Property_Item_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_Type_Property_Item_On_Conflict>;
}

/** Boolean expression to filter rows from the table "product_type_property_item". All fields are combined with a logical 'AND'. */
export interface Product_Type_Property_Item_Bool_Exp {
  _and?: InputMaybe<Array<Product_Type_Property_Item_Bool_Exp>>;
  _not?: InputMaybe<Product_Type_Property_Item_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Type_Property_Item_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  product_type_list_id?: InputMaybe<Uuid_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "product_type_property_item" */
export enum Product_Type_Property_Item_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductTypePropertyItemPkey = 'product_type_property_item_pkey'
}

/** input type for inserting data into table "product_type_property_item" */
export interface Product_Type_Property_Item_Insert_Input {
  id?: InputMaybe<Scalars['uuid']['input']>;
  product_type_list_id?: InputMaybe<Scalars['uuid']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** order by max() on columns of table "product_type_property_item" */
export interface Product_Type_Property_Item_Max_Order_By {
  id?: InputMaybe<Order_By>;
  product_type_list_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** order by min() on columns of table "product_type_property_item" */
export interface Product_Type_Property_Item_Min_Order_By {
  id?: InputMaybe<Order_By>;
  product_type_list_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** on_conflict condition type for table "product_type_property_item" */
export interface Product_Type_Property_Item_On_Conflict {
  constraint: Product_Type_Property_Item_Constraint;
  update_columns?: Array<Product_Type_Property_Item_Update_Column>;
  where?: InputMaybe<Product_Type_Property_Item_Bool_Exp>;
}

/** Ordering options when selecting data from "product_type_property_item". */
export interface Product_Type_Property_Item_Order_By {
  id?: InputMaybe<Order_By>;
  product_type_list_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** primary key columns input for table: product_type_property_item */
export interface Product_Type_Property_Item_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** select columns of table "product_type_property_item" */
export enum Product_Type_Property_Item_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ProductTypeListId = 'product_type_list_id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "product_type_property_item" */
export interface Product_Type_Property_Item_Set_Input {
  id?: InputMaybe<Scalars['uuid']['input']>;
  product_type_list_id?: InputMaybe<Scalars['uuid']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "product_type_property_item" */
export interface Product_Type_Property_Item_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Product_Type_Property_Item_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Product_Type_Property_Item_Stream_Cursor_Value_Input {
  id?: InputMaybe<Scalars['uuid']['input']>;
  product_type_list_id?: InputMaybe<Scalars['uuid']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "product_type_property_item" */
export enum Product_Type_Property_Item_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ProductTypeListId = 'product_type_list_id',
  /** column name */
  Value = 'value'
}

export interface Product_Type_Property_Item_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Type_Property_Item_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Type_Property_Item_Bool_Exp;
}

/** Boolean expression to filter rows from the table "product_types". All fields are combined with a logical 'AND'. */
export interface Product_Types_Bool_Exp {
  Properties?: InputMaybe<Product_Type_Properties_Bool_Exp>;
  Properties_aggregate?: InputMaybe<Product_Type_Properties_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Product_Types_Bool_Exp>>;
  _not?: InputMaybe<Product_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Types_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "product_types" */
export enum Product_Types_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductTypesPkey = 'product_types_pkey'
}

/** input type for inserting data into table "product_types" */
export interface Product_Types_Insert_Input {
  Properties?: InputMaybe<Product_Type_Properties_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}

/** input type for inserting object relation for remote table "product_types" */
export interface Product_Types_Obj_Rel_Insert_Input {
  data: Product_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_Types_On_Conflict>;
}

/** on_conflict condition type for table "product_types" */
export interface Product_Types_On_Conflict {
  constraint: Product_Types_Constraint;
  update_columns?: Array<Product_Types_Update_Column>;
  where?: InputMaybe<Product_Types_Bool_Exp>;
}

/** Ordering options when selecting data from "product_types". */
export interface Product_Types_Order_By {
  Properties_aggregate?: InputMaybe<Product_Type_Properties_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
}

/** primary key columns input for table: product_types */
export interface Product_Types_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** select columns of table "product_types" */
export enum Product_Types_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "product_types" */
export interface Product_Types_Set_Input {
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "product_types" */
export interface Product_Types_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Product_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Product_Types_Stream_Cursor_Value_Input {
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "product_types" */
export enum Product_Types_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export interface Product_Types_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Types_Bool_Exp;
}

/** Boolean expression to filter rows from the table "products". All fields are combined with a logical 'AND'. */
export interface Products_Bool_Exp {
  CsvProduct?: InputMaybe<Csv_Products_Bool_Exp>;
  Exports?: InputMaybe<Product_Exports_Bool_Exp>;
  Exports_aggregate?: InputMaybe<Product_Exports_Aggregate_Bool_Exp>;
  ProductFiles?: InputMaybe<Product_Files_Bool_Exp>;
  ProductFiles_aggregate?: InputMaybe<Product_Files_Aggregate_Bool_Exp>;
  ProductType?: InputMaybe<Product_Types_Bool_Exp>;
  Properties?: InputMaybe<Product_Properties_Bool_Exp>;
  Properties_aggregate?: InputMaybe<Product_Properties_Aggregate_Bool_Exp>;
  Updates?: InputMaybe<Updates_Bool_Exp>;
  Updates_aggregate?: InputMaybe<Updates_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Products_Bool_Exp>>;
  _not?: InputMaybe<Products_Bool_Exp>;
  _or?: InputMaybe<Array<Products_Bool_Exp>>;
  audited?: InputMaybe<Boolean_Comparison_Exp>;
  csv_product_id?: InputMaybe<Uuid_Comparison_Exp>;
  deprecated?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  product_type_id?: InputMaybe<Uuid_Comparison_Exp>;
  trademark_ref?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
}

/** unique or primary key constraints on table "products" */
export enum Products_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductsPkey = 'products_pkey'
}

/** input type for inserting data into table "products" */
export interface Products_Insert_Input {
  CsvProduct?: InputMaybe<Csv_Products_Obj_Rel_Insert_Input>;
  Exports?: InputMaybe<Product_Exports_Arr_Rel_Insert_Input>;
  ProductFiles?: InputMaybe<Product_Files_Arr_Rel_Insert_Input>;
  ProductType?: InputMaybe<Product_Types_Obj_Rel_Insert_Input>;
  Properties?: InputMaybe<Product_Properties_Arr_Rel_Insert_Input>;
  Updates?: InputMaybe<Updates_Arr_Rel_Insert_Input>;
  audited?: InputMaybe<Scalars['Boolean']['input']>;
  csv_product_id?: InputMaybe<Scalars['uuid']['input']>;
  deprecated?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  product_type_id?: InputMaybe<Scalars['uuid']['input']>;
  trademark_ref?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
}

/** input type for inserting object relation for remote table "products" */
export interface Products_Obj_Rel_Insert_Input {
  data: Products_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Products_On_Conflict>;
}

/** on_conflict condition type for table "products" */
export interface Products_On_Conflict {
  constraint: Products_Constraint;
  update_columns?: Array<Products_Update_Column>;
  where?: InputMaybe<Products_Bool_Exp>;
}

/** Ordering options when selecting data from "products". */
export interface Products_Order_By {
  CsvProduct?: InputMaybe<Csv_Products_Order_By>;
  Exports_aggregate?: InputMaybe<Product_Exports_Aggregate_Order_By>;
  ProductFiles_aggregate?: InputMaybe<Product_Files_Aggregate_Order_By>;
  ProductType?: InputMaybe<Product_Types_Order_By>;
  Properties_aggregate?: InputMaybe<Product_Properties_Aggregate_Order_By>;
  Updates_aggregate?: InputMaybe<Updates_Aggregate_Order_By>;
  audited?: InputMaybe<Order_By>;
  csv_product_id?: InputMaybe<Order_By>;
  deprecated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  product_type_id?: InputMaybe<Order_By>;
  trademark_ref?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
}

/** primary key columns input for table: products */
export interface Products_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** select columns of table "products" */
export enum Products_Select_Column {
  /** column name */
  Audited = 'audited',
  /** column name */
  CsvProductId = 'csv_product_id',
  /** column name */
  Deprecated = 'deprecated',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProductTypeId = 'product_type_id',
  /** column name */
  TrademarkRef = 'trademark_ref',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "products" */
export interface Products_Set_Input {
  audited?: InputMaybe<Scalars['Boolean']['input']>;
  csv_product_id?: InputMaybe<Scalars['uuid']['input']>;
  deprecated?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  product_type_id?: InputMaybe<Scalars['uuid']['input']>;
  trademark_ref?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
}

/** Streaming cursor of the table "products" */
export interface Products_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Products_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Products_Stream_Cursor_Value_Input {
  audited?: InputMaybe<Scalars['Boolean']['input']>;
  csv_product_id?: InputMaybe<Scalars['uuid']['input']>;
  deprecated?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  product_type_id?: InputMaybe<Scalars['uuid']['input']>;
  trademark_ref?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
}

/** update columns of table "products" */
export enum Products_Update_Column {
  /** column name */
  Audited = 'audited',
  /** column name */
  CsvProductId = 'csv_product_id',
  /** column name */
  Deprecated = 'deprecated',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProductTypeId = 'product_type_id',
  /** column name */
  TrademarkRef = 'trademark_ref',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface Products_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Products_Set_Input>;
  /** filter the rows which have to be updated */
  where: Products_Bool_Exp;
}

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export interface Timestamp_Comparison_Exp {
  _eq?: InputMaybe<Scalars['timestamp']['input']>;
  _gt?: InputMaybe<Scalars['timestamp']['input']>;
  _gte?: InputMaybe<Scalars['timestamp']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamp']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamp']['input']>;
  _lte?: InputMaybe<Scalars['timestamp']['input']>;
  _neq?: InputMaybe<Scalars['timestamp']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']['input']>>;
}

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export interface Timestamptz_Comparison_Exp {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
}

export interface Update_Changes_Aggregate_Bool_Exp {
  count?: InputMaybe<Update_Changes_Aggregate_Bool_Exp_Count>;
}

export interface Update_Changes_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<Update_Changes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Update_Changes_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** order by aggregate values of table "update_changes" */
export interface Update_Changes_Aggregate_Order_By {
  avg?: InputMaybe<Update_Changes_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Update_Changes_Max_Order_By>;
  min?: InputMaybe<Update_Changes_Min_Order_By>;
  stddev?: InputMaybe<Update_Changes_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Update_Changes_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Update_Changes_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Update_Changes_Sum_Order_By>;
  var_pop?: InputMaybe<Update_Changes_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Update_Changes_Var_Samp_Order_By>;
  variance?: InputMaybe<Update_Changes_Variance_Order_By>;
}

/** input type for inserting array relation for remote table "update_changes" */
export interface Update_Changes_Arr_Rel_Insert_Input {
  data: Array<Update_Changes_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Update_Changes_On_Conflict>;
}

/** order by avg() on columns of table "update_changes" */
export interface Update_Changes_Avg_Order_By {
  integer?: InputMaybe<Order_By>;
}

/** Boolean expression to filter rows from the table "update_changes". All fields are combined with a logical 'AND'. */
export interface Update_Changes_Bool_Exp {
  _and?: InputMaybe<Array<Update_Changes_Bool_Exp>>;
  _not?: InputMaybe<Update_Changes_Bool_Exp>;
  _or?: InputMaybe<Array<Update_Changes_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  integer?: InputMaybe<Int_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  previous_value?: InputMaybe<String_Comparison_Exp>;
  update_id?: InputMaybe<Uuid_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "update_changes" */
export enum Update_Changes_Constraint {
  /** unique or primary key constraint on columns "id" */
  UpdateChangesPkey = 'update_changes_pkey'
}

/** input type for incrementing numeric columns in table "update_changes" */
export interface Update_Changes_Inc_Input {
  integer?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "update_changes" */
export interface Update_Changes_Insert_Input {
  id?: InputMaybe<Scalars['uuid']['input']>;
  integer?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  previous_value?: InputMaybe<Scalars['String']['input']>;
  update_id?: InputMaybe<Scalars['uuid']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** order by max() on columns of table "update_changes" */
export interface Update_Changes_Max_Order_By {
  id?: InputMaybe<Order_By>;
  integer?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  previous_value?: InputMaybe<Order_By>;
  update_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** order by min() on columns of table "update_changes" */
export interface Update_Changes_Min_Order_By {
  id?: InputMaybe<Order_By>;
  integer?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  previous_value?: InputMaybe<Order_By>;
  update_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** on_conflict condition type for table "update_changes" */
export interface Update_Changes_On_Conflict {
  constraint: Update_Changes_Constraint;
  update_columns?: Array<Update_Changes_Update_Column>;
  where?: InputMaybe<Update_Changes_Bool_Exp>;
}

/** Ordering options when selecting data from "update_changes". */
export interface Update_Changes_Order_By {
  id?: InputMaybe<Order_By>;
  integer?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  previous_value?: InputMaybe<Order_By>;
  update_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** primary key columns input for table: update_changes */
export interface Update_Changes_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** select columns of table "update_changes" */
export enum Update_Changes_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Integer = 'integer',
  /** column name */
  Key = 'key',
  /** column name */
  PreviousValue = 'previous_value',
  /** column name */
  UpdateId = 'update_id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "update_changes" */
export interface Update_Changes_Set_Input {
  id?: InputMaybe<Scalars['uuid']['input']>;
  integer?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  previous_value?: InputMaybe<Scalars['String']['input']>;
  update_id?: InputMaybe<Scalars['uuid']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** order by stddev() on columns of table "update_changes" */
export interface Update_Changes_Stddev_Order_By {
  integer?: InputMaybe<Order_By>;
}

/** order by stddev_pop() on columns of table "update_changes" */
export interface Update_Changes_Stddev_Pop_Order_By {
  integer?: InputMaybe<Order_By>;
}

/** order by stddev_samp() on columns of table "update_changes" */
export interface Update_Changes_Stddev_Samp_Order_By {
  integer?: InputMaybe<Order_By>;
}

/** Streaming cursor of the table "update_changes" */
export interface Update_Changes_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Update_Changes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Update_Changes_Stream_Cursor_Value_Input {
  id?: InputMaybe<Scalars['uuid']['input']>;
  integer?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  previous_value?: InputMaybe<Scalars['String']['input']>;
  update_id?: InputMaybe<Scalars['uuid']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** order by sum() on columns of table "update_changes" */
export interface Update_Changes_Sum_Order_By {
  integer?: InputMaybe<Order_By>;
}

/** update columns of table "update_changes" */
export enum Update_Changes_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Integer = 'integer',
  /** column name */
  Key = 'key',
  /** column name */
  PreviousValue = 'previous_value',
  /** column name */
  UpdateId = 'update_id',
  /** column name */
  Value = 'value'
}

export interface Update_Changes_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Update_Changes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Update_Changes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Update_Changes_Bool_Exp;
}

/** order by var_pop() on columns of table "update_changes" */
export interface Update_Changes_Var_Pop_Order_By {
  integer?: InputMaybe<Order_By>;
}

/** order by var_samp() on columns of table "update_changes" */
export interface Update_Changes_Var_Samp_Order_By {
  integer?: InputMaybe<Order_By>;
}

/** order by variance() on columns of table "update_changes" */
export interface Update_Changes_Variance_Order_By {
  integer?: InputMaybe<Order_By>;
}

export interface Updates_Aggregate_Bool_Exp {
  bool_and?: InputMaybe<Updates_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Updates_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Updates_Aggregate_Bool_Exp_Count>;
}

export interface Updates_Aggregate_Bool_Exp_Bool_And {
  arguments: Updates_Select_Column_Updates_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Updates_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface Updates_Aggregate_Bool_Exp_Bool_Or {
  arguments: Updates_Select_Column_Updates_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Updates_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface Updates_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<Updates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Updates_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** order by aggregate values of table "updates" */
export interface Updates_Aggregate_Order_By {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Updates_Max_Order_By>;
  min?: InputMaybe<Updates_Min_Order_By>;
}

/** input type for inserting array relation for remote table "updates" */
export interface Updates_Arr_Rel_Insert_Input {
  data: Array<Updates_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Updates_On_Conflict>;
}

/** Boolean expression to filter rows from the table "updates". All fields are combined with a logical 'AND'. */
export interface Updates_Bool_Exp {
  UpdateChanges?: InputMaybe<Update_Changes_Bool_Exp>;
  UpdateChanges_aggregate?: InputMaybe<Update_Changes_Aggregate_Bool_Exp>;
  UpdatedBy?: InputMaybe<Users_Bool_Exp>;
  _and?: InputMaybe<Array<Updates_Bool_Exp>>;
  _not?: InputMaybe<Updates_Bool_Exp>;
  _or?: InputMaybe<Array<Updates_Bool_Exp>>;
  audited?: InputMaybe<Boolean_Comparison_Exp>;
  deprecated?: InputMaybe<Boolean_Comparison_Exp>;
  exported?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  product_id?: InputMaybe<Uuid_Comparison_Exp>;
  time?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
}

/** unique or primary key constraints on table "updates" */
export enum Updates_Constraint {
  /** unique or primary key constraint on columns "id" */
  UpdatesPkey = 'updates_pkey'
}

/** input type for inserting data into table "updates" */
export interface Updates_Insert_Input {
  UpdateChanges?: InputMaybe<Update_Changes_Arr_Rel_Insert_Input>;
  UpdatedBy?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  audited?: InputMaybe<Scalars['Boolean']['input']>;
  deprecated?: InputMaybe<Scalars['Boolean']['input']>;
  exported?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  product_id?: InputMaybe<Scalars['uuid']['input']>;
  time?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** order by max() on columns of table "updates" */
export interface Updates_Max_Order_By {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
}

/** order by min() on columns of table "updates" */
export interface Updates_Min_Order_By {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
}

/** on_conflict condition type for table "updates" */
export interface Updates_On_Conflict {
  constraint: Updates_Constraint;
  update_columns?: Array<Updates_Update_Column>;
  where?: InputMaybe<Updates_Bool_Exp>;
}

/** Ordering options when selecting data from "updates". */
export interface Updates_Order_By {
  UpdateChanges_aggregate?: InputMaybe<Update_Changes_Aggregate_Order_By>;
  UpdatedBy?: InputMaybe<Users_Order_By>;
  audited?: InputMaybe<Order_By>;
  deprecated?: InputMaybe<Order_By>;
  exported?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
}

/** primary key columns input for table: updates */
export interface Updates_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** select columns of table "updates" */
export enum Updates_Select_Column {
  /** column name */
  Audited = 'audited',
  /** column name */
  Deprecated = 'deprecated',
  /** column name */
  Exported = 'exported',
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  Time = 'time',
  /** column name */
  UserId = 'user_id'
}

/** select "updates_aggregate_bool_exp_bool_and_arguments_columns" columns of table "updates" */
export enum Updates_Select_Column_Updates_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Audited = 'audited',
  /** column name */
  Deprecated = 'deprecated',
  /** column name */
  Exported = 'exported'
}

/** select "updates_aggregate_bool_exp_bool_or_arguments_columns" columns of table "updates" */
export enum Updates_Select_Column_Updates_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Audited = 'audited',
  /** column name */
  Deprecated = 'deprecated',
  /** column name */
  Exported = 'exported'
}

/** input type for updating data in table "updates" */
export interface Updates_Set_Input {
  audited?: InputMaybe<Scalars['Boolean']['input']>;
  deprecated?: InputMaybe<Scalars['Boolean']['input']>;
  exported?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  product_id?: InputMaybe<Scalars['uuid']['input']>;
  time?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** Streaming cursor of the table "updates" */
export interface Updates_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Updates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Updates_Stream_Cursor_Value_Input {
  audited?: InputMaybe<Scalars['Boolean']['input']>;
  deprecated?: InputMaybe<Scalars['Boolean']['input']>;
  exported?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  product_id?: InputMaybe<Scalars['uuid']['input']>;
  time?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** update columns of table "updates" */
export enum Updates_Update_Column {
  /** column name */
  Audited = 'audited',
  /** column name */
  Deprecated = 'deprecated',
  /** column name */
  Exported = 'exported',
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  Time = 'time',
  /** column name */
  UserId = 'user_id'
}

export interface Updates_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Updates_Set_Input>;
  /** filter the rows which have to be updated */
  where: Updates_Bool_Exp;
}

export interface Users_Aggregate_Bool_Exp {
  count?: InputMaybe<Users_Aggregate_Bool_Exp_Count>;
}

export interface Users_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** order by aggregate values of table "users" */
export interface Users_Aggregate_Order_By {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Max_Order_By>;
  min?: InputMaybe<Users_Min_Order_By>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface Users_Append_Input {
  avatar_design?: InputMaybe<Scalars['jsonb']['input']>;
}

/** input type for inserting array relation for remote table "users" */
export interface Users_Arr_Rel_Insert_Input {
  data: Array<Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
}

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export interface Users_Bool_Exp {
  Permissions?: InputMaybe<Auth_Permissions_Bool_Exp>;
  Permissions_aggregate?: InputMaybe<Auth_Permissions_Aggregate_Bool_Exp>;
  UserGroups?: InputMaybe<Auth_User_Groups_Bool_Exp>;
  UserGroups_aggregate?: InputMaybe<Auth_User_Groups_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  account?: InputMaybe<Auth_Accounts_Bool_Exp>;
  avatar_design?: InputMaybe<Jsonb_Comparison_Exp>;
  avatar_url?: InputMaybe<String_Comparison_Exp>;
  context_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  display_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
}

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface Users_Delete_At_Path_Input {
  avatar_design?: InputMaybe<Array<Scalars['String']['input']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface Users_Delete_Elem_Input {
  avatar_design?: InputMaybe<Scalars['Int']['input']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface Users_Delete_Key_Input {
  avatar_design?: InputMaybe<Scalars['String']['input']>;
}

/** input type for inserting data into table "users" */
export interface Users_Insert_Input {
  Permissions?: InputMaybe<Auth_Permissions_Arr_Rel_Insert_Input>;
  UserGroups?: InputMaybe<Auth_User_Groups_Arr_Rel_Insert_Input>;
  account?: InputMaybe<Auth_Accounts_Obj_Rel_Insert_Input>;
  avatar_design?: InputMaybe<Scalars['jsonb']['input']>;
  avatar_url?: InputMaybe<Scalars['String']['input']>;
  context_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  display_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** order by max() on columns of table "users" */
export interface Users_Max_Order_By {
  avatar_url?: InputMaybe<Order_By>;
  context_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  display_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
}

/** order by min() on columns of table "users" */
export interface Users_Min_Order_By {
  avatar_url?: InputMaybe<Order_By>;
  context_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  display_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
}

/** input type for inserting object relation for remote table "users" */
export interface Users_Obj_Rel_Insert_Input {
  data: Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
}

/** on_conflict condition type for table "users" */
export interface Users_On_Conflict {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
}

/** Ordering options when selecting data from "users". */
export interface Users_Order_By {
  Permissions_aggregate?: InputMaybe<Auth_Permissions_Aggregate_Order_By>;
  UserGroups_aggregate?: InputMaybe<Auth_User_Groups_Aggregate_Order_By>;
  account?: InputMaybe<Auth_Accounts_Order_By>;
  avatar_design?: InputMaybe<Order_By>;
  avatar_url?: InputMaybe<Order_By>;
  context_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  display_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
}

/** primary key columns input for table: users */
export interface Users_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface Users_Prepend_Input {
  avatar_design?: InputMaybe<Scalars['jsonb']['input']>;
}

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  AvatarDesign = 'avatar_design',
  /** column name */
  AvatarUrl = 'avatar_url',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "users" */
export interface Users_Set_Input {
  avatar_design?: InputMaybe<Scalars['jsonb']['input']>;
  avatar_url?: InputMaybe<Scalars['String']['input']>;
  context_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  display_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** Streaming cursor of the table "users" */
export interface Users_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Users_Stream_Cursor_Value_Input {
  avatar_design?: InputMaybe<Scalars['jsonb']['input']>;
  avatar_url?: InputMaybe<Scalars['String']['input']>;
  context_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  display_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  AvatarDesign = 'avatar_design',
  /** column name */
  AvatarUrl = 'avatar_url',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface Users_Updates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Users_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Users_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Bool_Exp;
}

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export interface Uuid_Comparison_Exp {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
}

export type InsertFileMutationVariables = Exact<{
  file: Auth_Files_Insert_Input;
}>;


export type InsertFileMutation = { insert_auth_files_one?: { __typename: 'auth_files', id: string } | null | undefined };

export type GetFilesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  where?: Auth_Files_Bool_Exp;
  order_by?: Array<Auth_Files_Order_By>;
}>;


export type GetFilesQuery = { auth_files: Array<{ __typename: 'auth_files', name: string, id: string, path: string, size: number, languages?: any | null | undefined, ProductFiles: Array<{ __typename: 'product_files', Product: { __typename: 'products', id: string, name: string } }> }>, auth_files_aggregate: { __typename: 'auth_files_aggregate', aggregate?: { __typename: 'auth_files_aggregate_fields', count: number } | null | undefined } };

export type UpdateFileMutationVariables = Exact<{
  fileUpdates: Auth_Files_Set_Input;
  id: Scalars['uuid']['input'];
}>;


export type UpdateFileMutation = { update_auth_files_by_pk?: { __typename: 'auth_files', id: string, languages?: any | null | undefined, name: string, path: string, size: number, updated_at: string } | null | undefined };

export type GetProductFilesQueryVariables = Exact<{
  productId: Scalars['uuid']['input'];
}>;


export type GetProductFilesQuery = { product_files: Array<{ __typename: 'product_files', id: string, technical_part: string, file_id: string, File: { __typename: 'auth_files', id: string, languages?: any | null | undefined, name: string, path: string, size: number, updated_at: string } }> };

export type UpdateProductFilesMutationVariables = Exact<{
  newProductFiles?: InputMaybe<Array<Product_Files_Insert_Input>>;
  productId?: InputMaybe<Scalars['uuid']['input']>;
  update: Updates_Insert_Input;
}>;


export type UpdateProductFilesMutation = { delete_product_files?: { __typename: 'product_files_mutation_response', affected_rows: number } | null | undefined, insert_product_files?: { __typename: 'product_files_mutation_response', affected_rows: number } | null | undefined, insert_updates_one?: { __typename: 'updates', id: string } | null | undefined };

export type GetGroupQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type GetGroupQuery = { auth_groups_by_pk?: { __typename: 'auth_groups', name: string, description?: string | null | undefined, id: string, GroupFeatures: Array<{ __typename: 'auth_group_features', id: string, Feature: { __typename: 'auth_features', id: string, name: string } }>, UserGroups_aggregate: { __typename: 'auth_user_groups_aggregate', aggregate?: { __typename: 'auth_user_groups_aggregate_fields', count: number } | null | undefined } } | null | undefined };

export type GetGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGroupsQuery = { auth_groups: Array<{ __typename: 'auth_groups', name: string, id: string, description?: string | null | undefined, GroupFeatures: Array<{ __typename: 'auth_group_features', id: string, Feature: { __typename: 'auth_features', id: string, name: string } }> }> };

export type GetFeaturesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFeaturesQuery = { auth_features: Array<{ __typename: 'auth_features', id: string, name: string, description?: string | null | undefined, RequiredFeatures?: Array<{ __typename: 'auth_features', id: string, name: string }> | null | undefined, RequiredByFeatures?: Array<{ __typename: 'auth_features', id: string, name: string }> | null | undefined }> };

export type UpdatePermissionsMutationVariables = Exact<{
  newGroupFeatures?: InputMaybe<Array<Auth_Group_Features_Insert_Input>>;
}>;


export type UpdatePermissionsMutation = { delete_auth_group_features?: { __typename: 'auth_group_features_mutation_response', affected_rows: number } | null | undefined, insert_auth_group_features?: { __typename: 'auth_group_features_mutation_response', affected_rows: number } | null | undefined };

export type InsertGroupMutationVariables = Exact<{
  group: Auth_Groups_Insert_Input;
}>;


export type InsertGroupMutation = { insert_auth_groups_one?: { __typename: 'auth_groups', id: string } | null | undefined };

export type UpdateGroupMutationVariables = Exact<{
  _set: Auth_Groups_Set_Input;
  group_id: Scalars['uuid']['input'];
}>;


export type UpdateGroupMutation = { update_auth_groups_by_pk?: { __typename: 'auth_groups', id: string } | null | undefined };

export type DeleteGroupMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type DeleteGroupMutation = { delete_auth_groups_by_pk?: { __typename: 'auth_groups', id: string } | null | undefined };

export type GetProductTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProductTypesQuery = { product_types: Array<{ __typename: 'product_types', name: string, id: string, Properties: Array<{ __typename: 'product_type_properties', required: boolean, key: string, id: string, description?: string | null | undefined, data_type: string, csv_property_key?: string | null | undefined, multiple: boolean, parent_property_id?: string | null | undefined }> }> };

export type GetCsvProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCsvProductsQuery = { csv_products: Array<{ __typename: 'csv_products', id: string, properties: any }> };

export type GetProductsByCsvKeyQueryVariables = Exact<{
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
}>;


export type GetProductsByCsvKeyQuery = { products: Array<{ __typename: 'products', id: string, csv_product_id?: string | null | undefined, Updates: Array<{ __typename: 'updates', id: string }> }> };

export type DeleteProductPropertiesMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type DeleteProductPropertiesMutation = { delete_product_properties?: { __typename: 'product_properties_mutation_response', affected_rows: number } | null | undefined };

export type InsertProductMutationVariables = Exact<{
  object: Products_Insert_Input;
  where?: InputMaybe<Product_Properties_Bool_Exp>;
}>;


export type InsertProductMutation = { insert_products_one?: { __typename: 'products', id: string } | null | undefined, delete_product_properties?: { __typename: 'product_properties_mutation_response', affected_rows: number } | null | undefined };

export type InsertProductsMutationVariables = Exact<{
  objects: Array<Products_Insert_Input>;
}>;


export type InsertProductsMutation = { insert_products?: { __typename: 'products_mutation_response', affected_rows: number } | null | undefined };

export type GetProductsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Products_Bool_Exp>;
}>;


export type GetProductsQuery = { products: Array<{ __typename: 'products', name: string, audited: boolean, deprecated: boolean, id: string, Exports: Array<{ __typename: 'product_exports', id: string }>, ProductType: { __typename: 'product_types', name: string } }>, products_aggregate: { __typename: 'products_aggregate', aggregate?: { __typename: 'products_aggregate_fields', count: number } | null | undefined } };

export type GetProductQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type GetProductQuery = { products_by_pk?: { __typename: 'products', name: string, audited: boolean, deprecated: boolean, id: string, Properties: Array<{ __typename: 'product_properties', id: string, value?: string | null | undefined, key: string, csv_value?: string | null | undefined, integer?: number | null | undefined }>, Exports: Array<{ __typename: 'product_exports', id: string }>, ProductType: { __typename: 'product_types', id: string, name: string, Properties: Array<{ __typename: 'product_type_properties', id: string, csv_property_key?: string | null | undefined, required: boolean, data_type: string, description?: string | null | undefined, key: string, parent_property_id?: string | null | undefined, parent_property_value?: string | null | undefined, multiple: boolean, ItemList?: { __typename: 'product_type_properties_list', Items: Array<{ __typename: 'product_type_property_item', value: string }> } | null | undefined }> }, CsvProduct?: { __typename: 'csv_products', properties: any } | null | undefined, ProductFiles: Array<{ __typename: 'product_files', technical_part: string }> } | null | undefined, products_aggregate: { __typename: 'products_aggregate', aggregate?: { __typename: 'products_aggregate_fields', count: number } | null | undefined } };

export type UpdateProductPropertyMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
  csv_value: Scalars['String']['input'];
}>;


export type UpdateProductPropertyMutation = { update_product_properties_by_pk?: { __typename: 'product_properties', id: string, csv_value?: string | null | undefined } | null | undefined };

export type InsertUpdateMutationVariables = Exact<{
  object: Updates_Insert_Input;
}>;


export type InsertUpdateMutation = { insert_updates_one?: { __typename: 'updates', id: string } | null | undefined };

export type GetProductUpdatesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Updates_Bool_Exp>;
  order_by?: InputMaybe<Array<Updates_Order_By>>;
}>;


export type GetProductUpdatesQuery = { updates: Array<{ __typename: 'updates', audited?: boolean | null | undefined, deprecated?: boolean | null | undefined, id: string, time: string, exported?: boolean | null | undefined, UpdatedBy: { __typename: 'users', display_name?: string | null | undefined, id: string }, UpdateChanges: Array<{ __typename: 'update_changes', id: string, key: string, previous_value?: string | null | undefined, update_id: string, value?: string | null | undefined, integer?: number | null | undefined }> }>, updates_aggregate: { __typename: 'updates_aggregate', aggregate?: { __typename: 'updates_aggregate_fields', count: number } | null | undefined } };

export type GetProductsToUploadQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['uuid']['input']>>;
}>;


export type GetProductsToUploadQuery = { products: Array<{ __typename: 'products', name: string, audited: boolean, deprecated: boolean, id: string, trademark_ref?: string | null | undefined, Properties: Array<{ __typename: 'product_properties', value?: string | null | undefined, key: string, csv_value?: string | null | undefined, integer?: number | null | undefined }>, ProductType: { __typename: 'product_types', id: string, name: string, Properties: Array<{ __typename: 'product_type_properties', id: string, csv_property_key?: string | null | undefined, required: boolean, data_type: string, description?: string | null | undefined, key: string }> }, CsvProduct?: { __typename: 'csv_products', properties: any } | null | undefined, ProductFiles: Array<{ __typename: 'product_files', technical_part: string, file_id: string, File: { __typename: 'auth_files', id: string, languages?: any | null | undefined, name: string, path: string, size: number, updated_at: string } }> }>, products_aggregate: { __typename: 'products_aggregate', aggregate?: { __typename: 'products_aggregate_fields', count: number } | null | undefined } };

export type InsertProductExportsMutationVariables = Exact<{
  objects?: InputMaybe<Array<Product_Exports_Insert_Input>>;
}>;


export type InsertProductExportsMutation = { insert_product_exports?: { __typename: 'product_exports_mutation_response', returning: Array<{ __typename: 'product_exports', id: string }> } | null | undefined };

export type SetExportSuccessfulMutationVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['uuid']['input']>>;
  objects?: InputMaybe<Array<Updates_Insert_Input>>;
  product_ids: Array<Scalars['uuid']['input']>;
}>;


export type SetExportSuccessfulMutation = { update_product_exports?: { __typename: 'product_exports_mutation_response', affected_rows: number } | null | undefined, insert_updates?: { __typename: 'updates_mutation_response', affected_rows: number } | null | undefined, update_products?: { __typename: 'products_mutation_response', affected_rows: number } | null | undefined };

export type GetUserQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type GetUserQuery = { users_by_pk?: { __typename: 'users', id: string, display_name?: string | null | undefined, Permissions: Array<{ __typename: 'auth_permissions', Feature?: { __typename: 'auth_features', name: string, id: string, description?: string | null | undefined } | null | undefined }> } | null | undefined };

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = { users: Array<{ __typename: 'users', display_name?: string | null | undefined, id: string, UserGroups: Array<{ __typename: 'auth_user_groups', Group: { __typename: 'auth_groups', name: string, id: string } }>, Permissions: Array<{ __typename: 'auth_permissions', Feature?: { __typename: 'auth_features', name: string, id: string, description?: string | null | undefined } | null | undefined }> }> };

export type UpdateUserGroupsMutationVariables = Exact<{
  newUserGroups?: InputMaybe<Array<Auth_User_Groups_Insert_Input>>;
  user_id: Scalars['uuid']['input'];
}>;


export type UpdateUserGroupsMutation = { delete_auth_user_groups?: { __typename: 'auth_user_groups_mutation_response', affected_rows: number } | null | undefined, insert_auth_user_groups?: { __typename: 'auth_user_groups_mutation_response', affected_rows: number } | null | undefined };

export const InsertFileDocument = gql`
    mutation insertFile($file: auth_files_insert_input!) {
  insert_auth_files_one(object: $file) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertFileGQL extends Apollo.Mutation<InsertFileMutation, InsertFileMutationVariables> {
    override document = InsertFileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetFilesDocument = gql`
    query getFiles($limit: Int = 10, $offset: Int = 0, $where: auth_files_bool_exp! = {}, $order_by: [auth_files_order_by!]! = {name: asc}) {
  auth_files(limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
    name
    id
    path
    size
    languages
    ProductFiles(distinct_on: product_id) {
      Product {
        id
        name
      }
    }
  }
  auth_files_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetFilesGQL extends Apollo.Query<GetFilesQuery, GetFilesQueryVariables> {
    override document = GetFilesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateFileDocument = gql`
    mutation updateFile($fileUpdates: auth_files_set_input!, $id: uuid!) {
  update_auth_files_by_pk(_set: $fileUpdates, pk_columns: {id: $id}) {
    id
    languages
    name
    path
    size
    updated_at
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateFileGQL extends Apollo.Mutation<UpdateFileMutation, UpdateFileMutationVariables> {
    override document = UpdateFileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProductFilesDocument = gql`
    query getProductFiles($productId: uuid!) {
  product_files(where: {product_id: {_eq: $productId}}) {
    id
    technical_part
    file_id
    File {
      id
      languages
      name
      path
      size
      updated_at
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProductFilesGQL extends Apollo.Query<GetProductFilesQuery, GetProductFilesQueryVariables> {
    override document = GetProductFilesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateProductFilesDocument = gql`
    mutation updateProductFiles($newProductFiles: [product_files_insert_input!] = [], $productId: uuid = "", $update: updates_insert_input!) {
  delete_product_files(where: {product_id: {_eq: $productId}}) {
    affected_rows
  }
  insert_product_files(objects: $newProductFiles) {
    affected_rows
  }
  insert_updates_one(object: $update) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProductFilesGQL extends Apollo.Mutation<UpdateProductFilesMutation, UpdateProductFilesMutationVariables> {
    override document = UpdateProductFilesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetGroupDocument = gql`
    query getGroup($id: uuid!) {
  auth_groups_by_pk(id: $id) {
    name
    description
    id
    GroupFeatures {
      id
      Feature {
        id
        name
      }
    }
    UserGroups_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetGroupGQL extends Apollo.Query<GetGroupQuery, GetGroupQueryVariables> {
    override document = GetGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetGroupsDocument = gql`
    query getGroups {
  auth_groups {
    name
    id
    description
    GroupFeatures {
      id
      Feature {
        id
        name
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetGroupsGQL extends Apollo.Query<GetGroupsQuery, GetGroupsQueryVariables> {
    override document = GetGroupsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetFeaturesDocument = gql`
    query getFeatures {
  auth_features {
    id
    name
    description
    RequiredFeatures {
      id
      name
    }
    RequiredByFeatures {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetFeaturesGQL extends Apollo.Query<GetFeaturesQuery, GetFeaturesQueryVariables> {
    override document = GetFeaturesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePermissionsDocument = gql`
    mutation updatePermissions($newGroupFeatures: [auth_group_features_insert_input!] = []) {
  delete_auth_group_features(where: {id: {_is_null: false}}) {
    affected_rows
  }
  insert_auth_group_features(objects: $newGroupFeatures) {
    affected_rows
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePermissionsGQL extends Apollo.Mutation<UpdatePermissionsMutation, UpdatePermissionsMutationVariables> {
    override document = UpdatePermissionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertGroupDocument = gql`
    mutation insertGroup($group: auth_groups_insert_input!) {
  insert_auth_groups_one(object: $group) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertGroupGQL extends Apollo.Mutation<InsertGroupMutation, InsertGroupMutationVariables> {
    override document = InsertGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateGroupDocument = gql`
    mutation updateGroup($_set: auth_groups_set_input!, $group_id: uuid!) {
  update_auth_groups_by_pk(_set: $_set, pk_columns: {id: $group_id}) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateGroupGQL extends Apollo.Mutation<UpdateGroupMutation, UpdateGroupMutationVariables> {
    override document = UpdateGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteGroupDocument = gql`
    mutation deleteGroup($id: uuid!) {
  delete_auth_groups_by_pk(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteGroupGQL extends Apollo.Mutation<DeleteGroupMutation, DeleteGroupMutationVariables> {
    override document = DeleteGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProductTypesDocument = gql`
    query getProductTypes {
  product_types {
    name
    id
    Properties {
      required
      key
      id
      description
      data_type
      csv_property_key
      multiple
      parent_property_id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProductTypesGQL extends Apollo.Query<GetProductTypesQuery, GetProductTypesQueryVariables> {
    override document = GetProductTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCsvProductsDocument = gql`
    query getCsvProducts {
  csv_products {
    id
    properties
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCsvProductsGQL extends Apollo.Query<GetCsvProductsQuery, GetCsvProductsQueryVariables> {
    override document = GetCsvProductsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProductsByCsvKeyDocument = gql`
    query getProductsByCsvKey($_in: [uuid!]) {
  products(where: {csv_product_id: {_in: $_in}}) {
    id
    csv_product_id
    Updates {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProductsByCsvKeyGQL extends Apollo.Query<GetProductsByCsvKeyQuery, GetProductsByCsvKeyQueryVariables> {
    override document = GetProductsByCsvKeyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteProductPropertiesDocument = gql`
    mutation deleteProductProperties($id: uuid!) {
  delete_product_properties(where: {product_id: {_eq: $id}}) {
    affected_rows
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteProductPropertiesGQL extends Apollo.Mutation<DeleteProductPropertiesMutation, DeleteProductPropertiesMutationVariables> {
    override document = DeleteProductPropertiesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertProductDocument = gql`
    mutation insertProduct($object: products_insert_input!, $where: product_properties_bool_exp = {}) {
  insert_products_one(
    object: $object
    on_conflict: {constraint: products_pkey, update_columns: [name, audited, deprecated, updated_at]}
  ) {
    id
  }
  delete_product_properties(where: $where) {
    affected_rows
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertProductGQL extends Apollo.Mutation<InsertProductMutation, InsertProductMutationVariables> {
    override document = InsertProductDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertProductsDocument = gql`
    mutation insertProducts($objects: [products_insert_input!]!) {
  insert_products(
    objects: $objects
    on_conflict: {constraint: products_pkey, update_columns: [name, audited, deprecated, updated_at]}
  ) {
    affected_rows
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertProductsGQL extends Apollo.Mutation<InsertProductsMutation, InsertProductsMutationVariables> {
    override document = InsertProductsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProductsDocument = gql`
    query getProducts($limit: Int = 10, $offset: Int = 0, $where: products_bool_exp) {
  products(
    limit: $limit
    offset: $offset
    where: $where
    order_by: {updated_at: desc}
  ) {
    name
    audited
    deprecated
    id
    Exports(where: {exported: {_eq: true}}, limit: 1) {
      id
    }
    ProductType {
      name
    }
  }
  products_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProductsGQL extends Apollo.Query<GetProductsQuery, GetProductsQueryVariables> {
    override document = GetProductsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProductDocument = gql`
    query getProduct($id: uuid!) {
  products_by_pk(id: $id) {
    Properties(order_by: {integer: asc_nulls_last}) {
      id
      value
      key
      csv_value
      integer
    }
    Exports(where: {exported: {_eq: true}}, limit: 1) {
      id
    }
    name
    audited
    deprecated
    id
    ProductType {
      id
      name
      Properties {
        id
        csv_property_key
        required
        data_type
        description
        key
        parent_property_id
        parent_property_value
        multiple
        ItemList {
          Items {
            value
          }
        }
      }
    }
    CsvProduct {
      properties
    }
    ProductFiles {
      technical_part
    }
  }
  products_aggregate {
    aggregate {
      count
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProductGQL extends Apollo.Query<GetProductQuery, GetProductQueryVariables> {
    override document = GetProductDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateProductPropertyDocument = gql`
    mutation updateProductProperty($id: uuid!, $csv_value: String!) {
  update_product_properties_by_pk(
    pk_columns: {id: $id}
    _set: {csv_value: $csv_value}
  ) {
    id
    csv_value
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProductPropertyGQL extends Apollo.Mutation<UpdateProductPropertyMutation, UpdateProductPropertyMutationVariables> {
    override document = UpdateProductPropertyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertUpdateDocument = gql`
    mutation InsertUpdate($object: updates_insert_input!) {
  insert_updates_one(object: $object) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertUpdateGQL extends Apollo.Mutation<InsertUpdateMutation, InsertUpdateMutationVariables> {
    override document = InsertUpdateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProductUpdatesDocument = gql`
    query getProductUpdates($limit: Int = 10, $offset: Int = 0, $where: updates_bool_exp = {}, $order_by: [updates_order_by!] = {time: desc}) {
  updates(limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
    audited
    deprecated
    id
    time
    exported
    UpdatedBy {
      display_name
      id
    }
    UpdateChanges(order_by: {key: asc}) {
      id
      key
      previous_value
      update_id
      value
      integer
    }
  }
  updates_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProductUpdatesGQL extends Apollo.Query<GetProductUpdatesQuery, GetProductUpdatesQueryVariables> {
    override document = GetProductUpdatesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProductsToUploadDocument = gql`
    query getProductsToUpload($ids: [uuid!]) {
  products(where: {id: {_in: $ids}}) {
    Properties {
      value
      key
      csv_value
      integer
    }
    name
    audited
    deprecated
    id
    trademark_ref
    ProductType {
      id
      name
      Properties(order_by: {key: desc_nulls_last}) {
        id
        csv_property_key
        required
        data_type
        description
        key
      }
    }
    CsvProduct {
      properties
    }
    ProductFiles {
      technical_part
      file_id
      File {
        id
        languages
        name
        path
        size
        updated_at
      }
    }
  }
  products_aggregate {
    aggregate {
      count
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProductsToUploadGQL extends Apollo.Query<GetProductsToUploadQuery, GetProductsToUploadQueryVariables> {
    override document = GetProductsToUploadDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertProductExportsDocument = gql`
    mutation insertProductExports($objects: [product_exports_insert_input!] = {}) {
  insert_product_exports(objects: $objects) {
    returning {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertProductExportsGQL extends Apollo.Mutation<InsertProductExportsMutation, InsertProductExportsMutationVariables> {
    override document = InsertProductExportsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetExportSuccessfulDocument = gql`
    mutation setExportSuccessful($ids: [uuid!], $objects: [updates_insert_input!] = {}, $product_ids: [uuid!]!) {
  update_product_exports(where: {id: {_in: $ids}}, _set: {exported: true}) {
    affected_rows
  }
  insert_updates(objects: $objects) {
    affected_rows
  }
  update_products(where: {id: {_in: $product_ids}}, _set: {audited: false}) {
    affected_rows
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetExportSuccessfulGQL extends Apollo.Mutation<SetExportSuccessfulMutation, SetExportSuccessfulMutationVariables> {
    override document = SetExportSuccessfulDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUserDocument = gql`
    query getUser($id: uuid!) {
  users_by_pk(id: $id) {
    id
    display_name
    Permissions {
      Feature {
        name
        id
        description
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUserGQL extends Apollo.Query<GetUserQuery, GetUserQueryVariables> {
    override document = GetUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUsersDocument = gql`
    query getUsers {
  users(order_by: {display_name: asc}) {
    display_name
    id
    UserGroups {
      Group {
        name
        id
      }
    }
    Permissions {
      Feature {
        name
        id
        description
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUsersGQL extends Apollo.Query<GetUsersQuery, GetUsersQueryVariables> {
    override document = GetUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserGroupsDocument = gql`
    mutation updateUserGroups($newUserGroups: [auth_user_groups_insert_input!] = [], $user_id: uuid!) {
  delete_auth_user_groups(where: {user_id: {_eq: $user_id}}) {
    affected_rows
  }
  insert_auth_user_groups(objects: $newUserGroups) {
    affected_rows
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserGroupsGQL extends Apollo.Mutation<UpdateUserGroupsMutation, UpdateUserGroupsMutationVariables> {
    override document = UpdateUserGroupsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

  type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

  interface WatchQueryOptionsAlone<V extends OperationVariables> extends Omit<ApolloCore.WatchQueryOptions<V>, 'query' | 'variables'> {}

  interface QueryOptionsAlone<V> extends Omit<ApolloCore.QueryOptions<V>, 'query' | 'variables'> {}

  interface MutationOptionsAlone<T, V> extends Omit<ApolloCore.MutationOptions<T, V>, 'mutation' | 'variables'> {}

  @Injectable({ providedIn: 'root' })
  export class ApolloService {
    constructor(
      private insertFileGql: InsertFileGQL,
      private getFilesGql: GetFilesGQL,
      private updateFileGql: UpdateFileGQL,
      private getProductFilesGql: GetProductFilesGQL,
      private updateProductFilesGql: UpdateProductFilesGQL,
      private getGroupGql: GetGroupGQL,
      private getGroupsGql: GetGroupsGQL,
      private getFeaturesGql: GetFeaturesGQL,
      private updatePermissionsGql: UpdatePermissionsGQL,
      private insertGroupGql: InsertGroupGQL,
      private updateGroupGql: UpdateGroupGQL,
      private deleteGroupGql: DeleteGroupGQL,
      private getProductTypesGql: GetProductTypesGQL,
      private getCsvProductsGql: GetCsvProductsGQL,
      private getProductsByCsvKeyGql: GetProductsByCsvKeyGQL,
      private deleteProductPropertiesGql: DeleteProductPropertiesGQL,
      private insertProductGql: InsertProductGQL,
      private insertProductsGql: InsertProductsGQL,
      private getProductsGql: GetProductsGQL,
      private getProductGql: GetProductGQL,
      private updateProductPropertyGql: UpdateProductPropertyGQL,
      private insertUpdateGql: InsertUpdateGQL,
      private getProductUpdatesGql: GetProductUpdatesGQL,
      private getProductsToUploadGql: GetProductsToUploadGQL,
      private insertProductExportsGql: InsertProductExportsGQL,
      private setExportSuccessfulGql: SetExportSuccessfulGQL,
      private getUserGql: GetUserGQL,
      private getUsersGql: GetUsersGQL,
      private updateUserGroupsGql: UpdateUserGroupsGQL
    ) {}
      
    insertFile(variables: InsertFileMutationVariables, options?: MutationOptionsAlone<InsertFileMutation, InsertFileMutationVariables>) {
      return this.insertFileGql.mutate(variables, options)
    }
    
    getFiles(variables?: GetFilesQueryVariables, options?: QueryOptionsAlone<GetFilesQueryVariables>) {
      return this.getFilesGql.fetch(variables, options)
    }
    
    getFilesWatch(variables?: GetFilesQueryVariables, options?: WatchQueryOptionsAlone<GetFilesQueryVariables>) {
      return this.getFilesGql.watch(variables, options)
    }
    
    updateFile(variables: UpdateFileMutationVariables, options?: MutationOptionsAlone<UpdateFileMutation, UpdateFileMutationVariables>) {
      return this.updateFileGql.mutate(variables, options)
    }
    
    getProductFiles(variables: GetProductFilesQueryVariables, options?: QueryOptionsAlone<GetProductFilesQueryVariables>) {
      return this.getProductFilesGql.fetch(variables, options)
    }
    
    getProductFilesWatch(variables: GetProductFilesQueryVariables, options?: WatchQueryOptionsAlone<GetProductFilesQueryVariables>) {
      return this.getProductFilesGql.watch(variables, options)
    }
    
    updateProductFiles(variables: UpdateProductFilesMutationVariables, options?: MutationOptionsAlone<UpdateProductFilesMutation, UpdateProductFilesMutationVariables>) {
      return this.updateProductFilesGql.mutate(variables, options)
    }
    
    getGroup(variables: GetGroupQueryVariables, options?: QueryOptionsAlone<GetGroupQueryVariables>) {
      return this.getGroupGql.fetch(variables, options)
    }
    
    getGroupWatch(variables: GetGroupQueryVariables, options?: WatchQueryOptionsAlone<GetGroupQueryVariables>) {
      return this.getGroupGql.watch(variables, options)
    }
    
    getGroups(variables?: GetGroupsQueryVariables, options?: QueryOptionsAlone<GetGroupsQueryVariables>) {
      return this.getGroupsGql.fetch(variables, options)
    }
    
    getGroupsWatch(variables?: GetGroupsQueryVariables, options?: WatchQueryOptionsAlone<GetGroupsQueryVariables>) {
      return this.getGroupsGql.watch(variables, options)
    }
    
    getFeatures(variables?: GetFeaturesQueryVariables, options?: QueryOptionsAlone<GetFeaturesQueryVariables>) {
      return this.getFeaturesGql.fetch(variables, options)
    }
    
    getFeaturesWatch(variables?: GetFeaturesQueryVariables, options?: WatchQueryOptionsAlone<GetFeaturesQueryVariables>) {
      return this.getFeaturesGql.watch(variables, options)
    }
    
    updatePermissions(variables?: UpdatePermissionsMutationVariables, options?: MutationOptionsAlone<UpdatePermissionsMutation, UpdatePermissionsMutationVariables>) {
      return this.updatePermissionsGql.mutate(variables, options)
    }
    
    insertGroup(variables: InsertGroupMutationVariables, options?: MutationOptionsAlone<InsertGroupMutation, InsertGroupMutationVariables>) {
      return this.insertGroupGql.mutate(variables, options)
    }
    
    updateGroup(variables: UpdateGroupMutationVariables, options?: MutationOptionsAlone<UpdateGroupMutation, UpdateGroupMutationVariables>) {
      return this.updateGroupGql.mutate(variables, options)
    }
    
    deleteGroup(variables: DeleteGroupMutationVariables, options?: MutationOptionsAlone<DeleteGroupMutation, DeleteGroupMutationVariables>) {
      return this.deleteGroupGql.mutate(variables, options)
    }
    
    getProductTypes(variables?: GetProductTypesQueryVariables, options?: QueryOptionsAlone<GetProductTypesQueryVariables>) {
      return this.getProductTypesGql.fetch(variables, options)
    }
    
    getProductTypesWatch(variables?: GetProductTypesQueryVariables, options?: WatchQueryOptionsAlone<GetProductTypesQueryVariables>) {
      return this.getProductTypesGql.watch(variables, options)
    }
    
    getCsvProducts(variables?: GetCsvProductsQueryVariables, options?: QueryOptionsAlone<GetCsvProductsQueryVariables>) {
      return this.getCsvProductsGql.fetch(variables, options)
    }
    
    getCsvProductsWatch(variables?: GetCsvProductsQueryVariables, options?: WatchQueryOptionsAlone<GetCsvProductsQueryVariables>) {
      return this.getCsvProductsGql.watch(variables, options)
    }
    
    getProductsByCsvKey(variables?: GetProductsByCsvKeyQueryVariables, options?: QueryOptionsAlone<GetProductsByCsvKeyQueryVariables>) {
      return this.getProductsByCsvKeyGql.fetch(variables, options)
    }
    
    getProductsByCsvKeyWatch(variables?: GetProductsByCsvKeyQueryVariables, options?: WatchQueryOptionsAlone<GetProductsByCsvKeyQueryVariables>) {
      return this.getProductsByCsvKeyGql.watch(variables, options)
    }
    
    deleteProductProperties(variables: DeleteProductPropertiesMutationVariables, options?: MutationOptionsAlone<DeleteProductPropertiesMutation, DeleteProductPropertiesMutationVariables>) {
      return this.deleteProductPropertiesGql.mutate(variables, options)
    }
    
    insertProduct(variables: InsertProductMutationVariables, options?: MutationOptionsAlone<InsertProductMutation, InsertProductMutationVariables>) {
      return this.insertProductGql.mutate(variables, options)
    }
    
    insertProducts(variables: InsertProductsMutationVariables, options?: MutationOptionsAlone<InsertProductsMutation, InsertProductsMutationVariables>) {
      return this.insertProductsGql.mutate(variables, options)
    }
    
    getProducts(variables?: GetProductsQueryVariables, options?: QueryOptionsAlone<GetProductsQueryVariables>) {
      return this.getProductsGql.fetch(variables, options)
    }
    
    getProductsWatch(variables?: GetProductsQueryVariables, options?: WatchQueryOptionsAlone<GetProductsQueryVariables>) {
      return this.getProductsGql.watch(variables, options)
    }
    
    getProduct(variables: GetProductQueryVariables, options?: QueryOptionsAlone<GetProductQueryVariables>) {
      return this.getProductGql.fetch(variables, options)
    }
    
    getProductWatch(variables: GetProductQueryVariables, options?: WatchQueryOptionsAlone<GetProductQueryVariables>) {
      return this.getProductGql.watch(variables, options)
    }
    
    updateProductProperty(variables: UpdateProductPropertyMutationVariables, options?: MutationOptionsAlone<UpdateProductPropertyMutation, UpdateProductPropertyMutationVariables>) {
      return this.updateProductPropertyGql.mutate(variables, options)
    }
    
    insertUpdate(variables: InsertUpdateMutationVariables, options?: MutationOptionsAlone<InsertUpdateMutation, InsertUpdateMutationVariables>) {
      return this.insertUpdateGql.mutate(variables, options)
    }
    
    getProductUpdates(variables?: GetProductUpdatesQueryVariables, options?: QueryOptionsAlone<GetProductUpdatesQueryVariables>) {
      return this.getProductUpdatesGql.fetch(variables, options)
    }
    
    getProductUpdatesWatch(variables?: GetProductUpdatesQueryVariables, options?: WatchQueryOptionsAlone<GetProductUpdatesQueryVariables>) {
      return this.getProductUpdatesGql.watch(variables, options)
    }
    
    getProductsToUpload(variables?: GetProductsToUploadQueryVariables, options?: QueryOptionsAlone<GetProductsToUploadQueryVariables>) {
      return this.getProductsToUploadGql.fetch(variables, options)
    }
    
    getProductsToUploadWatch(variables?: GetProductsToUploadQueryVariables, options?: WatchQueryOptionsAlone<GetProductsToUploadQueryVariables>) {
      return this.getProductsToUploadGql.watch(variables, options)
    }
    
    insertProductExports(variables?: InsertProductExportsMutationVariables, options?: MutationOptionsAlone<InsertProductExportsMutation, InsertProductExportsMutationVariables>) {
      return this.insertProductExportsGql.mutate(variables, options)
    }
    
    setExportSuccessful(variables: SetExportSuccessfulMutationVariables, options?: MutationOptionsAlone<SetExportSuccessfulMutation, SetExportSuccessfulMutationVariables>) {
      return this.setExportSuccessfulGql.mutate(variables, options)
    }
    
    getUser(variables: GetUserQueryVariables, options?: QueryOptionsAlone<GetUserQueryVariables>) {
      return this.getUserGql.fetch(variables, options)
    }
    
    getUserWatch(variables: GetUserQueryVariables, options?: WatchQueryOptionsAlone<GetUserQueryVariables>) {
      return this.getUserGql.watch(variables, options)
    }
    
    getUsers(variables?: GetUsersQueryVariables, options?: QueryOptionsAlone<GetUsersQueryVariables>) {
      return this.getUsersGql.fetch(variables, options)
    }
    
    getUsersWatch(variables?: GetUsersQueryVariables, options?: WatchQueryOptionsAlone<GetUsersQueryVariables>) {
      return this.getUsersGql.watch(variables, options)
    }
    
    updateUserGroups(variables: UpdateUserGroupsMutationVariables, options?: MutationOptionsAlone<UpdateUserGroupsMutation, UpdateUserGroupsMutationVariables>) {
      return this.updateUserGroupsGql.mutate(variables, options)
    }
  }
export type insertFileVariables = InsertFileMutationVariables;
export type insertFile = InsertFileMutation;
export type insertFile_insert_auth_files_one = (NonNullable<InsertFileMutation['insert_auth_files_one']>);

export type getFilesVariables = GetFilesQueryVariables;
export type getFiles = GetFilesQuery;
export type getFiles_auth_files = NonNullable<(NonNullable<GetFilesQuery['auth_files']>)[number]>;
export type getFiles_auth_files_ProductFiles = NonNullable<(NonNullable<NonNullable<(NonNullable<GetFilesQuery['auth_files']>)[number]>['ProductFiles']>)[number]>;
export type getFiles_auth_files_ProductFiles_Product = (NonNullable<NonNullable<(NonNullable<NonNullable<(NonNullable<GetFilesQuery['auth_files']>)[number]>['ProductFiles']>)[number]>['Product']>);
export type getFiles_auth_files_aggregate = (NonNullable<GetFilesQuery['auth_files_aggregate']>);
export type getFiles_auth_files_aggregate_aggregate = (NonNullable<(NonNullable<GetFilesQuery['auth_files_aggregate']>)['aggregate']>);

export type updateFileVariables = UpdateFileMutationVariables;
export type updateFile = UpdateFileMutation;
export type updateFile_update_auth_files_by_pk = (NonNullable<UpdateFileMutation['update_auth_files_by_pk']>);

export type getProductFilesVariables = GetProductFilesQueryVariables;
export type getProductFiles = GetProductFilesQuery;
export type getProductFiles_product_files = NonNullable<(NonNullable<GetProductFilesQuery['product_files']>)[number]>;
export type getProductFiles_product_files_File = (NonNullable<NonNullable<(NonNullable<GetProductFilesQuery['product_files']>)[number]>['File']>);

export type updateProductFilesVariables = UpdateProductFilesMutationVariables;
export type updateProductFiles = UpdateProductFilesMutation;
export type updateProductFiles_delete_product_files = (NonNullable<UpdateProductFilesMutation['delete_product_files']>);
export type updateProductFiles_insert_product_files = (NonNullable<UpdateProductFilesMutation['insert_product_files']>);
export type updateProductFiles_insert_updates_one = (NonNullable<UpdateProductFilesMutation['insert_updates_one']>);

export type getGroupVariables = GetGroupQueryVariables;
export type getGroup = GetGroupQuery;
export type getGroup_auth_groups_by_pk = (NonNullable<GetGroupQuery['auth_groups_by_pk']>);
export type getGroup_auth_groups_by_pk_GroupFeatures = NonNullable<(NonNullable<(NonNullable<GetGroupQuery['auth_groups_by_pk']>)['GroupFeatures']>)[number]>;
export type getGroup_auth_groups_by_pk_GroupFeatures_Feature = (NonNullable<NonNullable<(NonNullable<(NonNullable<GetGroupQuery['auth_groups_by_pk']>)['GroupFeatures']>)[number]>['Feature']>);
export type getGroup_auth_groups_by_pk_UserGroups_aggregate = (NonNullable<(NonNullable<GetGroupQuery['auth_groups_by_pk']>)['UserGroups_aggregate']>);
export type getGroup_auth_groups_by_pk_UserGroups_aggregate_aggregate = (NonNullable<(NonNullable<(NonNullable<GetGroupQuery['auth_groups_by_pk']>)['UserGroups_aggregate']>)['aggregate']>);

export type getGroupsVariables = GetGroupsQueryVariables;
export type getGroups = GetGroupsQuery;
export type getGroups_auth_groups = NonNullable<(NonNullable<GetGroupsQuery['auth_groups']>)[number]>;
export type getGroups_auth_groups_GroupFeatures = NonNullable<(NonNullable<NonNullable<(NonNullable<GetGroupsQuery['auth_groups']>)[number]>['GroupFeatures']>)[number]>;
export type getGroups_auth_groups_GroupFeatures_Feature = (NonNullable<NonNullable<(NonNullable<NonNullable<(NonNullable<GetGroupsQuery['auth_groups']>)[number]>['GroupFeatures']>)[number]>['Feature']>);

export type getFeaturesVariables = GetFeaturesQueryVariables;
export type getFeatures = GetFeaturesQuery;
export type getFeatures_auth_features = NonNullable<(NonNullable<GetFeaturesQuery['auth_features']>)[number]>;
export type getFeatures_auth_features_RequiredFeatures = NonNullable<(NonNullable<NonNullable<(NonNullable<GetFeaturesQuery['auth_features']>)[number]>['RequiredFeatures']>)[number]>;
export type getFeatures_auth_features_RequiredByFeatures = NonNullable<(NonNullable<NonNullable<(NonNullable<GetFeaturesQuery['auth_features']>)[number]>['RequiredByFeatures']>)[number]>;

export type updatePermissionsVariables = UpdatePermissionsMutationVariables;
export type updatePermissions = UpdatePermissionsMutation;
export type updatePermissions_delete_auth_group_features = (NonNullable<UpdatePermissionsMutation['delete_auth_group_features']>);
export type updatePermissions_insert_auth_group_features = (NonNullable<UpdatePermissionsMutation['insert_auth_group_features']>);

export type insertGroupVariables = InsertGroupMutationVariables;
export type insertGroup = InsertGroupMutation;
export type insertGroup_insert_auth_groups_one = (NonNullable<InsertGroupMutation['insert_auth_groups_one']>);

export type updateGroupVariables = UpdateGroupMutationVariables;
export type updateGroup = UpdateGroupMutation;
export type updateGroup_update_auth_groups_by_pk = (NonNullable<UpdateGroupMutation['update_auth_groups_by_pk']>);

export type deleteGroupVariables = DeleteGroupMutationVariables;
export type deleteGroup = DeleteGroupMutation;
export type deleteGroup_delete_auth_groups_by_pk = (NonNullable<DeleteGroupMutation['delete_auth_groups_by_pk']>);

export type getProductTypesVariables = GetProductTypesQueryVariables;
export type getProductTypes = GetProductTypesQuery;
export type getProductTypes_product_types = NonNullable<(NonNullable<GetProductTypesQuery['product_types']>)[number]>;
export type getProductTypes_product_types_Properties = NonNullable<(NonNullable<NonNullable<(NonNullable<GetProductTypesQuery['product_types']>)[number]>['Properties']>)[number]>;

export type getCsvProductsVariables = GetCsvProductsQueryVariables;
export type getCsvProducts = GetCsvProductsQuery;
export type getCsvProducts_csv_products = NonNullable<(NonNullable<GetCsvProductsQuery['csv_products']>)[number]>;

export type getProductsByCsvKeyVariables = GetProductsByCsvKeyQueryVariables;
export type getProductsByCsvKey = GetProductsByCsvKeyQuery;
export type getProductsByCsvKey_products = NonNullable<(NonNullable<GetProductsByCsvKeyQuery['products']>)[number]>;
export type getProductsByCsvKey_products_Updates = NonNullable<(NonNullable<NonNullable<(NonNullable<GetProductsByCsvKeyQuery['products']>)[number]>['Updates']>)[number]>;

export type deleteProductPropertiesVariables = DeleteProductPropertiesMutationVariables;
export type deleteProductProperties = DeleteProductPropertiesMutation;
export type deleteProductProperties_delete_product_properties = (NonNullable<DeleteProductPropertiesMutation['delete_product_properties']>);

export type insertProductVariables = InsertProductMutationVariables;
export type insertProduct = InsertProductMutation;
export type insertProduct_insert_products_one = (NonNullable<InsertProductMutation['insert_products_one']>);
export type insertProduct_delete_product_properties = (NonNullable<InsertProductMutation['delete_product_properties']>);

export type insertProductsVariables = InsertProductsMutationVariables;
export type insertProducts = InsertProductsMutation;
export type insertProducts_insert_products = (NonNullable<InsertProductsMutation['insert_products']>);

export type getProductsVariables = GetProductsQueryVariables;
export type getProducts = GetProductsQuery;
export type getProducts_products = NonNullable<(NonNullable<GetProductsQuery['products']>)[number]>;
export type getProducts_products_Exports = NonNullable<(NonNullable<NonNullable<(NonNullable<GetProductsQuery['products']>)[number]>['Exports']>)[number]>;
export type getProducts_products_ProductType = (NonNullable<NonNullable<(NonNullable<GetProductsQuery['products']>)[number]>['ProductType']>);
export type getProducts_products_aggregate = (NonNullable<GetProductsQuery['products_aggregate']>);
export type getProducts_products_aggregate_aggregate = (NonNullable<(NonNullable<GetProductsQuery['products_aggregate']>)['aggregate']>);

export type getProductVariables = GetProductQueryVariables;
export type getProduct = GetProductQuery;
export type getProduct_products_by_pk = (NonNullable<GetProductQuery['products_by_pk']>);
export type getProduct_products_by_pk_Properties = NonNullable<(NonNullable<(NonNullable<GetProductQuery['products_by_pk']>)['Properties']>)[number]>;
export type getProduct_products_by_pk_Exports = NonNullable<(NonNullable<(NonNullable<GetProductQuery['products_by_pk']>)['Exports']>)[number]>;
export type getProduct_products_by_pk_ProductType = (NonNullable<(NonNullable<GetProductQuery['products_by_pk']>)['ProductType']>);
export type getProduct_products_by_pk_ProductType_Properties = NonNullable<(NonNullable<(NonNullable<(NonNullable<GetProductQuery['products_by_pk']>)['ProductType']>)['Properties']>)[number]>;
export type getProduct_products_by_pk_ProductType_Properties_ItemList = (NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<GetProductQuery['products_by_pk']>)['ProductType']>)['Properties']>)[number]>['ItemList']>);
export type getProduct_products_by_pk_ProductType_Properties_ItemList_Items = NonNullable<(NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<GetProductQuery['products_by_pk']>)['ProductType']>)['Properties']>)[number]>['ItemList']>)['Items']>)[number]>;
export type getProduct_products_by_pk_CsvProduct = (NonNullable<(NonNullable<GetProductQuery['products_by_pk']>)['CsvProduct']>);
export type getProduct_products_by_pk_ProductFiles = NonNullable<(NonNullable<(NonNullable<GetProductQuery['products_by_pk']>)['ProductFiles']>)[number]>;
export type getProduct_products_aggregate = (NonNullable<GetProductQuery['products_aggregate']>);
export type getProduct_products_aggregate_aggregate = (NonNullable<(NonNullable<GetProductQuery['products_aggregate']>)['aggregate']>);

export type updateProductPropertyVariables = UpdateProductPropertyMutationVariables;
export type updateProductProperty = UpdateProductPropertyMutation;
export type updateProductProperty_update_product_properties_by_pk = (NonNullable<UpdateProductPropertyMutation['update_product_properties_by_pk']>);

export type InsertUpdateVariables = InsertUpdateMutationVariables;
export type InsertUpdate = InsertUpdateMutation;
export type InsertUpdate_insert_updates_one = (NonNullable<InsertUpdateMutation['insert_updates_one']>);

export type getProductUpdatesVariables = GetProductUpdatesQueryVariables;
export type getProductUpdates = GetProductUpdatesQuery;
export type getProductUpdates_updates = NonNullable<(NonNullable<GetProductUpdatesQuery['updates']>)[number]>;
export type getProductUpdates_updates_UpdatedBy = (NonNullable<NonNullable<(NonNullable<GetProductUpdatesQuery['updates']>)[number]>['UpdatedBy']>);
export type getProductUpdates_updates_UpdateChanges = NonNullable<(NonNullable<NonNullable<(NonNullable<GetProductUpdatesQuery['updates']>)[number]>['UpdateChanges']>)[number]>;
export type getProductUpdates_updates_aggregate = (NonNullable<GetProductUpdatesQuery['updates_aggregate']>);
export type getProductUpdates_updates_aggregate_aggregate = (NonNullable<(NonNullable<GetProductUpdatesQuery['updates_aggregate']>)['aggregate']>);

export type getProductsToUploadVariables = GetProductsToUploadQueryVariables;
export type getProductsToUpload = GetProductsToUploadQuery;
export type getProductsToUpload_products = NonNullable<(NonNullable<GetProductsToUploadQuery['products']>)[number]>;
export type getProductsToUpload_products_Properties = NonNullable<(NonNullable<NonNullable<(NonNullable<GetProductsToUploadQuery['products']>)[number]>['Properties']>)[number]>;
export type getProductsToUpload_products_ProductType = (NonNullable<NonNullable<(NonNullable<GetProductsToUploadQuery['products']>)[number]>['ProductType']>);
export type getProductsToUpload_products_ProductType_Properties = NonNullable<(NonNullable<(NonNullable<NonNullable<(NonNullable<GetProductsToUploadQuery['products']>)[number]>['ProductType']>)['Properties']>)[number]>;
export type getProductsToUpload_products_CsvProduct = (NonNullable<NonNullable<(NonNullable<GetProductsToUploadQuery['products']>)[number]>['CsvProduct']>);
export type getProductsToUpload_products_ProductFiles = NonNullable<(NonNullable<NonNullable<(NonNullable<GetProductsToUploadQuery['products']>)[number]>['ProductFiles']>)[number]>;
export type getProductsToUpload_products_ProductFiles_File = (NonNullable<NonNullable<(NonNullable<NonNullable<(NonNullable<GetProductsToUploadQuery['products']>)[number]>['ProductFiles']>)[number]>['File']>);
export type getProductsToUpload_products_aggregate = (NonNullable<GetProductsToUploadQuery['products_aggregate']>);
export type getProductsToUpload_products_aggregate_aggregate = (NonNullable<(NonNullable<GetProductsToUploadQuery['products_aggregate']>)['aggregate']>);

export type insertProductExportsVariables = InsertProductExportsMutationVariables;
export type insertProductExports = InsertProductExportsMutation;
export type insertProductExports_insert_product_exports = (NonNullable<InsertProductExportsMutation['insert_product_exports']>);
export type insertProductExports_insert_product_exports_returning = NonNullable<(NonNullable<(NonNullable<InsertProductExportsMutation['insert_product_exports']>)['returning']>)[number]>;

export type setExportSuccessfulVariables = SetExportSuccessfulMutationVariables;
export type setExportSuccessful = SetExportSuccessfulMutation;
export type setExportSuccessful_update_product_exports = (NonNullable<SetExportSuccessfulMutation['update_product_exports']>);
export type setExportSuccessful_insert_updates = (NonNullable<SetExportSuccessfulMutation['insert_updates']>);
export type setExportSuccessful_update_products = (NonNullable<SetExportSuccessfulMutation['update_products']>);

export type getUserVariables = GetUserQueryVariables;
export type getUser = GetUserQuery;
export type getUser_users_by_pk = (NonNullable<GetUserQuery['users_by_pk']>);
export type getUser_users_by_pk_Permissions = NonNullable<(NonNullable<(NonNullable<GetUserQuery['users_by_pk']>)['Permissions']>)[number]>;
export type getUser_users_by_pk_Permissions_Feature = (NonNullable<NonNullable<(NonNullable<(NonNullable<GetUserQuery['users_by_pk']>)['Permissions']>)[number]>['Feature']>);

export type getUsersVariables = GetUsersQueryVariables;
export type getUsers = GetUsersQuery;
export type getUsers_users = NonNullable<(NonNullable<GetUsersQuery['users']>)[number]>;
export type getUsers_users_UserGroups = NonNullable<(NonNullable<NonNullable<(NonNullable<GetUsersQuery['users']>)[number]>['UserGroups']>)[number]>;
export type getUsers_users_UserGroups_Group = (NonNullable<NonNullable<(NonNullable<NonNullable<(NonNullable<GetUsersQuery['users']>)[number]>['UserGroups']>)[number]>['Group']>);
export type getUsers_users_Permissions = NonNullable<(NonNullable<NonNullable<(NonNullable<GetUsersQuery['users']>)[number]>['Permissions']>)[number]>;
export type getUsers_users_Permissions_Feature = (NonNullable<NonNullable<(NonNullable<NonNullable<(NonNullable<GetUsersQuery['users']>)[number]>['Permissions']>)[number]>['Feature']>);

export type updateUserGroupsVariables = UpdateUserGroupsMutationVariables;
export type updateUserGroups = UpdateUserGroupsMutation;
export type updateUserGroups_delete_auth_user_groups = (NonNullable<UpdateUserGroupsMutation['delete_auth_user_groups']>);
export type updateUserGroups_insert_auth_user_groups = (NonNullable<UpdateUserGroupsMutation['insert_auth_user_groups']>);

