import { SelectionModel } from '@angular/cdk/collections';
import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    Subject,
    debounceTime,
    firstValueFrom,
    merge, startWith, switchMap, tap,
} from 'rxjs';
import { EprelUploadBuilderService } from 'src/app/services/eprel-upload-builder.service';
import { ProductService } from 'src/app/services/product.service';
import { UserService } from 'src/app/services/user.service';
import { ApolloService, Products_Bool_Exp, getProducts_products } from 'src/gql-generated/generated';

@UntilDestroy()
@Component({
    selector: 'app-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.scss'],
})
export class ProductsComponent {
    /**
     * Paginator used by products table
     */
    @ViewChild('paginator') paginator?: MatPaginator;

    /**
     * Products
     */
    products: getProducts_products[] = [];

    /**
     * Subject used to signal changes in products.
     * A change happens after a product is edited, created or deleted
     */
    productsUpdated = new Subject<void>();

    /**
     * True if waiting for response of product query
     */
    isLoading = true;

    /**
     * Selection model to keep track of selected product ids
     */
    selection = new SelectionModel<string>(true, []);

    /**
     * Form group tracking value of trademark and contact references
     */
    referencesFormGroup = new FormGroup({
        contact: new FormControl<string>('test', Validators.required),
    });

    /**
     * Formcontrol checking the audited box
     */
    auditedControl = new FormControl(0);

    /**
     * Form control used to search
     */
    searchControl = new FormControl('', { nonNullable: true });

    /**
     * After a user has selected a number of audited items and confirmed an export, here is the list of item ids
     * This list will be there until the user confirms that the export succeeded or failed
     */
    exportIds?: string[];

    constructor(
        private apollo: ApolloService,
        private exportBuilder: EprelUploadBuilderService,
        private productService: ProductService,
        private userService: UserService,
    ) { }

    ngAfterViewInit(): void {
        this.productService.initialized.subscribe((init) => {
            if (init && this.paginator) {
                merge(
                    this.paginator?.page || 0,
                    // Emits when items are updated (e.g. deleted)
                    this.productsUpdated,

                    this.auditedControl.valueChanges.pipe(
                        tap(() => { this.paginator!.pageIndex = 0; }),
                    ),
                    // Emits when any relevant queryParam changes
                    this.searchControl.valueChanges.pipe(
                        // Make search control only emit values after user stopped typing for 300ms
                        debounceTime(300),
                        tap(() => { this.paginator!.pageIndex = 0; }),
                    ),

                ).pipe(

                    // Make observable emit on start
                    startWith([]),

                    // Set loading to true.
                    tap(() => {
                        this.isLoading = true;
                    }),

                    // Fetch items via apollo query
                    switchMap(() => {
                        const where: Products_Bool_Exp = {};

                        if (this.searchControl.value) {
                            where.name = { _ilike: `%${this.searchControl.value.trim().toLowerCase()}%` };
                        }
                        if (this.auditedControl.value) {
                            where.audited = { _eq: this.auditedControl.value === 1 };
                        }

                        return this.apollo.getProducts({
                            limit: this.paginator?.pageSize,
                            offset: (this.paginator?.pageIndex || 0) * (this.paginator?.pageSize || 10),
                            where,
                        });
                    }),

                    tap((result: any) => {
                        // Update paginator length
                        if (this.paginator && result.data.products_aggregate.aggregate) {
                            this.paginator.length = result.data[Object.keys(result.data)[1]].aggregate.count;
                        }
                        // Update loading state
                        this.isLoading = false;
                    }),

                    // Take untill component is destroyed
                    untilDestroyed(this),
                ).subscribe((result) => {
                    // Subscribe to emitted values and update data
                    this.products = result.data.products;
                });
            }
        });
    }

    /**
     * Select or deselect all products that are audited on this page
     * @param event Click event containing wether the checkbox is checked or unchecked
     */
    selectAll(event: MatCheckboxChange): void {
        if (event.checked) {
            this.selection.setSelection(...this.products.filter((pr) => pr.audited).map((pr) => pr.id));
        } else {
            this.selection.clear();
        }
    }

    /**
     * Create an export of the currently selected audited products
     */
    async export(): Promise<void> {
        const products = await this.exportBuilder.constructXML(
            this.selection.selected,
            this.referencesFormGroup.controls.contact.value!,
        );

        if (products) {
            const result = await firstValueFrom(this.apollo.insertProductExports({
                objects: this.selection.selected.map((sel) => (
                    {
                        product_id: sel,
                        exported: false,
                        properties: JSON.stringify(products.find((prod) => prod.id === sel)),
                    }
                )),
            }));
            this.exportIds = result.data?.insert_product_exports?.returning.map((ret) => ret.id);
        }
    }

    /**
     * Called when the user confirms or denies the success of uploading the document
     * @param success Wether or uploading the xml at eprel was a success
     */
    async uploadSuccess(success: boolean): Promise<void> {
        if (success) {
            await firstValueFrom(this.apollo.setExportSuccessful({
                ids: this.exportIds,
                objects: this.selection.selected.map((product_id) => ({
                    product_id,
                    exported: true,
                    audited: false,
                    user_id: this.userService.profile.id,
                })),
                product_ids: this.selection.selected,
            }));

            this.exportIds = undefined;
            this.productsUpdated.next();
            this.selection.clear();
        }
    }
}
