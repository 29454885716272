import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    EMPTY, Subject, catchError, map, merge, startWith, switchMap, tap,
} from 'rxjs';
import { ApolloService, Order_By, getFiles_auth_files } from 'src/gql-generated/generated';
import { AttachmentComponent } from '../attachment/attachment.component';

@UntilDestroy()
@Component({
    selector: 'app-files',
    templateUrl: './files.component.html',
    styleUrls: ['./files.component.scss'],
})
export class FilesComponent implements AfterViewInit {
    /**
     * Paginator reference
     */
    @ViewChild(MatPaginator) paginator?: MatPaginator;

    /**
     * Mat sort reference
     */
    @ViewChild(MatSort) sort?: MatSort;

    /**
     * Files shown in table
     */
    files: getFiles_auth_files[] = [];

    /**
     * Columns displayed in table
     */
    tableColumns = ['name', 'languages', 'size', 'used_by', 'actions'];

    /**
     * Subject that emits when files are changed
     */
    filesChanged = new Subject<void>();

    constructor(private apollo: ApolloService, private snackBar: MatSnackBar) {
    }

    /**
     * Load data
     */
    ngAfterViewInit(): void {
        if (this.sort && this.paginator) {
            // Get data everytime sort or paginator changes
            merge(
                // Reset paginator when sort changes
                this.sort.sortChange.pipe(
                    tap(() => { this.paginator!.pageIndex = 0; }),
                ),
                this.paginator.page,
                this.filesChanged.asObservable(),
            ).pipe(
                // Trigger first time data loading
                startWith(null),

                // Get files via apollo query
                switchMap(() => this.apollo.getFiles({
                    // Only include results related to the product
                    limit: this.paginator!.pageSize,
                    offset: this.paginator!.pageIndex * this.paginator!.pageSize,
                    // Add sorting if active
                    ...(this.sort?.active === 'name' && { order_by: [{ name: (this.sort.direction || 'asc') as Order_By }] }),
                    ...(this.sort?.active === 'languages' && { order_by: [{ languages: (this.sort.direction || 'asc') as Order_By }] }),
                    ...(this.sort?.active === 'size' && { order_by: [{ size: (this.sort.direction || 'asc') as Order_By }] }),
                    ...(this.sort?.active === 'used_by' && {
                        order_by: [{ ProductFiles_aggregate: { count: (this.sort.direction || 'asc') as Order_By } }],
                    }),
                }).pipe(
                    // Catch apollo error and return EMPTY observable to keep outer observable alive
                    catchError((error) => {
                        this.snackBar.open('Could not load files', 'Close');
                        return EMPTY;
                    }),
                    // Update paginator length
                    tap((result) => { this.paginator!.length = result.data.auth_files_aggregate.aggregate?.count; }),
                    // Map result to files
                    map((result) => result.data.auth_files),
                )),
                // Automatically unsubscribe on component destruction
                untilDestroyed(this),
            ).subscribe((files) => {
                // Store updates in local variable
                this.files = files;
            });
        }
    }

    /**
     * Deletes a file and then refetches files
     * @param confirmed Confimation status of delete action
     * @param attachment Attachment to delete
     */
    deleteFile(confirmed: boolean, attachment: AttachmentComponent): void {
        if (confirmed) {
            attachment.deleteFile().then((success) => {
                if (success) {
                    this.filesChanged.next();
                }
            });
        }
    }
}
