import { HttpClient } from '@angular/common/http';
import {
    ChangeDetectorRef,
    Component, EventEmitter,
    Input, Output, SimpleChanges,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { ConnectionService } from 'src/app/services/connection.service';
import { FileService } from 'src/app/services/file.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { ApolloService } from 'src/gql-generated/generated';
import { v4 as uuidv4 } from 'uuid';

@Component({
    selector: 'app-attachment',
    templateUrl: './attachment.component.html',
    styleUrls: ['./attachment.component.scss'],
})
export class AttachmentComponent {
    /**
     * File to display. Can either be of a File type or FileInfo
     *
     * When type is File this component will automatically start a minio upload attempt
     * When type is FileInfo this component will show the attachment info/actions
     */
    @Input() file?: Attachment;

    /**
     * Set to false to hide the delete icon
     */
    @Input() allowDelete = true;

    /**
     * Emits when a file is deleted
     */
    @Output() onFileDeletion = new EventEmitter<AttachmentExtended>();

    /**
     * Emits when a file is done uploading (including uploadDelay)
     */
    @Output() onUploadComplete = new EventEmitter<AttachmentExtended>();

    /**
     * Reference to maxFileSize
     */
    maxFileSize = environment.maxFileSize;

    /**
     * File extenstions that can be openen in browser (in a new tab)
     */
    allowOpenInBrowser = ['.jpg', '.pdf', '.png', '.svg', '.mp3', '.mp4', '.gif', '.txt', '.json'];

    /**
     * Minimum time upload should take to avoid UI flickering
     */
    uploadDelay = 750;

    /**
     * Attachment with additional info about status and content
     */
    attachment?: AttachmentExtended;

    /**
     * Available languages
     */
    languages = ['NL', 'EN', 'DE', 'ES', 'FR', 'IT'];

    /**
     * Control tracking selected languages
     */
    languageControl = new FormControl<string[]>([], { validators: Validators.required, nonNullable: true });

    constructor(
        private connection: ConnectionService,
        private snackBar: MatSnackBar,
        private http: HttpClient,
        private user: UserService,
        private cd: ChangeDetectorRef,
        private apollo: ApolloService,
        private fileService: FileService,
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        // Check type of file input and upload if type is File
        if (changes['file'].currentValue) {
            this.setFile();
        }
        // Prevents value changed after checking error
        this.cd.detectChanges();
    }

    /**
     * Set the value of the attachment based on provided file
     */
    setFile(): void {
        if (this.file) {
            const extension = this.file.name.match(/(\.[^.]+)$/)![1];
            this.attachment = {
                allowOpen: this.allowOpenInBrowser.includes(extension),
                completed: new BehaviorSubject(true),
                progress: new BehaviorSubject(100),
                name: this.file.name,
                size: this.file.size,
                path: (this.file as FileInfo).path,
                fileId: (this.file as FileInfo).id,
                languages: (this.file as any).languages,
            };
        }
    }

    /**
     * Attempts to upload the file to minio storage.
     */
    uploadFile(): void {
        if (this.file) {
            // Get extension including . (e.g. .txt)
            const extension = this.file.name.match(/(\.[^.]+)$/)![1];
            const path = `/public/${uuidv4()}${extension}`;

            // Tracks the progress of upload (0-100)
            const progress = new BehaviorSubject<number>(0);
            const completed = new BehaviorSubject<boolean>(false);

            // Callback function used to track progress
            const onProgressUpdate = (progressUpdate: any): void => {
                progress.next(Math.round(progressUpdate.progress * 100));
                if (progressUpdate.progress === 1) {
                    // Add a delay to completion to prevent UI from flickering
                    setTimeout(() => completed.next(true), this.uploadDelay);
                }
            };

            // Set attachment info needed to display the attachment
            this.attachment = {
                name: this.file.name,
                size: this.file.size,
                progress,
                allowOpen: this.allowOpenInBrowser.includes(extension),
                completed,
                error: this.file.size > this.maxFileSize ? 'maxFileSize' : undefined,
                path,
                languages: this.languageControl.value,
            };
            // If file size is within limits, start upload
            if (this.file.size <= this.maxFileSize) {
                this.connection.client.storage.put(
                    path,
                    this.file as File,
                    null,
                    onProgressUpdate,
                ).then((result) => {
                    // Completion returns fileId (id of row in auth.files table)
                    if (result.fileId) {
                        // Add id to attachment
                        this.attachment!.fileId = result.fileId;
                        // Emit completion
                        setTimeout(() => this.onUploadComplete.emit(this.attachment), this.uploadDelay);

                        // Update file languages
                        firstValueFrom(this.apollo.updateFile({
                            id: result.fileId,
                            fileUpdates: {
                                languages: this.languageControl.value,
                            },
                        })).catch(() => this.snackBar.open('Could not save file language', 'Close'));
                    }
                });
            }
        }
    }

    /**
     * Deletes current file (from minio and/or auth.files)
     */
    async deleteFile(): Promise<boolean> {
        if (this.attachment && !this.attachment?.error && this.attachment.path) {
            try {
                await this.connection.client.storage.delete(this.attachment.path);
                this.onFileDeletion.emit(this.attachment);
                return true;
            } catch {
                this.snackBar.open('Could not delete file', 'Close');
            }
        } else {
            this.onFileDeletion.emit();
        }
        return false;
    }

    /**
     * Download file
     */
    async downloadFile(): Promise<void> {
        if (this.file && this.attachment) {
            const blob = await this.fileService.getFileAsBlob(this.attachment.path);

            // Temporarily add an <a> element and click it to download the file
            if (blob) {
                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(blob);
                downloadLink.setAttribute('download', this.file.name);
                document.body.appendChild(downloadLink);
                downloadLink.click();
                downloadLink.remove();
            } else {
                this.snackBar.open(`Could not download ${this.file?.name}`, 'Close');
            }
        }
    }

    /**
     * Open file in a new tab
     *
     * (note that the filename is a random string, it looks like this is default browser behaviour and cannot be changed)
     */
    async openFileInNewTab(): Promise<void> {
        if (this.file && this.attachment?.path) {
            const blob = await this.fileService.getFileAsBlob(this.attachment.path);

            // Open file in a new tab
            if (blob) {
                window.open(window.URL.createObjectURL(blob));
            } else {
                this.snackBar.open(`Could not open ${this.file?.name}`, 'Close');
            }
        }
    }
}

/**
 * Minimal info required to create an attachment
 */
export type FileInfo = { name: string, size: number, path: string, id: string };

/**
 * Accepted input for attachment component
 */
export type Attachment = File | FileInfo;

/**
 * Detailed information needed to succesfully render attachment
 */
export interface AttachmentExtended {
    fileId?: string,
    name: string,
    size: number,
    path: string,
    allowOpen: boolean,
    progress: BehaviorSubject<number>,
    completed: BehaviorSubject<boolean>,
    error?: 'maxFileSize';
    languages?: string[]
}
