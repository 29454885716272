<!-- Display breadcrumbs -->
<div fxLayout="row" class="breadcrumbs-container">
    <div class="breadcrumb" *ngFor="let breadcrumb of breadcrumbService.breadcrumbs; let last = last;" fxLayout="row"
        fxLayoutGap="4px">
        <ng-container *ngIf="!breadcrumb.hide">
            <!-- Optional icon -->
            <mat-icon *ngIf="breadcrumb.icon">
                {{breadcrumb.icon}}
            </mat-icon>
            <!-- Breadcrumb text -->
            <ng-container *ngIf="!last">
                <p [ngClass]="{route: !last}" [routerLink]="breadcrumb.location" routerLinkWrap>
                    {{breadcrumb.text}}
                </p>
            </ng-container>
            <ng-container *ngIf="last">
                <p>{{breadcrumb.text}}</p>
            </ng-container>
            <!-- Add '/' separator if breadcrumb is not last -->
            <p *ngIf="!last">
                <mat-icon class="arrow">chevron_right</mat-icon>
            </p>
        </ng-container>
    </div>
</div>