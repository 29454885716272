import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import {
    ApolloService,
    getProductTypes_product_types,
    Products_Insert_Input,
} from 'src/gql-generated/generated';
import { AccessService } from './access.service';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root',
})
export class ProductService {
    /**
     * General properties of the product types loaded into the app,
     * for the intial version this is only a single product type
     */
    productData?: getProductTypes_product_types[];

    /**
     * Will be true when the profile is loaded
     */
    initialized = new BehaviorSubject<boolean>(false);

    constructor(private apollo: ApolloService, private accessService: AccessService, private userService: UserService) {
        this.initializeProducts();
    }

    /**
     * Use the products in the csv_products table to insert all products that are previously unkown
     */
    async initializeProducts(): Promise<void> {
        this.userService.initialized.subscribe(async (init) => {
            if (init && this.accessService.hasAccess({ feature: 'audit_products' })) {
                this.productData = (await firstValueFrom(this.apollo.getProductTypes())).data.product_types;

                // We can only insert products if product types are known
                if (!this.productData.length) { return; }

                const objects: Products_Insert_Input[] = [];

                const waterHeater = this.productData.find((prod) => prod.name === 'Water heater');
                const spaceHeater = this.productData.find((prod) => prod.name === 'Space heater');
                const combinationHeater = this.productData.find((prod) => prod.name === 'Combination heater');

                // Get a list of all the csv products
                const csvJsonValues = (await firstValueFrom(this.apollo.getCsvProducts())).data.csv_products;

                // Get a list of all the products already in the database using the csv keys
                const localProducts = (await firstValueFrom(this.apollo.getProductsByCsvKey({
                    _in: csvJsonValues.map((pr) => pr.id),
                }))).data.products;

                // Go over all the the products in the json list
                csvJsonValues.forEach(async (csvProduct) => {
                    // Check if a local version of this json product already exist
                    const localProduct = localProducts.find((pr) => pr.csv_product_id === csvProduct.id);

                    if (localProduct && !localProduct.Updates.length) {
                        // Remove the properties of the product first
                        await firstValueFrom(this.apollo.deleteProductProperties({ id: localProduct.id }));
                    }
                    // If product is not in the local database or we have never made an update to the product
                    if (!localProduct || !localProduct.Updates.length) {
                        let type: getProductTypes_product_types;
                        switch (csvProduct.properties.Type) {
                            case 'solo':
                                type = spaceHeater as getProductTypes_product_types;
                                break;
                            case 'kombi':
                                type = combinationHeater as getProductTypes_product_types;
                                break;
                            default:
                                type = waterHeater as getProductTypes_product_types;
                        }

                        // Add the product to the insert promises
                        objects.push({
                            id: localProduct?.id,
                            csv_product_id: csvProduct.id,
                            product_type_id: type.id,
                            name: csvProduct.properties.naam_certificaat,
                            trademark_ref: csvProduct.properties.marketeer,
                        });
                    }
                });

                await firstValueFrom(this.apollo.insertProducts({ objects }));
                this.initialized.next(true);
            }
        });
    }
}
