<div *ngIf="product" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="8px">

    <p fxFlex="50">{{product.name}} </p>

    <p fxFlex>{{product.ProductType.name}} </p>

    <mat-chip [class.highlight]="product.audited" *ngIf="product.audited || !product.Exports.length">{{product.audited ?
        'Audited' : 'Not audited'}}</mat-chip>

    <mat-chip *ngIf="product.Exports.length" class="highlight">Exported</mat-chip>

    <mat-chip *ngIf="product.deprecated">deprecated</mat-chip>

</div>