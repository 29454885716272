<div class="file " fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" *ngIf="attachment">

    <!-- File icon -->
    <div>
        <mat-icon [class.error]="attachment.error">{{attachment.error ? 'error_outline'
            : 'insert_drive_file'}}
        </mat-icon>
    </div>

    <!-- Language selection before file can be uploaded -->
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px"
        *ngIf="attachment.completed.value && !attachment.error && !attachment.path" fxFlex class="before-file-upload">

        <!-- File info -->
        <div fxLayoutAlign="space-between center" *ngIf="attachment.completed.value && !attachment.path"
            class=" ellipsis" fxFlex>

            <div fxLayout="column" class="ellipsis">
                <p class="file-name ellipsis" [matTooltip]="attachment.name" tooltipOnOverflow
                    matTooltipShowDelay="300">
                    {{attachment.name}}
                </p>
                <p class="file-size ellipsis">{{attachment.size | fileSize}}</p>
            </div>
        </div>


        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="8px">
            <!-- Language selection -->
            <mat-form-field class="no-form-field-wrapper">
                <mat-label i18n>Select language(s)</mat-label>
                <mat-select multiple [formControl]="languageControl">
                    <mat-option *ngFor="let language of languages" [value]="language">{{language}}</mat-option>
                </mat-select>
            </mat-form-field>

            <!-- Upload/cancel buttons -->
            <div fxLayout="row" fxLayoutGap="8px">
                <button class="small-button" [class.upload-button]="languageControl.value.length"
                    [disabled]="!languageControl.value.length" mat-stroked-button (click)="uploadFile()">
                    Upload
                    <mat-icon>upload</mat-icon>
                </button>
                <button class="small-icon-button" mat-icon-button (click)="deleteFile()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
    </div>

    <!-- File is uploading-->
    <div fxLayout="column" fxFlex *ngIf="!attachment.completed.value && !attachment.error" class="ellipsis">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="ellipsis" fxLayoutGap="8px">
            <p class="file-name ellipsis" fxFlex [matTooltip]="attachment.name" tooltipOnOverflow
                matTooltipShowDelay="300">
                {{attachment.name}}</p>
        </div>
        <mat-progress-bar mode="determinate" [value]="attachment.progress.value"></mat-progress-bar>
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <p class="file-size">
                {{(attachment.size * attachment.progress.value / 100) | fileSize}}
                of
                {{attachment.size | fileSize}}
            </p>
            <p class="file-progress">Uploading... {{attachment.progress.value}}%
            </p>
        </div>
    </div>

    <!-- File upload is completed -->
    <div fxLayoutAlign="space-between center" fxFlex
        *ngIf="attachment.completed.value && !attachment.error && attachment.path" class="ellipsis">

        <div fxLayout="column" class="ellipsis">
            <p class="file-name ellipsis" [matTooltip]="attachment.name" tooltipOnOverflow matTooltipShowDelay="300">
                {{attachment.name}}
            </p>
            <div fxLayout="row" fxLayoutAlign="start center" class="languages">
                <p class="file-size ellipsis">{{attachment.size | fileSize}} | </p>
                <mat-icon class="language-icon">language</mat-icon>
                <span *ngFor="let language of attachment.languages; let last = last">
                    {{language + (last ? '' : ',&nbsp;')}}
                </span>
            </div>
        </div>

        <!-- Action buttons -->
        <div fxLayout="row">
            <!-- Open in new tab -->
            <button class="small-icon-button" mat-icon-button (click)="openFileInNewTab()" *ngIf="attachment.allowOpen"
                matTooltip='Open in new tab' matTooltipShowDelay="300">
                <mat-icon>open_in_new</mat-icon>
            </button>
            <!-- Download file -->
            <button class="small-icon-button" mat-icon-button (click)="downloadFile()" matTooltip='Download file'
                matTooltipShowDelay="300">
                <mat-icon>file_download</mat-icon>
            </button>
            <!-- Delete file -->
            <button class="small-icon-button" mat-icon-button color="warn" (click)="deleteAttachmentDialog.open()"
                matTooltip='Delete file' matTooltipShowDelay="300" *ngIf="allowDelete">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </div>

    <!-- Error occurred during upload -->
    <div fxLayoutAlign="space-between center" fxFlex *ngIf="attachment.error" class="ellipsis">

        <div fxLayout="column" class="ellipsis">
            <p class="error ellipsis" [matTooltip]="attachment.name" tooltipOnOverflow matTooltipShowDelay="300">
                {{attachment.name}}</p>
            <p class="error ellipsis" *ngIf="attachment.error === 'maxFileSize'">{{attachment.size | fileSize}} |
                Max
                allowed file size: {{maxFileSize | fileSize}}
            </p>
        </div>

        <div fxLayout="row" fxLayoutAlign="center center">
            <button mat-icon-button color="warn" (click)="deleteFile()" aria-label="Delete file">
                <mat-icon svgIcon="trash-x"></mat-icon>
            </button>
        </div>
    </div>

</div>

<!-- Delete attachment dialog content -->
<app-dynamic-dialog #deleteAttachmentDialog (onClose)="$event && deleteFile()">
    <h1 i18n title>Delete attachment</h1>

    <p i18n> Are you sure you want to delete this attachment? This action happens immediately and cannot be undone</p>

    <!-- Custom confirm button -->
    <button confirm-button mat-flat-button color="warn" i18n>Delete attachment</button>
</app-dynamic-dialog>