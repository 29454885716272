import { Injectable } from '@angular/core';
import { InMemoryCache } from '@apollo/client/core';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { OIDCClient, createOIDCClient } from '@inversable/hasura-js-sdk';
import { Apollo } from 'apollo-angular';
import { createClient } from 'graphql-ws';
import { environment } from 'src/environments/environment';
import { filter, firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ConnectionService {
    /**
     * Hasura JS SDK OpenIDConnect Client
     */
    public client: OIDCClient;

    constructor(protected apollo: Apollo) {
        // Initialize hasura-js-sdk app
        const hasuraJsSdk = createOIDCClient({
            IHBURL: environment.backend,
            SSOProvider: environment.ssoProvider,
        });
        this.client = hasuraJsSdk;

        // Initialize ApolloClient instance
        this.apollo.create({
            // Create web socket link
            link: new GraphQLWsLink(
                createClient({
                    // Endpoint of graphql-engine
                    url: environment.graphqlws,

                    // Attempt to create connection even when no GraphQL queries have been made yet
                    lazy: false,

                    connectionParams: async () => {
                        // Check current authentication status
                        const authenticated = await firstValueFrom(this.client.isAuthenticated.pipe(filter((auth) => auth !== null)));
                        if (authenticated) {
                            // Return connection parameters
                            return {
                                headers: {
                                    Authorization: `Bearer ${this.client.accessToken}`,
                                    // Set hasura role to admin if user is allowed the admin role
                                    ...(this.client.getClaim('x-hasura-allowed-roles')?.includes('admin') && { 'x-hasura-role': 'admin' }),
                                },
                            };
                        }
                        // Check if url contains 'code', which is used in an OIDC authorization flow
                        const code = new URLSearchParams(window.location.search).get('code');
                        // Remove URL parameters
                        window.history.replaceState(null, '', window.location.pathname);
                        if (code) {
                            // Attempt to authenticate using code and ssoProvider config
                            const authStatus = await this.client.authenticate(code, environment.ssoProvider);
                            if (authStatus) {
                                // Return connection parameters
                                return {
                                    headers: {
                                        Authorization: `Bearer ${this.client.accessToken}`,
                                        // Set hasura role to admin if user is allowed the admin role
                                        ...(this.client.getClaim('x-hasura-allowed-roles')?.includes('admin') && { 'x-hasura-role': 'admin' }),
                                    },
                                };
                            }
                            // Throw error if authentication failed
                            throw new Error('Invalid authorization code');
                        } else {
                            this.client.login();
                        }
                        throw new Error('Could not create apollo client');
                    },
                }),
            ),
            // Use caching for this GraphQL connection
            cache: new InMemoryCache({}),

            // Disable cahce on queries by default
            defaultOptions: {
                query: {
                    fetchPolicy: 'no-cache',
                },
            },
        });
    }
}
