<ng-template #template>

    <!-- Display dialog in a mat card -->
    <mat-card>

        <!-- Title -->
        <mat-card-header>
            <ng-content select="[title]"></ng-content>
        </mat-card-header>

        <!-- Content -->
        <mat-card-content>
            <ng-content></ng-content>
        </mat-card-content>

        <!-- Actions -->
        <mat-card-actions align="end" fxLayoutGap="8px">
            <!-- Cancel -->
            <span [mat-dialog-close]="false">
                <ng-content select="[cancel-button]"></ng-content>
                <!-- Default cancel button if no cancel-button is found -->
                <button mat-button color="warn" i18n class="default cancel">Cancel</button>
            </span>

            <!-- Confirm -->
            <span [mat-dialog-close]="true">
                <ng-content select="[confirm-button]"></ng-content>
                <!-- Default cancel button if no confirm-button is found -->
                <button mat-flat-button color="warn" i18n class="default">Confirm</button>
            </span>
        </mat-card-actions>

    </mat-card>
</ng-template>