import {
    Directive, Input, TemplateRef, ViewContainerRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AccessService, AppAccess } from '../services/access.service';
import { ConnectionService } from '../services/connection.service';

@Directive({
    selector: '[appAccess]',
})
export class AccessDirective {
    /**
     * Helper variable to keep track of whether the DOM element the directive is attached to is currently in the view
     */
    private isHidden = true;

    /**
     * Active subscriptions that need to be unsubscribed on destroy
     */
    private subscription: Subscription = new Subscription();

    /**
     * Helper variable to keep track of visibility of component in this structural directive
     */
    private shouldDrawComponent = false;

    /**
     * Input to define when access should be granted
     */
    @Input() appAccess?: AppAccess;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private connection: ConnectionService,
        protected access: AccessService,
    ) {
    }

    /**
     * Initialize directive and update view
     */
    ngOnInit(): void {
        this.updateView();

        // Listen to auth state change and update validity
        this.subscription.add(
            this.connection.client.isAuthenticated.subscribe((x) => {
                setTimeout(() => {
                    // Update validity next tick
                    this.updateValidity();
                });
            }),
        );
    }

    /**
     * Update validity on parameter input change
     */
    ngOnChanges(): void {
        this.updateValidity();
    }

    /**
     * Update the current view based on the availability of the supplied feature.
     * Will remove the DOM element from the view when the feature is not enabled.
     * Will add the element to the DOM when enabled.
     */
    private updateView(): void {
        if (this.shouldDrawComponent) {
            if (this.isHidden) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.isHidden = false;
            }
        } else {
            this.viewContainer.clear();
            this.isHidden = true;
        }
    }

    /**
     * Update validity of current access directive
     * Hides component if it should not be drawn
     */
    private async updateValidity(): Promise<void> {
        this.shouldDrawComponent = this.appAccess ? this.access.hasAccess(this.appAccess) : false;

        // Check access and update view
        this.updateView();
    }

    /**
     * Handles the on destroy of the component
     */
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
