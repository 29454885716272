/**
 * Environment variables
 */
export const environment = {
    production: true,
    // Links for connecting to local graphql environment
    backend: `https://${location.hostname}/graphql_backend`,
    graphqlws: `wss://${location.hostname}/graphqlws/v1/graphql`,

    // Add keycloak as supported SSO provider
    ssoProvider: {
        name: 'keycloak',
        clientID: 'eprel-dev',
        // Use host.docker.internal such that both docker and front-end use the same url for keycloak
        tokenEndpoint: 'https://keycloak.inversable.cloud/realms/intergas/protocol/openid-connect/token',
        authorizationEndpoint: 'https://keycloak.inversable.cloud/realms/intergas/protocol/openid-connect/auth',
        logoutEndpoint: 'https://keycloak.inversable.cloud/realms/intergas/protocol/openid-connect/logout',
        loginRedirectURL: 'https://eprel.intergas.inversable.dev/login',
        logoutRedirectURL: 'https://eprel.intergas.inversable.dev/login',
    },

    // Sentry URL
    // sentry_url: 'https://1b2a67fb98bff52450ae38de182d0543@sentry.inversable.com/54',

    // Max file size of 50MB
    maxFileSize: 50000000,

    version: '3e9b86d0',
};
