<!-- Page title -->
<h1 i18n>Permissions</h1>

<div fxLayout="column" *ngIf="groups?.length" fxLayoutGap="16px">

    <!-- Mat table displaying permissions with checkboxes -->
    <table mat-table [dataSource]="features">

        <!-- Feature column -->
        <ng-container matColumnDef="features">
            <th mat-header-cell *matHeaderCellDef i18n> Feature </th>
            <td mat-cell *matCellDef="let feature">
                <div [matTooltip]="feature.description" matTooltipShowDelay="300">
                    {{feature.name}}
                </div>
            </td>
        </ng-container>

        <!-- Column for each group -->
        <ng-container [matColumnDef]="group.id" *ngFor="let group of groups">
            <th mat-header-cell *matHeaderCellDef>
                <div [matTooltip]="group.description || ''" matTooltipShowDelay="300">
                    <a routerLink="groups/{{group.id}}" class="link">{{group.name}}</a>
                </div>
            </th>
            <td mat-cell *matCellDef="let feature">
                <mat-checkbox color="primary"
                    [formControl]="$any(checkboxControls.controls[group.id]).controls[feature.id]">
                </mat-checkbox>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
    </table>

    <!-- Save button -->
    <div fxLayoutAlign="end center" fxLayoutGap="8px">

        <button mat-stroked-button color="primary" i18n routerLink="groups/new" routerLinkWrap>
            <mat-icon>add</mat-icon>
            New role
        </button>
        <button mat-flat-button color="primary" i18n (click)="updatePermissions()">Save permissions</button>
    </div>
</div>