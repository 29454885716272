import {
    Component, EventEmitter, Input, Output,
} from '@angular/core';
import { ApolloService } from 'src/gql-generated/generated';
import { firstValueFrom } from 'rxjs';
import { Item } from '../product-detail.component';

@Component({
    selector: 'app-product-detail-input',
    templateUrl: './product-detail-input.component.html',
    styleUrls: ['./product-detail-input.component.scss'],
})
export class ProductDetailInputComponent {
    /**
     * Item containing the input (and subinput) information
     */
    @Input() item?: Item;

    /**
     * The user clicked the delete button and the item should be deleted
     */
    @Output() delete = new EventEmitter<void>();

    constructor(private apollo: ApolloService) { }

    /**
     * When the csv value changes, the auditer uses the new csv property.
     * Prefill it in the form and update the property in the db so the message won't show again.
     */
    async useNew(): Promise<void> {
        if (!this.item?.productProperty) { throw new Error('Item and property should exist'); }
        // Update the form
        this.item.control.setValue(this.item.newCsvValue);

        // Store the new csv value, so the message won't show again
        await firstValueFrom(this.apollo.updateProductProperty(
            { id: (this.item.productProperty.id as string), csv_value: String(this.item.newCsvValue) },
        ));
        delete this.item.newCsvValue;
    }

    /**
     * When the csv value changes, but the auditer wants to keep the old property.
     * Update the db so the message won't show again
     */
    async keepOld(): Promise<void> {
        if (!this.item?.productProperty) { throw new Error('Item and property should exist'); }
        // Store the new csv value, so the message won't show again
        await firstValueFrom(this.apollo.updateProductProperty({ id: this.item?.productProperty.id, csv_value: String(this.item.newCsvValue) }));

        delete this.item.newCsvValue;
    }
}
