import { Component } from '@angular/core';
import { ConnectionService } from './services/connection.service';
import { EprelUploadBuilderService } from './services/eprel-upload-builder.service';
import { UserService } from './services/user.service';
import { ProductService } from './services/product.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    constructor(
        public connection: ConnectionService,
        public xmlBuilder: EprelUploadBuilderService,
        public user: UserService,
        public productService: ProductService,
    ) { }
}
