<div fxLayout="column">

    <!-- Drag/drop container -->
    <div class="upload-container" fxLayout="row" fxLayoutAlign="center center" (dragover)="onDragOver($event)"
        (drop)="onDrop($event)" (dragenter)="onDragEnter($event)" (dragleave)="onDragLeave($event)"
        [class.hover]="isHovered">

        <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="4px">
            <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="upload-icon">upload_file</mat-icon>
                <p i18n class="description"> Drag and drop or
                    <span class="browse" (click)="fileUpload.click()">browse</span>
                    your files
                </p>
            </div>
            <p class="supported-file-types" i18n>Supported files: pdf, txt, docx, rtf, xlsx and pps</p>
        </div>

        <!-- Hidden input -->
        <input class="hidden" type="file" multiple #fileUpload (change)="filesSelected($any($event))"
            accept=".pdf, .txt, .docx, .rtf, .xlsx, .pps">
    </div>

    <!-- List of files uploaded/being uploaded -->
    <div class="files-container" fxLayout="column">
        <app-attachment [file]="file" (onFileDeletion)="deleteFile($event, i)" [allowDelete]="allowDelete"
            (onUploadComplete)="uploadComplete($event)" *ngFor="let file of files; let i = index"
            class="attachment"></app-attachment>
    </div>

</div>