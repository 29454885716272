import { Component, OnDestroy, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    Subject, Subscription, firstValueFrom, merge, switchMap,
} from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import {
    ApolloService, Product_Properties_Constraint, Product_Properties_Insert_Input, Product_Properties_Update_Column,
    Update_Changes_Insert_Input, getProduct_products_by_pk, getProduct_products_by_pk_ProductType_Properties,
    getProduct_products_by_pk_Properties,
} from 'src/gql-generated/generated';
import { ProductHistoryComponent } from '../product-history/product-history.component';

@UntilDestroy()
@Component({
    selector: 'app-product-detail',
    templateUrl: './product-detail.component.html',
    styleUrls: ['./product-detail.component.scss'],
})
export class ProductDetailComponent implements OnDestroy {
    /**
     * Emits the productTypes that exist for mat-select
     */
    product?: getProduct_products_by_pk | null;

    /**
     * Paginator used by products table
     */
    @ViewChild('history') history?: ProductHistoryComponent;

    /**
     * Control to control the audited toggle, will be set to '1' if audited
     */
    auditedControl = new FormControl<boolean>(false);

    /**
     * Formcontrols related to information that should be given when an exported product is updated again
     */
    exportedFormGroup = new FormGroup({
        REASON_COMMENT: new FormControl('', [Validators.required]),
        REASON_FOR_CHANGE: new FormControl('', [Validators.required]),
        EPREL_MODEL_REGISTRATION_NUMBER: new FormControl('', [Validators.required]),
    });

    /**
     * List of reasons why an exported product can be updated, used in a select
     */
    exportReasons = [
        'CORRECT_TYPO',
        'CHANGE_IN_STANDARDS',
        'LABEL_SCALE_RANGE_CHANGE',
        'CHANGE_REQUESTED_BY_MSA',
        'ADDED_INFORMATION_NO_EFFECT_ON_DECLARATION',
        'REQUEST_CHANGE_BY_EXTERNAL_BODY',
    ];

    /**
     * Formgroup containing all the formcontrols for this product
     */
    productFormGroup = new FormGroup({} as { [key: string]: FormControl });

    /**
     * When the csv is changed for properties of the product,
     * the new csv value will be here, in the key of the product property,
     * This only happens when this object has been editted,
     * otherwise the csv value just overwrite the previous value
     */
    newCsvValues: { [key: string]: string } = {};

    /**
     * An array of items, sorted in the way they should be displayed
     * Items can contain either form fields, or buttons to add form fields
     */
    items: FormItem[] = [];

    /**
     * List of subscriptions
     */
    subscription = new Subscription();

    /**
     * Subject used to signal changes in products.
     * A change happens after a product is edited, created or deleted
     */
    productUpdated = new Subject<void>();

    /**
     * This product has already been exported to EPREL.
     * To make updates to the product, the user has to clarify why it has to be changed
     */
    exported = false;

    constructor(
        private apollo: ApolloService,
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
        private snackBar: MatSnackBar,
    ) { }

    ngAfterViewInit(): void {
        merge(
            this.route.params,
        ).pipe(

            // Fetch items via apollo query
            switchMap(() => this.apollo.getProduct({
                id: this.route.snapshot.paramMap.get('productId') || '',
            }, {
                fetchPolicy: 'no-cache',
            })),

            untilDestroyed(this),
        ).subscribe((result) => {
            this.product = result.data.products_by_pk;
            const properties = this.product?.ProductType.Properties;

            // The product, should exist
            if (this.product && properties) {
                // Make the history component load data
                this.history?.update.next();

                this.auditedControl.setValue(this.product.audited);

                this.items = [];

                // Sort all properties alphabeticly
                const sortedProperties = properties.sort((a, b) => (a.key > b.key ? -1 : 1));

                // Add all the properties without parents or children at the front of the list
                const topLevelProperties = sortedProperties.filter(
                    (pr) => !pr.parent_property_id,
                );

                // Prefill exportedformgroup properties
                this.exportedFormGroup.patchValue({
                    REASON_FOR_CHANGE: this.product.Properties.find((prop) => prop.key === 'REASON_FOR_CHANGE')?.value,
                    REASON_COMMENT: this.product.Properties.find((prop) => prop.key === 'REASON_COMMENT')?.value,
                    EPREL_MODEL_REGISTRATION_NUMBER: this.product.Properties.find((prop) => prop.key === 'EPREL_MODEL_REGISTRATION_NUMBER')?.value,
                });

                // Go over all the properties ids that have child properties
                for (const typeProperty of topLevelProperties) {
                    if (typeProperty.multiple) {
                        // Find a list of all the properties this product has with this id, multiple instances could have been added
                        const productProperties = this.product.Properties.filter((prop) => prop.key === typeProperty.key);

                        // Go over all the found properties, but at least once
                        for (let i = 0; i < Math.max(1, productProperties.length); i++) {
                            // Add the parent property to the list
                            const item = this.createItem(typeProperty, productProperties[i], productProperties[i]?.integer || 0);
                            this.items.push({ item });
                        }
                        // Add an 'add' button
                        this.items.push({ addButton: typeProperty });
                    } else {
                        const prodProp = this.product.Properties.find((prop) => prop.key === typeProperty.key);
                        const item = this.createItem(typeProperty, prodProp);

                        this.items.push({ item });
                    }
                }

                this.exported = this.product.Exports.length > 0;
                this.updateFormValidity();
            }
        });
    }

    /**
     * Function to be called after the attachments are updated, to include them in the current products
     * and to show an updated version of the history
     */
    updateAttachments(): void {
        this.apollo.getProduct({
            id: this.route.snapshot.paramMap.get('productId') || '',
        }, {
            fetchPolicy: 'no-cache',
        }).subscribe((res) => {
            this.product = res.data.products_by_pk;
            this.history?.update.next();
        });
    }

    /**
     * Delete an item from the list of items, only items with multiple instances can be deleted
     */
    removeItem(item: Item): void {
        this.items = this.items.filter((itm) => itm.item !== item);
        if (item.subItems) {
            this.removeSubitems(item.subItems);
        }
    }

    /** */
    removeSubitems(items: Item[]): void {
        items.forEach((item) => {
            (this.productFormGroup as any).removeControl(`${item.integer}${item.typeProperty.key}`);
            if (item.subItems) {
                this.removeSubitems(item.subItems);
            }
        });
    }

    /**
     * Insert a new instance of an item
     * @param typeProperty
     */
    insertItem(typeProperty: getProduct_products_by_pk_ProductType_Properties): void {
        let max = -1;
        if (typeProperty.multiple) {
            // Find the max index for this item
            max = Math.max(...this.items.filter((item) => item.item?.typeProperty.id === typeProperty.id)
                .map((itm) => itm.item?.integer || 0));
        }

        // Insert the item before the add button
        this.items.splice(
            this.items.findIndex((item) => item.addButton?.id === typeProperty.id), // index of add button
            0, // items to delete
            { item: this.createItem(typeProperty, undefined, max + 1) }, // item to insert, add one to the highest integer
        );
    }

    /**
     * Item that converts a product type property to the item interface, containing all the required properties for display
     * @param typeProperty The product type property, general information about this property
     * @param productProperty The properties for this product, when available (only when it is saved before
     * @param integer The instance number of this property, as for some properties there can be multiple
     * @returns An item containing everything to create the form field
     */
    createItem(
        typeProperty: getProduct_products_by_pk_ProductType_Properties,
        productProperty?: getProduct_products_by_pk_Properties,
        integer?: number,
    ): Item {
        if (!this.product) { throw new Error('No product'); }
        // Product as it is store in the csv
        const csvProduct = this.product?.CsvProduct?.properties;
        let control: FormControl;
        // Create validator array
        const validators = (typeProperty.required && !typeProperty.parent_property_id) ? [Validators.required] : [];

        if (typeProperty.data_type === 'number') {
            // Create control for number inputs
            control = new FormControl<number | void>(productProperty
                ? Number(Number(productProperty.value).toFixed(0)) : undefined, [...validators, Validators.pattern(/^[0-9]*$/)]);
        } else if (typeProperty.data_type === 'date') {
            // Create control for text and boolean inputs (booleans are '1' or '0')
            control = new FormControl<Date | void>(productProperty?.value ? new Date(productProperty.value) : undefined, validators);
        } else {
            // Create control for text and boolean inputs (booleans are '1' or '0')
            control = new FormControl<string | null | undefined>(productProperty?.value, validators);
        }

        // Add the control, include the integer to make it unique
        this.productFormGroup.addControl(
            `${integer !== undefined ? integer : ''}${typeProperty.key}`,
            control,
        );

        // Look up if the value of the CSV has changed after the last time
        let newCsvValue: string | undefined;
        if (!productProperty && typeProperty.csv_property_key && csvProduct[typeProperty.csv_property_key] !== undefined) {
            if (typeProperty.data_type === 'number') {
                control.setValue(Number(parseFloat(csvProduct[typeProperty.csv_property_key]).toFixed(0)));
            } else {
                control.setValue(csvProduct[typeProperty.csv_property_key]);
            }
            // Set formcontrol to intial value when nothing is saved yet for this property
            newCsvValue = String(csvProduct[typeProperty.csv_property_key]);
        } else if (!integer && csvProduct && typeProperty.csv_property_key && productProperty
            && String(productProperty?.csv_value) !== String(csvProduct[typeProperty.csv_property_key])) {
            // The value of the CSV has changed since last time it was saved
            newCsvValue = String(csvProduct[typeProperty.csv_property_key]);
        }

        const item: Item = {
            productProperty,
            control,
            typeProperty,
            newCsvValue,
            integer,
        };

        // Find all the child properties
        const childProperties = this.product?.ProductType.Properties.filter((pr) => pr.parent_property_id === typeProperty.id);

        if (childProperties.length) {
            item.subItems = [];

            // Add all the child properties to the list
            for (const cprop of childProperties) {
                const subItemProdProp = this.product.Properties.find(
                    (prop) => prop.key === cprop.key && (integer === undefined || integer === prop.integer),
                );

                // Add the child properties by calling this function, so it works recursively
                item.subItems.push(this.createItem(cprop, subItemProdProp, integer));
            }
        }
        return item;
    }

    /**
     * Go over the form, remove all required validators from fields that are not required because they they are dependend on other fields
     * and add them to those that are required
     */
    updateFormValidity(): void {
        // Add and remove validators for nested items that are required, but only if the parent value meets certain criteria
        for (const item of this.items) {
            if (item.item?.subItems) {
                for (const subitem of item.item.subItems) {
                    if (subitem.typeProperty.required) {
                        const shouldBeInForm = !subitem.typeProperty.parent_property_value
                            || subitem.typeProperty.parent_property_value === item.item.control.value;

                        if (shouldBeInForm
                            && !subitem.control.hasValidator(Validators.required)) {
                            // Item should have validator because it is part of the form
                            subitem.control.addValidators(Validators.required);
                        } else if (!shouldBeInForm
                            && subitem.control.hasValidator(Validators.required)) {
                            // Item should not have a validator because it is hidden
                            subitem.control.removeValidators(Validators.required);
                        }
                        subitem.control.updateValueAndValidity();
                    }
                }
            }
        }
    }

    /**
     * Start editting the product
     * Makes the formgroup dirty (so all the invalid inputs turn red) if the form is invalid.
     */
    async updateProduct(): Promise<void> {
        this.updateFormValidity();

        if (!this.product) { return; }

        if (this.auditedControl.value) {
            // When toggled to audited, all formgroups should be valid
            if (this.productFormGroup.invalid || (this.exported && this.exportedFormGroup.invalid)) {
                this.snackBar.open(
                    'Unable to set product to audited as not all required fields are filled in',
                    'Close',
                    { duration: 100000 },
                );

                this.productFormGroup.markAllAsTouched();
                this.exportedFormGroup.markAllAsTouched();
                return;
            }
            // Check if all conditions that require an attachment as proof have an attachment assigned
            const fileParts = [
                'TESTING_CONDITIONS',
                'CALCULATIONS',
                'GENERAL_DESCRIPTION',
                'MESURED_TECHNICAL_PARAMETERS',
                'REFERENCES_TO_HARMONISED_STANDARDS',
                'SPECIFIC_PRECAUTIONS',
            ];
            const missingParts = fileParts.filter(
                (part) => this.product?.ProductFiles.find((file) => file.technical_part === part) === undefined,
            );

            // Not all required parts are proved using an attachment, give an error message
            if (missingParts.length) {
                this.snackBar.open(
                    `You can not set the product to audited, add attachments for ${missingParts.join(', ')}.`,
                    'Close',
                    { duration: 10000 },
                );
                return;
            }
        }

        // List of properties that are changed
        const properties: Product_Properties_Insert_Input[] = [];
        const updateChanges: Update_Changes_Insert_Input[] = [];

        // Go over all the properties this product can have
        for (const item of this.items) {
            if (item.item) {
                this.handleItem(item.item, properties, updateChanges, item.item.integer);
            }
        }

        const reasonInputs = ['REASON_COMMENT', 'REASON_FOR_CHANGE', 'EPREL_MODEL_REGISTRATION_NUMBER'];
        if (this.exported) {
            const updateInfo = reasonInputs.map((key) => ({
                key,
                value: (this.exportedFormGroup.controls as any)[key].value,
            }));

            properties.push(...updateInfo);
            updateChanges.push(...updateInfo);
        }

        // The value of audited, but only if it is changed
        const audited = this.product.audited !== this.auditedControl.value ? this.auditedControl.value : null;

        // Find all the property ids of the items that are currently in the form
        const currentPropertyIds = this.getProductPropertyIds(this.items.map((item) => item.item).filter((item) => item) as Item[]);

        // Find all the properties that exist in the product, but are no longer visible int he form
        const deletedProperties = this.product.Properties
            .filter((prop) => !currentPropertyIds.includes(prop.id) && !reasonInputs.includes(prop.key)).map((prp) => prp.id);

        // Insert the product properties, the product itself should already exist
        await firstValueFrom(this.apollo.insertProduct({
            object: {
                id: this.product?.id,
                product_type_id: this.product.ProductType.id,
                Properties: {
                    data: properties,
                    on_conflict: {
                        constraint: Product_Properties_Constraint.ProductPropertiesPkey,
                        update_columns: [
                            Product_Properties_Update_Column.Value,
                            Product_Properties_Update_Column.CsvValue,
                        ],
                    },
                },
                name: this.product.CsvProduct?.properties.naam_certificaat,
                audited: this.auditedControl.value,
                updated_at: new Date().toISOString(),
                Updates: {
                    data: [{
                        UpdateChanges: { data: updateChanges },
                        user_id: this.userService.profile.id,
                        audited,
                    }],
                },
            },
            where: {
                id: { _in: deletedProperties },
            },
        }));

        // Product is editted, proceed to products overview
        this.router.navigateByUrl('/products');
    }

    /**
     * Get a list of all product property ids that are in the form at this point
     */
    getProductPropertyIds(items: Item[]): string[] {
        const ids = items.filter((item) => item.productProperty).map((item) => (item.productProperty?.id as string));

        const itemsWithSubitems = items.filter((item) => item.subItems);

        for (const item of itemsWithSubitems) {
            // Filter items that are displayed in the form, so remove conditional items
            const validSubitems = (item.subItems as Item[]).filter((subitem) => !subitem.typeProperty.parent_property_value
            || item.control.value === subitem.typeProperty.parent_property_value);

            // Add the ids of them, and all their subitems to the id list
            ids.push(...this.getProductPropertyIds(validSubitems));
        }

        return ids;
    }

    /**
     * Function to recursively handle all items, fills the properties and the updatechanges arrays that are inserted,
     * only adds changed values to the array
     * @param item Item, potentialy with a list of subitems
     * @param properties array of properties that should be updated
     * @param updateChanges Array of updates that should be updated
     */
    handleItem(item: Item, properties: Product_Properties_Insert_Input[], updateChanges: Update_Changes_Insert_Input[], integer?: number): void {
        if (item.subItems) {
            // Handle subitems recursively
            for (const itm of item.subItems) {
                if (!itm.typeProperty.parent_property_value || item.control.value === itm.typeProperty.parent_property_value) {
                    this.handleItem(itm, properties, updateChanges, integer);
                }
            }
        }
        const value = item.control.value !== null && item.control.value !== undefined && item.control.value !== ''
            ? String(item.control.value) : null;
        const previousValue = item.productProperty?.value !== undefined ? String(item.productProperty.value) : null;
        // Only proceed if it exists, empty inputs are not stored
        if (value !== previousValue) {
            const itm: Product_Properties_Insert_Input = {
                value,
                key: item.typeProperty.key,
                integer,
            };

            // Add it to the update
            updateChanges.push({ previous_value: previousValue, ...itm });

            if (item.productProperty?.id) {
                itm.id = item.productProperty.id;
            }

            // Add it to the product properties
            properties.push({ csv_value: item.newCsvValue, ...itm });
        }
    }

    /**
     * Get rid of subscriptions
     */
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}

/**
 * A form item can be either an item, or a button to add additional item(s)
 */
export interface FormItem {
    /** Item containing all information about a input */
    item?: Item;

    /** Overrides the item to just display an add button, contains product type that should be added */
    addButton?: getProduct_products_by_pk_ProductType_Properties;
}

/**
 * Interface containing an item
 */
export interface Item {
    /** A type property contains the generic information about an item property */
    typeProperty: getProduct_products_by_pk_ProductType_Properties;
    /** The product property contains product specific information */
    productProperty?: getProduct_products_by_pk_Properties;
    /** An optional array of subitems can contain items that should be conditionaly displayed depending on the parent properties */
    subItems?: Item[];
    /** If the csv value is changed in comparison what it was before, a cue should be given to the auditer and this field is filled */
    newCsvValue?: string;
    /** Formcontrol for this property */
    control: FormControl;
    /** In case this is an item that happens multiple time (when multiple = true),
     * the integer is added to add a unique identifier */
    integer?: number;
    /** An item is deletable when there are multiple instances of it */
    deletable?: boolean;
}
