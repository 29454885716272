import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
    BehaviorSubject,
    filter, firstValueFrom,
    map,
    switchMap,
} from 'rxjs';
import { ApolloService, getUser_users_by_pk } from 'src/gql-generated/generated';
import { ConnectionService } from './connection.service';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    /**
     * User profile. Only available after user is authenticated
     */
    profile!: getUser_users_by_pk;

    /**
     * Current role of user.
     */
    role?: HasuraRole;

    /**
     * Will be true when the profile is loaded
     */
    initialized = new BehaviorSubject<boolean>(false);

    constructor(private apollo: ApolloService, private connection: ConnectionService, private snackBar: MatSnackBar) {
        // Wait for user to be authenticated
        firstValueFrom(this.connection.client.isAuthenticated
            .pipe(
                // Authentication status can be null (waiting for response), true or false. Only true inidcates that the user is authenticated
                filter((auth) => !!auth),
                // Query the user object using the user-id from the JWT token
                switchMap(() => this.apollo.getUser({ id: this.connection.client.getClaim('x-hasura-user-id') as string })),
                // Return the user profile
                map((result) => result.data.users_by_pk),
            ))
            .then((profile) => {
                // Store profile in public variable
                this.profile = profile!;
                // Set role
                this.role = this.connection.client.getClaim('x-hasura-allowed-roles')?.includes('admin') ? 'admin'
                    : this.connection.client.getClaim('x-hasura-default-role') as HasuraRole;

                this.initialized.next(true);
            })
            // Notify user in case of error
            .catch(() => { this.snackBar.open('Could not load user profile', 'Close'); });
    }
}

/**
 * Define hasura roles available in eprel
 */
export type HasuraRole = 'admin' | 'user';
