import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    /**
     * History of user navigation
     * New entry is appended on every NavigationEnd event
     */
    private history: string[] = [];

    constructor(private router: Router, private location: Location, private route: ActivatedRoute) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.history.push(event.urlAfterRedirects);
            }
        });
    }

    /**
     * Go back one URL
     * If there is no history navigate to `fallback`. If `fallback` is not set, tries to remove the last URL segment.
     * If URL has no segments, navigate to to '/'
     */
    back(fallback?: string): void {
        if (this.history.length > 0) {
            this.location.back();
        } else if (fallback) {
            this.router.navigateByUrl(fallback);
        } else {
            const segments = (this.route as any)._futureSnapshot._routerState._root.children[0]?.value.url;
            if (segments.length > 1) {
                this.router.navigateByUrl(segments.slice(0, -1).map((segment: any) => segment.path).join('/'));
            } else {
                this.router.navigateByUrl('/');
            }
        }
        this.history.pop();
    }
}
