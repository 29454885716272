import { Component, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom, forkJoin, map } from 'rxjs';
import {
    ApolloService,
    getFeatures_auth_features, getGroups_auth_groups,
} from 'src/gql-generated/generated';

@Component({
    selector: 'app-permissions',
    templateUrl: './permissions.component.html',
    styleUrls: ['./permissions.component.scss'],
})
export class PermissionsComponent {
    /**
     * All groups
     */
    groups?: getGroups_auth_groups[];

    /**
     * All features
     */
    features: getFeatures_auth_features[] = [];

    /**
     * Formgroup containing controls for all checkboxes.
     */
    checkboxControls = new FormGroup<{ [group_id: string]: FormGroup<{ [feature_id: string]: FormControl<boolean> }> }>({});

    /**
     * Defines the order of the features (list of feature_ids)
     */
    featureOrder = ['manage_permissions', 'audit_products', 'export_products'];

    /**
     * Column shown in the permissions table. Array is filled when groups are loaded
     */
    tableColumns: string[] = [];

    /**
     * Observable that emits all groups
     */
    private groups$ = this.apollo.getGroups().pipe(map((x) => x.data.auth_groups));

    /**
     * Observable that emits all features
     */
    private features$ = this.apollo.getFeatures().pipe(map((x) => x.data.auth_features));

    constructor(private apollo: ApolloService, private snackBar: MatSnackBar, private destroy: DestroyRef) {
        // Wait for both groups$ and features$ observable to emit a value
        forkJoin([this.groups$, this.features$]).pipe(takeUntilDestroyed(this.destroy))
            // Subscribe to the result and store groups/features locally
            .subscribe(([groups, features]) => {
                // Store groups and set table columns
                this.groups = groups;
                this.tableColumns = ['features'].concat(this.groups.map((group) => group.id));

                // Sort features using the defined featureOrder
                this.features = [...features].sort((f1, f2) => this.featureOrder.indexOf(f1.id)! - this.featureOrder.indexOf(f2.id)!);

                // Create controls for each group/feature combination
                groups.forEach((group) => {
                    this.checkboxControls.addControl(
                        // Set key to group_id
                        group.id,
                        // Set value to be a new formGroup, with each entry having the feature_id as key
                        // and a new FormControl<boolean> as value
                        new FormGroup(
                            features.reduce((result, feature) => (
                                {
                                    ...result,
                                    [feature.id]: new FormControl<boolean>(
                                        // Intialize value to true if feature was already enabled on first data load
                                        !!group.GroupFeatures.find((gf) => gf.Feature.id === feature.id),
                                    ),
                                }
                            ), {}),
                        ),
                    );
                });

                // Update the hierarchy everytime a checkbox value is changed
                this.checkboxControls.valueChanges.pipe(takeUntilDestroyed(this.destroy)).subscribe(() => {
                    this.updateCheckboxHierarchy();
                });

                // Set checkbox hierarchy once on component initialization
                this.updateCheckboxHierarchy();
            });
    }

    /**
     * Some features require other features to be checked. This function checks if any features triggering these requirements have
     * been enabled, and if so, updates the required features to be checked as well. This function also disables those checkboxes
     * until the features triggering this requirement are uncecked.
     */
    updateCheckboxHierarchy(): void {
        // Make sure required data is available
        if (this.features && this.groups) {
            // Loop over all feature/group combinations
            for (const group of this.groups) {
                for (const feature of this.features) {
                    // Get reference to control tracking checkbox value of feature/group combination
                    const control = this.checkboxControls.controls[group.id]!.controls[feature.id];
                    // Check if any features that require this feature have been checked, thus marking this feature as required
                    const isRequired = feature.RequiredByFeatures?.some(
                        (requiredByFeature) => !!this.checkboxControls.getRawValue()[group.id]![requiredByFeature.id],
                    );
                    // If this feature must be checked, set value to true and disable user interaction
                    if (isRequired) {
                        control.setValue(true, { emitEvent: false });
                        control.disable({ emitEvent: false });
                    } else {
                        // Else, enable the formcontrol in case it was disabled before
                        control.enable({ emitEvent: false });
                    }
                }
            }
        }
    }

    /**
     * Attempts to store the permissions to the back-end.
     * The updatePermissions deletes all previously stored permissions and inserts a complete new set of permissions. Because
     * these operations are included in the same mutation the data will be rolled back when one of the steps fail.
     */
    async updatePermissions(): Promise<void> {
        try {
            const res = await firstValueFrom(this.apollo.updatePermissions({
                // New group features, basen on value of checkboxControls FormGroup
                // 1. Object.entries() converts the object into an array of [groupId, features] pairs.
                // 2. .flatMap() is used to flatten the array.
                // 3. .filter() is used to include only the items where value is truthy, in other words, the enabled permissions.
                // 4. .map() is used to map each remaining item to an object { group_id, feature_id }, which is the expected
                newGroupFeatures:
                    Object.entries(this.checkboxControls.getRawValue())
                        .flatMap(([groupId, features]) => Object.entries(features)
                            .filter(([, value]) => value)
                            .map(([featureId]) => ({ group_id: groupId, feature_id: featureId }))),

            }));
            // Notify the user when the update was succesful
            if (res.data?.insert_auth_group_features) {
                this.snackBar.open('Permissions updated', 'Close');
            } else {
                // Notify the user in case something went wrong
                this.snackBar.open('Could not save permissions', 'Close');
            }
        } catch {
            this.snackBar.open('Could not save permissions', 'Close');
        }
    }
}
