/* eslint-disable max-classes-per-file */
import { NgModule } from '@angular/core';
import { Route, RouterModule, mapToCanActivate } from '@angular/router';
import { getGroup_auth_groups_by_pk, getProduct_products_by_pk } from 'src/gql-generated/generated';
import { FilesComponent } from './components/files/files.component';
import { PermissionsComponent } from './components/permissions/permissions.component';
import { ProductAttachmentsComponent } from './components/products/product-attachments/product-attachments.component';
import { ProductDetailComponent } from './components/products/product-detail/product-detail.component';
import { ProductHistoryComponent } from './components/products/product-history/product-history.component';
import { ProductsComponent } from './components/products/products.component';
import { RoleEditComponent } from './components/role-edit/role-edit.component';
import { UsersComponent } from './components/users/users.component';
import { AccessService } from './services/access.service';
import { Breadcrumb } from './services/breadcrumb.service';

const routes: AppRoute[] = [
    {
        path: 'permissions',
        component: PermissionsComponent,
        canActivate: mapToCanActivate([AccessService]),
        data: {
            features: ['manage_permissions'],
            breadcrumbs: [{ text: 'Permissions', icon: 'key' }],
        },
    },
    {
        path: 'permissions/groups/new',
        component: RoleEditComponent,
        canActivate: mapToCanActivate([AccessService]),
        data: {
            features: ['manage_permissions'],
            breadcrumbs: [{ text: 'Permissions', icon: 'key' }, { hide: true }, { text: 'New role' }],
        },
    },
    {
        path: 'permissions/groups/:groupId',
        component: RoleEditComponent,
        canActivate: mapToCanActivate([AccessService]),
        data: {
            features: ['manage_permissions'],
            breadcrumbs: [
                { text: 'Permissions', icon: 'key' },
                { hide: true },
                { query: 'getGroup', displayFunction: (group: getGroup_auth_groups_by_pk) => group.name }],
        },
    },
    {
        path: 'users',
        component: UsersComponent,
        canActivate: mapToCanActivate([AccessService]),
        data: {
            features: ['manage_permissions'],
            breadcrumbs: [{ text: 'Users', icon: 'people' }],
        },
    },
    {
        path: 'files',
        component: FilesComponent,
        data: {
            breadcrumbs: [{ text: 'Files', icon: 'insert_drive_file' }],
        },
    },
    {
        path: 'products',
        component: ProductsComponent,
        data: {
            breadcrumbs: [{ text: 'Products', icon: 'inventory' }],
        },
    },
    {
        path: 'products/:productId',
        component: ProductDetailComponent,
        data: {
            breadcrumbs: [
                { text: 'Products', icon: 'heat_pump' },
                { query: 'getProduct', displayFunction: (product: getProduct_products_by_pk) => product.name }],
        },
    },
    {
        path: 'products/:productId/history',
        component: ProductHistoryComponent,
        data: {
            breadcrumbs: [
                { text: 'Products', icon: 'heat_pump' },
                { query: 'getProduct', displayFunction: (product: getProduct_products_by_pk) => product.name },
                { text: 'History' },
            ],
        },
    },
    {
        path: 'products/:productId/attachments',
        component: ProductAttachmentsComponent,
        data: {
            breadcrumbs: [
                { text: 'Products', icon: 'heat_pump' },
                { query: 'getProduct', displayFunction: (product: getProduct_products_by_pk) => product.name },
                { text: 'Attachments' },
            ],
        },
    },
    { path: '**', component: ProductsComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule { }

/**
 * Extension of default Route type to allow breadcrumbs in data
 */
export type AppRoute = Route & {
    data?: {
        /**
         * Defines an array of breadcrumbs that needs to be displayed for this component.
         * Each breadcrumb can either be a static value, or an value retrieved via an apollo query.
         */
        breadcrumbs?: Breadcrumb[]
    }
};
