<!-- Page title -->
<h1 i18n>Files</h1>

<div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="16px">

    <!-- Upload new files via app-attachment-upload -->
    <h3 i18n>Upload new files</h3>
    <app-attachment-upload (onUploadComplete)="filesChanged.next()"
        (onFileDeletion)="filesChanged.next()"></app-attachment-upload>


    <!-- Table of existing files -->
    <h3 i18n>Existing files</h3>
    <div>
        <!-- Mat table displaying files -->
        <table mat-table [dataSource]="files" matSort>

            <!-- Name column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef i18n mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let file">
                    {{file.name}}
                </td>
            </ng-container>

            <!-- Languages column -->
            <ng-container matColumnDef="languages">
                <th mat-header-cell *matHeaderCellDef i18n mat-sort-header> Languages </th>
                <td mat-cell *matCellDef="let file">
                    <span *ngFor="let language of file.languages; let last = last">
                        {{language + (last ? '' : ', ')}}
                    </span>
                </td>
            </ng-container>

            <!-- Size -->
            <ng-container matColumnDef="size">
                <th mat-header-cell *matHeaderCellDef i18n mat-sort-header> Size </th>
                <td mat-cell *matCellDef="let file">
                    {{file.size | fileSize}}
                </td>
            </ng-container>

            <!-- Used_by -->
            <ng-container matColumnDef="used_by">
                <th mat-header-cell *matHeaderCellDef i18n mat-sort-header> Used by </th>
                <td mat-cell *matCellDef="let file">
                    <mat-chip routerLink="/products/{{productFile.Product.id}}" routerLinkWrap
                        *ngFor="let productFile of file.ProductFiles">
                        <mat-icon>heat_pump</mat-icon>
                        <span>{{productFile.Product.name}}</span>
                    </mat-chip>
                    <i *ngIf="!file.ProductFiles.length" i18n>Not in use</i>
                </td>
            </ng-container>

            <!-- Actions -->
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef i18n> Actions </th>
                <td mat-cell *matCellDef="let file">

                    <!-- Create a hidden app-attachment for easy access to open/delete functionality -->
                    <app-attachment [file]="file" #attachment class="hidden"></app-attachment>

                    <!-- Action buttons -->
                    <div fxLayout="row" fxLayoutAlign="end center" class="action-buttons">

                        <!-- Open file in new tab (if file type is supported by browser) -->
                        <button class="small-icon-button" mat-icon-button (click)="attachment.openFileInNewTab()"
                            *ngIf="attachment.attachment?.allowOpen" matTooltip='Open in new tab'
                            matTooltipShowDelay="300">
                            <mat-icon>open_in_new</mat-icon>
                        </button>

                        <!-- Download file -->
                        <button class="small-icon-button" mat-icon-button (click)="attachment.downloadFile()"
                            matTooltip='Download file' matTooltipShowDelay="300">
                            <mat-icon>file_download</mat-icon>
                        </button>

                        <!-- Delete file (only allow if file is currently not in use) -->
                        <span
                            [matTooltip]="file.ProductFiles.length ? 'Cannot delete files that are in use' : 'Delete file'"
                            matTooltipShowDelay=300>
                            <button class="small-icon-button" mat-icon-button color="warn"
                                (click)="deleteAttachmentDialog.open()" [disabled]="!!file.ProductFiles.length">
                                <mat-icon>delete</mat-icon>
                            </button>

                            <!-- Delete file confirmation dialog -->
                            <app-dynamic-dialog #deleteAttachmentDialog (onClose)="deleteFile($event, attachment)">
                                <h1 i18n title>Delete attachment</h1>

                                <p i18n> Are you sure you want to delete this attachment? This action happens
                                    immediately and cannot be undone</p>

                                <!-- Custom confirm button -->
                                <button confirm-button mat-flat-button color="warn" i18n>Delete attachment</button>
                            </app-dynamic-dialog>
                        </span>

                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
        </table>

        <!-- Mat paginator -->
        <mat-paginator [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
    </div>
</div>