import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
    ChangeDetectorRef,
    Component, ContentChild,
    Input,
    TemplateRef, ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    BehaviorSubject,
    EMPTY,
    catchError,
    combineLatestWith,
    debounceTime,
    firstValueFrom,
    map,
    of,
    switchMap,
    tap,
    timer,
} from 'rxjs';
import { Order_By } from 'src/gql-generated/generated';

@UntilDestroy()
@Component({
    selector: 'app-smart-select',
    templateUrl: './smart-select.component.html',
    styleUrls: ['./smart-select.component.scss'],
})
export class SmartSelectComponent {
    /**
     * Reference to template for mat-option that is optionally provided
     */
    @ContentChild('matOptionContent') matOptionContent: TemplateRef<any> | null = null;

    /**
     * Reference to virual scroll viewport
     */
    @ViewChild(CdkVirtualScrollViewport) scrollViewPort: CdkVirtualScrollViewport | null = null;

    /**
     * Settings
     */
    @Input() settings?: SmartSelectSettings;

    /**
     * Control used to apply search on settings.query
     */
    searchControl = new FormControl('', { nonNullable: true });

    /**
     * True when awaiting response of settings.query
     */
    isLoading = false;

    /**
     * Cached results of settings.query; When user scrolls down (or presses load more) the cache is expanded with new items
     */
    cache: any[] = [];

    /**
     * Total number of items that could potentially be queried by settings.query. This is calculated via an aggergate
     * in the graphql query
     */
    total = 0;

    /**
     * The amount of items that are fetched with each query (graphql query limit)
     */
    pageSize = 10;

    /**
     * Current page to fetch in the query (used to calculate grapqhl query offset)
     */
    page = new BehaviorSubject<number>(0);

    /**
     * Fixed row height for each of the items in the virtual scroll
     */
    rowHeight = 48;

    /**
     * Max amount of rows to display before container starts overflowing and scroll bar becomes visible
     */
    maxRows = 5;

    /**
     * Automatically load more contenet when user scrolls to bottom of scroll container
     */
    loadMoreOnScroll = true;

    /**
     * Property to filter items with. Value of searchControl must be included in this column.
     */
    searchProperty = 'name';

    /**
     * Minimal amount of time (in ms) query should take to show data
     */
    private delayAmount = 300;

    hasData: boolean | undefined = undefined;

    constructor(private snackBar: MatSnackBar, private cd: ChangeDetectorRef) {
        // Subscribe to changes of searchControl to set page back to 0
        this.searchControl.valueChanges.pipe(
            tap(() => { this.isLoading = true; }),
            debounceTime(300),
            untilDestroyed(this),
        ).subscribe(() => {
            // Set delay amount to 0 because the searchControl already has an 300ms delay
            this.delayAmount = 0;
            this.page.next(0);
        });
    }

    ngOnChanges(): void {
        // Overwrite default values when provided in settings
        if (this.settings?.pageSize) {
            this.pageSize = this.settings.pageSize;
        }
        if (this.settings?.rowHeight) {
            this.rowHeight = this.settings.rowHeight;
        }
        if (this.settings?.maxRows) {
            this.maxRows = this.settings.maxRows;
        }
        if (this.settings?.loadMoreOnScroll !== undefined) {
            this.loadMoreOnScroll = this.settings.loadMoreOnScroll;
        }
        if (this.settings?.searchProperty) {
            this.searchProperty = this.settings.searchProperty;
        }

        // Create observable that emits when page changes (either more content needs to be loaded or search changed)
        this.page.asObservable()
            .pipe(
                // Set loading state to true
                tap(() => { this.isLoading = true; }),

                // Run query provided in settings
                switchMap(() => {
                    // If fixedValues are provided, return Observable that mimics query response
                    if (this.settings?.fixedValues?.length) {
                        return of({
                            data: {
                                // First key should be the values
                                0: this.settings.fixedValues,
                                // Second key should be the aggregate
                                1: { aggregate: { count: this.settings.fixedValues.length } },
                            },
                        });
                    }
                    return this.settings?.query!({
                        limit: this.pageSize,
                        offset: this.pageSize * this.page.value,
                        where: {
                            // Add fixedWhere
                            ...(this.settings.fixedWhere),
                            // Apply filter on searchProperty, or 'name' if searchProperty is not set
                            [this.searchProperty]: {
                                _ilike: `%${this.searchControl.value.trim().toLowerCase()}%`,
                            },
                        },
                        // Order by searchProperty, or 'name' if not set
                        order_by: [{ [this.searchProperty]: Order_By.Asc }],
                    }, { fetchPolicy: 'no-cache' }).pipe(
                        catchError(() => {
                            this.isLoading = false;
                            this.snackBar.open(
                                this.settings?.queryErrorMessage || 'Could not search items',
                                'Close',
                                { duration: 3000 },
                            );
                            // Return EMPTY to keep outer observable alive
                            return EMPTY;
                        }),
                    ).pipe(
                        // Make sure response takes at least 300ms to load
                        combineLatestWith(timer(this.delayAmount)),
                        tap(() => { this.delayAmount = 300; }),
                        map((x: any) => x[0]),
                    );
                }),
                // Set loading state to false
                tap(() => { this.isLoading = false; }),
                untilDestroyed(this),
            ).subscribe((data: any) => {
                // Get returned items
                const items: any[] = data.data[Object.keys(data.data)[0]];

                // Set total (aggregate)
                this.total = data.data[Object.keys(data.data)[1]].aggregate.count;

                // Insert items into cache
                items.forEach((item, index) => { this.cache[this.pageSize * this.page.value + index] = item; });
                this.cache = this.cache.slice(0, this.pageSize * this.page.value + items.length);

                // Force change detector to apply latest changes
                this.cd.detectChanges();

                // Set hasData status on first result
                if (this.hasData === undefined) {
                    this.hasData = !!items.length;
                }
            });
    }

    /**
     * Handles user opening mat select overlay
     */
    opened(): void {
        // Subscribe to changes in scrollport to detect user scrolling to bottom
        this.scrollViewPort?.elementScrolled()
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const element = this.scrollViewPort?.elementRef.nativeElement;
                if (element) {
                    // Check if user reached bottom
                    const reachedBottom = Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight) < 1;

                    // Load next content if the setting is enabled, user is at bottom, data is not currently being loaded
                    // and there is still more data to load
                    if (this.loadMoreOnScroll && reachedBottom && !this.isLoading && this.cache.length !== this.total) {
                        this.page.next(this.page.value + 1);
                    }
                }
            });
    }

    /**
     * Load more content when user pressed "Load more" button
     * This button is only available when settings.loadMoreOnScroll is false
     */
    loadMore(): void {
        if (!this.isLoading && this.cache.length !== this.total) {
            this.isLoading = true;
            this.page.next(this.page.value + 1);
        }
    }

    /**
     * Handles what to do when user closes selection overlay.
     */
    closed(): void {
        // Reset search control
        this.searchControl.reset();
    }

    /**
     * Function that retuns true when two rows (objects) should be considered equal
     * @param val1 First value
     * @param val2 Second value
     * @returns True if val1 is equal to val2
     */
    compareWith(val1: any, val2: any): boolean {
        if (this.settings?.compareFunction) {
            return this.settings.compareFunction(val1, val2);
        }
        return (val1?.id && val2?.id) ? val1.id === val2.id : val1 === val2;
    }

    /**
     * Attempts to insert a new item based on provided settings.new variables
     */
    async insertNewItem(): Promise<void> {
        // Make sure required properties are set and searchcontrol has value
        if (this.settings?.new && this.searchControl.value.length) {
            try {
                // Attempt to insert new item
                const result = await firstValueFrom<any>(
                    this.settings.new.insertMutation(
                        this.settings.new.constructObject(this.searchControl.value),
                    ),
                );
                // If new item was created successfully
                if (result.data[Object.keys(result.data)[0]]) {
                    // Set setting control to new value
                    this.settings.control.setValue(result.data[Object.keys(result.data)[0]]);
                    this.settings.control.value[this.settings.searchProperty || 'name'] = this.searchControl.value;
                    // Notify user of success
                    this.snackBar.open(this.settings.new.successMessage || 'New item created', 'Close', { duration: 3000 });
                } else {
                    // Notify user in case of error
                    this.snackBar.open(this.settings.new.errorMessage
                        || 'Could not create new item', 'Close', { duration: 3000 });
                }
            } catch {
                // Notify user in case of error. Either when mutation failes or result does not exist
                this.snackBar.open(this.settings.new.errorMessage || 'Could not create new item', 'Close', { duration: 3000 });
            }
        }
    }

    /**
     * Checks if item should be disabled in mat option
     * @param item Item to check disabled status for
     * @returns True if item should be disabled
     */
    isDisabled(item: any): boolean {
        return !!this.settings?.disableFunction && this.settings.disableFunction(item);
    }
}

/**
 * Settings for a smart-select component
 */
export interface SmartSelectSettings {
    /**
     * Label to show in form control
     */
    label?: string,
    /**
     * Control used to track current value of select control
     */
    control: FormControl;
    /**
     * Query used to filter items and get the aggregate.
     */
    query?: Function,
    /**
     * Property to search on. Defaults to `name` if not set
     */
    searchProperty?: string,
    /**
     * Compare function. Defaults to comparing the `id` property of both values (if they exist)
     * and comparing the entire value otherwise:
     *
     * `(val1?.id && val2?.id) ? val1.id === val2.id : val1 === val2`
     */
    compareFunction?: (val1: any, val2: any) => boolean,
    /**
     * Optional message to show in case items could not be fetched via apollo
     */
    queryErrorMessage?: string,
    /**
     * Message to show in the mat error below the form field
     */
    invalidControlErrorMessage?: string,
    /**
     * Allow selection of multiple values (using checkboxes)
     */
    multiple?: boolean
    /**
     * Amount of items to fetch for each 'page' (e.g. offset amount)
     */
    pageSize?: number
    /**
     * Height of a single mat-option row. Defaults to 48px
     */
    rowHeight?: number;
    /**
     * Max amount of rows to show, defaults to 5
     */
    maxRows?: number
    /**
     * Loads more items on scroll (defaults to true)
     */
    loadMoreOnScroll?: boolean
    /**
     * Placeholder to show in mat select
     */
    placeholder?: string;
    /**
     * Fixed object that will always be added to the where clause of the search query
     */
    fixedWhere?: any,
    /**
     * If set, allows to user to directly add a new item when the search does not yield results.
     */
    new?: {
        /**
         * Mutation used to create new item
         */
        insertMutation: Function,
        /**
         * Function that constructs an insert mutation object given the current searchValue
         */
        constructObject: (searchValue: string) => any,
        /**
         * Optional message to show in snackbar if adding item was succesful
         */
        successMessage?: string,
        /**
         * Optional message to show in snackbar if adding item was unsuccesful
         */
        errorMessage?: string
    }
    /**
     * Hide search bar when set to true
     */
    hideSearch?: boolean
    /**
     * By setting fixed values any async logic is skipped and user is instead searching through provided list of values
     */
    fixedValues?: any[];
    /**
     * If user can select from a list of objects default behaviour is that the value of the control will be set to the
     * entire object. With this property you can set it to a parameter of this object.
     */
    valueProperty?: string,
    /**
     * Optional function that can used to check if mat option should be disabled. Exposes the item to check for as input variable
     */
    disableFunction?: (item: any) => boolean
}
