<!-- Page title -->
<h1>Update history for {{(product)?.name}}</h1>

<!-- Display updates in a table format -->
<table mat-table [dataSource]="updates" matSort>

    <!-- Date column -->
    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef i18n mat-sort-header> Date </th>
        <td mat-cell *matCellDef="let update">
            {{update.time | date:'dd/LL/yyyy HH:mm'}}
        </td>
    </ng-container>

    <!-- User column -->
    <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef i18n mat-sort-header> User </th>
        <td mat-cell *matCellDef="let update">
            {{update.UpdatedBy.display_name}}
        </td>
    </ng-container>

    <!-- Status column -->
    <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef i18n> Status update </th>
        <td mat-cell *matCellDef="let update" class="actions">

            <!-- Show audit status change -->
            <div *ngIf="update.audited !== null && update.deprecated !== true" fxLayout="row"
                fxLayoutAlign="start center" fxLayoutGap="8px">
                <p i18n>Marked as</p>
                <mat-chip class="highlight" *ngIf="update.audited" i18n>Audited</mat-chip>
                <mat-chip *ngIf="!update.audited" i18n>Not audited</mat-chip>
            </div>

            <!-- Show exported status change -->
            <div *ngIf="update.exported" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                <mat-chip class="highlight" i18n>Exported</mat-chip>
            </div>

            <!-- Show audit status change -->
            <div *ngIf="update.deprecated === true" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                <p i18n>Marked as</p>
                <mat-chip class="highlight-deprecated" i18n>Deprecated</mat-chip>
            </div>

            <!-- If both audit and deprecate status did not change -->
            <p *ngIf="update.audited === null && update.deprecated === null && update.exported === null">-</p>
        </td>
    </ng-container>

    <!-- Changed values column -->
    <ng-container matColumnDef="changes">
        <th mat-header-cell *matHeaderCellDef i18n> Value(s) changed </th>
        <td mat-cell *matCellDef="let update">
            <p *ngFor="let change of update.UpdateChanges" i18n style="padding: 4px 0px">
                Changed <b>{{change.key}}</b> <span *ngIf="change.integer !== null"> instance
                    <b>{{change.integer}}</b></span> <span *ngIf="change.previous_value">from
                    <b>{{change.previous_value}}</b></span>
                to <b>{{change.value || 'unset'}}</b>
            </p>
            <p *ngIf="!update.UpdateChanges.length">-</p>
        </td>
    </ng-container>

    <tr class="mat-row" *matNoDataRow>
        <td colspan="4" fxLayout="row">
            <div fxLayout="row" fxLayoutAlign="start center" class="no-updates">
                <p i18n><i>Product does not have any updates yet</i></p>
            </div>
        </td>
    </tr>

    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
</table>

<!-- Mat paginator -->
<mat-paginator [pageSizeOptions]="[25, 50, 100]"></mat-paginator>