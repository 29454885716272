import {
    Component,
    EventEmitter,
    Input,
    Output,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Attachment, AttachmentComponent, AttachmentExtended } from '../attachment/attachment.component';

@Component({
    selector: 'app-attachment-upload',
    templateUrl: './attachment-upload.component.html',
    styleUrls: ['./attachment-upload.component.scss'],
})
export class AttachmentUploadComponent {
    /**
     * List of all AttachmentComponents
     */
    @ViewChildren(AttachmentComponent) attachments = QueryList<AttachmentComponent>;

    /**
     * Attachments to display. Can be provided via Input and is extended when user uploads more files
     */
    @Input() files: Attachment[] = [];

    /**
     * Set to false to hide the delete icon after a file is uplaoded
     */
    @Input() allowDelete = true;

    /**
     * Outputs the attachment that was deleted
     */
    @Output() onFileDeletion = new EventEmitter<AttachmentExtended>();

    /**
     * Outputs the attachment that has completed uploading
     */
    @Output() onUploadComplete = new EventEmitter<AttachmentExtended>();

    /**
     * Is user hovering with (a) file(s) above the drag/drop container
     */
    isHovered = false;

    /**
     * Container that was entered when drag/dropping. Used to avoid when drag/drop container has child elements
     * that incorrectly set the isHovered states to false.
     */
    enterTarget: EventTarget | null = null;

    /**
     * Status of child attachments
     *   - valid: All child attachments are done uploading and none have an error
     *   - uploading: At least 1 child attachment is still uploading
     *   - error: At least 1 child attachment has an error (e.g. FileSizeTooLarge)
     */
    status: 'valid' | 'uploading' | 'error' = 'valid';

    /**
     * Show warning about status
     */
    showStatus = false;

    /**
     * Allowed file types (file name must end with .[allowedFileType])
     */
    allowedFileTypes = ['pdf', 'txt', 'docx', 'rtf', 'xlsx', 'pps'];

    constructor(private snackBar: MatSnackBar) {
    }

    /**
     * Handle drag over events
     * @param event Event
     */
    onDragOver(event: Event): void {
        event.preventDefault();
    }

    /**
     * Handle drag enter events
     * @param event Event
     */
    onDragEnter(event: Event): void {
        this.enterTarget = event.target;
        event.preventDefault();
        event.stopPropagation();
        this.isHovered = true;
    }

    /**
     * Handle drag leave events
     * @param event Event
     */
    onDragLeave(event: Event): void {
        event.preventDefault();
        event.stopPropagation();
        // Only set isHovered to false if user leaves drag/drop box they originally entered
        if (this.enterTarget === event.target) {
            this.isHovered = false;
        }
    }

    /**
     * Handle drop events
     * @param event Event
     */
    onDrop(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
        if (event.dataTransfer?.files.length) {
            // Only upload files with allowed file type
            const allowedFiles = Array.from(event.dataTransfer.files)
                .filter((file) => this.allowedFileTypes.includes(file.name.split('.').at(-1)!));

            // Notify amount of skipped files
            if (Array.from(event.dataTransfer.files).length !== allowedFiles.length) {
                const skippedAmount = Array.from(event.dataTransfer.files).length - allowedFiles.length;
                this.snackBar.open(`Skipped ${skippedAmount} file(s) with unsupported file type`, 'Close');
            }
            // Add allowed files
            this.files = this.files.concat(allowedFiles);
        }
        this.isHovered = false;
    }

    /**
     * Handle file selection via Select Files browser dialog
     * @param evt Event
     */
    filesSelected(evt: any): void {
        if (evt?.target?.files?.length) {
            // Only upload files with allowed file type
            const allowedFiles = Array.from(evt.target.files as FileList)
                .filter((file) => this.allowedFileTypes.includes(file.name.split('.').at(-1)!) && file.size < 10000000);

            // Notify amount of skipped files
            if (Array.from(evt.target.files).length !== allowedFiles.length) {
                const skippedAmount = Array.from(evt.target.files).length - allowedFiles.length;
                this.snackBar.open(`Skipped ${skippedAmount} file(s) with unsupported file type or exceeded file size of 10MB`, 'Close');
            }

            this.files = this.files.concat(allowedFiles);
            evt.target.value = '';
        }
    }

    /**
     * Removes an attachment from the files array
     * @param attachment Attachment
     * @param index Index
     */
    deleteFile(attachment: AttachmentExtended, index: number): void {
        this.files.splice(index, 1);
        // Wait for deletion animation time
        setTimeout(() => { this.onFileDeletion.next(attachment); }, 200);
    }

    /**
     * Returns true when all files are uploaded and none of the files have an error
     * @returns Valid status
     */
    isValid(): boolean {
        if (this.attachments.length === 0) {
            return true;
        }
        return ((this.attachments as any).toArray() as AttachmentComponent[])
            .every((attachment) => attachment.attachment?.completed.value && !attachment.attachment.error);
    }

    /**
     * Mark attachments as touched, calculating the status and setting showStatus to true
     */
    markAsTouched(): void {
        this.setStatus();
    }

    /**
     * Calculate the current status of the attachments and show a warning/error to the user
     * if necessary
     */
    private setStatus(): void {
        let isUploading = false;
        let hasError = false;
        for (const attachment of ((this.attachments as any).toArray() as AttachmentComponent[])) {
            if (!attachment.attachment?.error && !attachment.attachment?.completed.value) {
                isUploading = true;
            } if (attachment.attachment?.error) {
                hasError = true;
            }
        }
        if (hasError) {
            this.status = 'error';
        } else {
            this.status = isUploading ? 'uploading' : 'valid';
        }
        this.showStatus = true;
    }

    /**
     * Update status and emit upload completion
     * @param attachment Attachment
     */
    uploadComplete(attachment: AttachmentExtended): void {
        this.onUploadComplete.emit(attachment);
        // Only update status if it was already shown (via markAsTouched function)
        if (this.showStatus) {
            setTimeout(() => { this.setStatus(); });
        }
    }

    /**
     * Get all fileIds of attachments currenly uploaded
     * @returns Array of fileIds (string)
     */
    getFileIds(): string[] {
        return ((this.attachments as any).toArray() as AttachmentComponent[])
            .filter((a) => a.attachment?.fileId)
            .map((a) => a.attachment?.fileId!);
    }
}
