import { Component, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom, map } from 'rxjs';
import { ApolloService, getUsers_users } from 'src/gql-generated/generated';
import { FormControl } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { DynamicDialogComponent } from '../dynamic-dialog/dynamic-dialog.component';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
})
export class UsersComponent {
    /**
     * Dynamic dialog reference
     */
    @ViewChild(DynamicDialogComponent) dynamicDialog?: DynamicDialogComponent;

    /**
     * All users
     */
    users: getUsers_users[] = [];

    /**
     * Observable that emits all groups
     */
    groups$ = this.apollo.getGroups().pipe(map((x) => x.data.auth_groups));

    /**
     * Column shown in the users table.
     */
    tableColumns = ['name', 'groups', 'permissions'];

    /**
     * Control used to select groups a user belongs to
     */
    userGroupControl = new FormControl<string[]>([]);

    /**
     * Observable that emits all users
     */
    private users$ = this.apollo.getUsers().pipe(map((x) => x.data.users));

    constructor(private apollo: ApolloService, private snackBar: MatSnackBar, public user: UserService) {
        this.loadData();
    }

    /**
     * Query users data and store in this.users
     */
    loadData(): void {
        firstValueFrom(this.users$)
            .then((users) => { this.users = users; })
            .catch(() => this.snackBar.open('Could not load users', 'Close'));
    }

    /**
     * Change the groups a user belongs to
     * @param user User to change groups for
     */
    editUserGroups(user: getUsers_users): void {
        // Set value of userGroupControl to current group ids of the user the dialog is opened for
        this.userGroupControl.setValue(user.UserGroups.map((userGroup) => userGroup.Group.id));

        if (this.dynamicDialog) {
            // Open dialog and await result
            this.dynamicDialog.open().then(async (accepted) => {
                if (accepted) {
                    // Try and save new user groups. The apollo mutation deletes all previous userGroups for the user_id and
                    // then inserts the new ones. Because both operations are included in the same mutation the data is rolled
                    // back when one of the two fails.
                    try {
                        const res = await firstValueFrom(this.apollo.updateUserGroups({
                            user_id: user.id,
                            newUserGroups: this.userGroupControl.value?.map((groupId) => ({ group_id: groupId, user_id: user.id })),
                        }));
                        // Notify the user when the update was succesful and refresh the users table
                        if (res.data?.delete_auth_user_groups) {
                            this.loadData();
                            this.snackBar.open('User groups updated', 'Close');
                        } else {
                            // Notify the user in case something went wrong
                            this.snackBar.open('Could not save user groups', 'Close');
                        }
                    } catch {
                        this.snackBar.open('Could not save user groups', 'Close');
                    }
                }
            });
        }
    }
}
