<div fxLayout="column" fxLayoutGap="8px" *ngIf="productFiles !== undefined">

    <h1 i18n>Product attachments</h1>

    <!-- Upload box -->
    <h3 i18n>Upload additional files</h3>
    <app-attachment-upload [allowDelete]="false" (onUploadComplete)="refreshSmartSelects()"></app-attachment-upload>

    <!-- Technical parts -->
    <div>
        <h3 i18n>Assign files</h3>

        <div class="techical-parts-container">

            <!-- For each technical part either show a selection if no file has been selected, or attachment preview if it has  -->
            <div *ngFor="let technicalPart of technicalPartOrder"
                [class.showing-preview]="attachmentsFormGroup.get(technicalPart)!.value">

                <!-- File selection -->
                <app-smart-select *ngIf="!attachmentsFormGroup.get(technicalPart)!.value"
                    [settings]="smartSelects[technicalPart]"></app-smart-select>

                <!-- Selected file -->
                <div *ngIf="attachmentsFormGroup.get(technicalPart)!.value" fxLayout="row" fxLayoutAlign="start center"
                    fxLayoutGap="8px" class="attachment-preview">

                    <!-- Technical part name -->
                    <p fxFlex>{{$any(technicalPartDescriptions)[technicalPart]}}</p>

                    <!-- Attachment (disable delete, as file might be used elsewhere) -->
                    <app-attachment [file]="attachmentsFormGroup.get(technicalPart)!.value"
                        [allowDelete]="false"></app-attachment>

                    <!-- Clear formcontrol allowing reselect of file -->
                    <button mat-stroked-button class="small-button"
                        (click)="attachmentsFormGroup.get(technicalPart)!.reset()">
                        <mat-icon>clear</mat-icon>
                        Reselect
                    </button>
                </div>

            </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="end center">
            <button mat-stroked-button color="primary" i18n (click)="downloadZip()" style="margin-right: 8px;">Download
                zip</button>
            <button mat-flat-button color="primary" [disabled]="attachmentsFormGroup.invalid" i18n
                (click)="updateFiles()">Save</button>
        </div>
    </div>

</div>