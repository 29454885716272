<h1>Product overview</h1>
<div>
    <mat-checkbox (change)="selectAll($event)"></mat-checkbox>

    <!-- Search -->
    <mat-form-field fxFlex class="search-items">
        <mat-label i18n>Zoek product</mat-label>
        <input matInput [formControl]="searchControl">
    </mat-form-field>


    <mat-radio-group aria-label="Select an option" [formControl]="auditedControl" class="property-edit">
        <mat-radio-button [value]="0">All</mat-radio-button>
        <mat-radio-button [value]="1">Audited</mat-radio-button>
        <mat-radio-button [value]="2">Not audited</mat-radio-button>
    </mat-radio-group>
</div>

<div *ngFor="let product of products" fxLayout="row" fxLayoutAlign="start center" class="product-line">
    <mat-checkbox [disabled]="!product.audited" (click)="$event.stopPropagation()"
        (change)="selection.toggle(product.id)" [checked]="selection.isSelected(product.id)" fxFlex="50px"
        color="primary"></mat-checkbox>
    <app-product [product]="product" [routerLink]="['/products', product.id]" class="pointer"></app-product>
</div>

<p i18n *ngIf="!isLoading && products?.length === 0" class="centered">No products found</p>

<div fxLayout="row" fxLayoutAlign="space-between center">
    <button mat-stroked-button color="primary" [disabled]="!selection.selected.length"
        (click)="selectReferencesDialog.open()" *appAccess="{ features: ['export_products']}">Export</button>

    <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[10, 25, 50]"></mat-paginator>


</div>

<!-- Delete attachment dialog content -->
<app-dynamic-dialog #selectReferencesDialog>
    <h1 i18n title>Delete attachment</h1>

    <div fxLayout="column" fxLayoutGap="16px">
        <p i18n>Select a trademark and contact reference. These references must be available in EPREL before you
            can
            make an export.
        </p>

        <div fxLayout="column" fxFlex [formGroup]="referencesFormGroup">

            <mat-form-field>
                <mat-label i18n>Contact reference</mat-label>
                <input matInput formControlName="contact">
                <mat-error i18n>Field is required</mat-error>
            </mat-form-field>
        </div>
    </div>

    <!-- Custom confirm button -->
    <button confirm-button mat-flat-button color="primary" i18n [disabled]="referencesFormGroup.invalid"
        (click)="export(); confirmExportSuccess.open()">
        Create export
    </button>
</app-dynamic-dialog>

<app-dynamic-dialog #confirmExportSuccess [disableClose]="true" (onClose)="uploadSuccess($event)">
    <p>Created and downloaded a XML file. Please upload the XML file in the <b>EPREL environment</b>.
        When you are done, confirm if the xml could be uploaded succesfully.
    </p>

    <!-- Custom confirm button -->
    <button cancel-button mat-flat-button color="warn" i18n>
        Export failed
    </button>

    <!-- Custom confirm button -->
    <button confirm-button mat-flat-button color="primary" i18n>
        Export succeeded
    </button>
</app-dynamic-dialog>