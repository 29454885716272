import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { NavigationService } from 'src/app/services/navigation.service';
import { UserService } from 'src/app/services/user.service';
import { ApolloService, getGroup_auth_groups_by_pk } from 'src/gql-generated/generated';

@Component({
    selector: 'app-role-edit',
    templateUrl: './role-edit.component.html',
    styleUrls: ['./role-edit.component.scss'],
})
export class RoleEditComponent {
    /**
     * Reference to group. Only has value when this component is used for editing
     */
    group?: getGroup_auth_groups_by_pk;

    /**
     * Group id fetched from url.
     * If this component is used for creating a new group this variable will be undefined.
     */
    groupId = this.route.snapshot.paramMap.get('groupId');

    /**
     * FormGroup containing fields to be filled in for a valid group
     */
    groupFormGroup = new FormGroup({
        name: new FormControl<string>('', Validators.required),
        description: new FormControl<string | undefined>(undefined),
    });

    constructor(
        private route: ActivatedRoute,
        private apollo: ApolloService,
        private snackBar: MatSnackBar,
        private user: UserService,
        public navigation: NavigationService,
    ) {
        // Load group
        if (this.groupId) {
            firstValueFrom(this.apollo.getGroup({ id: this.groupId }))
                .then((response) => {
                    if (response.data.auth_groups_by_pk) {
                        this.group = response.data.auth_groups_by_pk;
                        this.groupFormGroup.patchValue({
                            name: this.group?.name,
                            description: this.group?.description,
                        });
                    }
                })
                .catch(() => this.snackBar.open('Could not load role data', 'Close'));
        }
    }

    /**
     * Attempts to save the group
     */
    async saveGroup(): Promise<void> {
        this.groupFormGroup.markAllAsTouched();
        if (this.groupFormGroup.valid) {
            try {
                const result = await firstValueFrom(this.apollo.insertGroup({
                    group: {
                        created_by: this.user.profile.id,
                        name: this.groupFormGroup.value.name!,
                        description: this.groupFormGroup.value.description || undefined,
                    },
                }));
                if (result.data?.insert_auth_groups_one) {
                    this.snackBar.open('Role created', 'Close');
                    this.navigation.back('permissions');
                }
            } catch {
                // Notify user in case of error
                this.snackBar.open('Could not save role', 'Close');
            }
        }
    }

    /**
     * Attempts to update the group
     */
    async updateGroup(): Promise<void> {
        this.groupFormGroup.markAllAsTouched();
        if (this.groupFormGroup.valid && this.groupId) {
            try {
                const result = await firstValueFrom(this.apollo.updateGroup({
                    group_id: this.groupId,
                    _set: {
                        updated_by: this.user.profile.id,
                        name: this.groupFormGroup.value.name!,
                        description: this.groupFormGroup.value.description || null,
                    },
                }));
                if (result.data?.update_auth_groups_by_pk) {
                    this.snackBar.open('Role updated', 'Close');
                    this.navigation.back('permissions');
                }
            } catch {
                // Notify user in case of error
                this.snackBar.open('Could not update role', 'Close');
            }
        }
    }

    /**
     * Attempts to delete the group
     */
    async deleteGroup(): Promise<void> {
        if (this.groupId) {
            try {
                const result = await firstValueFrom(this.apollo.deleteGroup({
                    id: this.groupId,
                }));
                if (result.data?.delete_auth_groups_by_pk) {
                    this.snackBar.open('Role deleted', 'Close');
                    this.navigation.back('permissions');
                }
            } catch {
                // Notify user in case of error
                this.snackBar.open('Could not delete role', 'Close');
            }
        }
    }
}
