<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" *ngIf="product">
    <mat-tab label="Product form">

        <h1><span class="heater-type">{{product.ProductType.name}}</span> {{product.name}}
            <button mat-stroked-button routerLink="/products">Back</button>
        </h1>

        <div *ngIf="exported" [formGroup]="exportedFormGroup" fxLayout="column" fxLayoutAlign="start stretch"
            class="exported-block">
            <div fxLayout="row" fxLayoutAlign="start center" class="info-block">
                <mat-icon>info</mat-icon>
                <p> This product has already been exported to EPREL. If you want to update it, you
                    need to supply a reason for the changes. </p>
            </div>

            <!-- Text input fields -->
            <mat-form-field>
                <mat-label i18n>Enter the EPREL registration number</mat-label>
                <input matInput formControlName="EPREL_MODEL_REGISTRATION_NUMBER">
            </mat-form-field>

            <!-- List input fields -->
            <mat-form-field>
                <mat-label>Select the type of change to make</mat-label>
                <mat-select formControlName="REASON_FOR_CHANGE">
                    <mat-option>--</mat-option>
                    <mat-option *ngFor="let reason of exportReasons" [value]="reason">{{reason}}</mat-option>
                </mat-select>
            </mat-form-field>

            <!-- Text input fields -->
            <mat-form-field>
                <mat-label i18n>Give a descriptive explanation of the reason for the change</mat-label>
                <input matInput formControlName="REASON_COMMENT">
            </mat-form-field>

        </div>


        <div *ngFor="let item of items" fxLayout="column">
            <app-product-detail-input *ngIf="item.item" (delete)="removeItem(item.item)" [item]="item.item">
            </app-product-detail-input>
            <button mat-stroked-button *ngIf="item.addButton" (click)="insertItem(item.addButton)"
                class="add-button">Add</button>
        </div>

        <div class="bottom-block">

            <p>Mark as audited</p>

            <mat-radio-group aria-label="Select an option" [formControl]="auditedControl" class="property-edit">
                <mat-radio-button [value]="false">False</mat-radio-button>
                <mat-radio-button [value]="true">True</mat-radio-button>
            </mat-radio-group>

            <div fxLayout="row" fxLayoutAlign="end center">
                <button mat-flat-button color="primary" (click)="updateProduct()">Confirm</button>
            </div>
        </div>
    </mat-tab>

    <mat-tab label="Attachments">
        <app-product-attachments [productId]="product.id" (refresh)="updateAttachments()"></app-product-attachments>
    </mat-tab>
    <mat-tab label="History">
        <app-product-history [product]="product" #history></app-product-history>
    </mat-tab>
</mat-tab-group>