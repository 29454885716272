import { Injectable } from '@angular/core';
import {
    ActivatedRoute, NavigationEnd, Router, UrlSegment,
} from '@angular/router';
import {
    filter, startWith, take, map,
} from 'rxjs';
import { ApolloService } from 'src/gql-generated/generated';

@Injectable({
    providedIn: 'root',
})
export class BreadcrumbService {
    /**
     * Array containing breadcrumbs making up current route
     */
    breadcrumbs: Breadcrumb[] = [];

    constructor(private apollo: ApolloService, private route: ActivatedRoute, private router: Router) {
        // Subscribe to NavigationEnd events
        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            startWith(null),
        ).subscribe(async () => {
            // Get url segments
            const urlSegments: UrlSegment[] = (this.route as any)._futureSnapshot._routerState._root.children[0]?.value.url;
            this.breadcrumbs = this.router.routerState.snapshot.root.firstChild?.data?.['breadcrumbs'] || [];

            // Set location for breadcrumbs that do not yet have a location property
            this.breadcrumbs.forEach((breadcrumb, index) => {
                breadcrumb.location = urlSegments.slice(0, index + 1).map((segment) => segment.path).join('/');
            });

            // Remove hidden breadcrumbs
            this.breadcrumbs = this.breadcrumbs.filter((breadcrumb) => !breadcrumb.hide);

            // If text is not provided but query is, set text to be result of query
            this.breadcrumbs.filter((breadcrumb) => breadcrumb.query).forEach((breadcrumb) => {
                const id = breadcrumb.location?.split('/').at(-1);
                if (id) {
                    (this.apollo as any)[`${breadcrumb.query}`]({ id }).pipe(
                        take(1),
                        map((respone: any) => respone.data[Object.keys(respone.data)[0]]),
                    ).subscribe((item: any) => {
                        if (item) {
                            breadcrumb.text = breadcrumb.displayFunction ? breadcrumb.displayFunction(item) : (item.name || '');
                        } else {
                            breadcrumb.text = '-';
                        }
                    });
                }
            });
        });
    }
}

/**
 * Breadcrumn data that is set in the router config
 */
export type Breadcrumb = {
    /**
     * Static text to display in breadcrumb
     */
    text?: string,
    /**
     * Name of query (function name of apolloService) used to get breadcrumb data
     */
    query?: keyof ApolloService,
    /**
     * Function that maps the result of the `query` to a string
     */
    displayFunction?: (item: any) => string,
    /**
     * Hides the breadcrumb if true. Each url segment must have a breadcrumb, hence the necessity of this input.
     */
    hide?: boolean
    /**
     * Optional icon to display as a mat-icon.
     */
    icon?: string
    /**
     * Location routerLink should route to. If left emtpy the breadcrumb service automatically determines the location by
     * parsing the url segments.
     */
    location?: string
};
