import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConnectionService } from './connection.service';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root',
})
export class FileService {
    constructor(
        private http: HttpClient,
        private connection: ConnectionService,
        private user: UserService,
    ) { }

    /**
     * Attempt to get the file contents based on path of attachment and user credentials
     * @param path File path
     * @returns Blob containing file contents or null if unsuccessful
     */
    async getFileAsBlob(path: string): Promise<Blob | null> {
        try {
            return await firstValueFrom(
                this.http.get(`${environment.backend}/storage/o${path}`, {
                    responseType: 'blob' as 'json',
                    // Set authorization header for permission check
                    headers: {
                        ...{
                            'Cache-Control': 'no-cache, no-store, must-revalidate, post- check=0, pre-check=0',
                            Pragma: 'no-cache',
                            Expires: '0',
                            Authorization: `Bearer ${this.connection.client.accessToken}`,
                        },
                        ...(this.user.role && { 'x-hasura-role': this.user.role }),
                    },
                }),
            ) as Blob;
        } catch {
            return null;
        }
    }
}
