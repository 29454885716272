<!-- Creating new group -->
<ng-container *ngIf="!groupId">
    <!-- title -->
    <h1 class="page-title" i18n>
        <mat-icon>add_circle_outline</mat-icon>
        Create new role
    </h1>
    <!-- Description -->
    <p class="description" i18n>
        Fill in the fields below to create a new role
    </p>
</ng-container>

<!-- Editing existing roup -->
<ng-container *ngIf="groupId">
    <!-- title -->
    <h1 class="page-title" i18n *ngIf="groupId">
        <mat-icon>edit</mat-icon>
        Edit role {{group?.name}}
    </h1>
    <!-- Description -->
    <p class="description" i18n *ngIf="groupId">
        Change the value in the fields below to edit the role.
    </p>
</ng-container>

<div fxLayout="column">

    <!-- Group input -->
    <ng-container [formGroup]="groupFormGroup">
        <!-- Name -->
        <mat-form-field>
            <mat-label i18n>Name</mat-label>
            <input matInput formControlName="name">
            <mat-error i18n>Field is required</mat-error>
        </mat-form-field>

        <!-- Description -->
        <mat-form-field>
            <mat-label i18n>Description</mat-label>
            <textarea matInput formControlName="description" cdkTextareaAutosize cdkAutosizeMinRows="5"
                cdkAutosizeMaxRows="10"></textarea>
        </mat-form-field>
    </ng-container>

    <!-- Save/Cancel buttons -->
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
        <button mat-button i18n (click)="navigation.back('permissions')" class="cancel">Cancel</button>
        <button mat-flat-button color="warn" i18n (click)="confirmDelete.open()" *ngIf="groupId">Delete</button>
        <button mat-flat-button color="primary" i18n (click)="groupId ? updateGroup() : saveGroup()">Save</button>
    </div>

</div>


<!-- Delete confirmation dialog -->
<app-dynamic-dialog #confirmDelete>
    <!-- Title -->
    <h1 i18n title>Delete role</h1>

    <p i18n class="description"> Are you sure you want to delete the role {{group?.name}}?</p>

    <!-- Show impact if there is any -->
    <p i18n *ngIf="group?.UserGroups_aggregate?.aggregate?.count" class="description">
        This role currently has {{group?.UserGroups_aggregate?.aggregate?.count}} users that might lose access to part
        of the system
    </p>

    <!-- Confirmation button -->
    <button confirm-button mat-flat-button color="warn" i18n (click)="deleteGroup()">Delete role</button>

</app-dynamic-dialog>