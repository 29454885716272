<!-- Display everything in a form field -->
<mat-form-field *ngIf="settings">

    <!-- Label -->
    <mat-label i18n>{{settings.label || 'Make selection'}}</mat-label>

    <!-- Use default angular mat select as base -->
    <mat-select [placeholder]="settings.placeholder || 'Make selection'" [formControl]="settings.control"
        (openedChange)="searchInput.focus(); $event ? opened() : closed()" [compareWith]="compareWith"
        [multiple]="settings.multiple" panelClass="select-panel-unset-max-height">

        <!-- Custom trigger for multiple select. e.g. adds (+5 others) -->
        <mat-select-trigger *ngIf="settings.multiple">
            {{settings.control.value?.[0]?.[searchProperty] || ''}}
            <span *ngIf="(settings.control.value?.length || 0) > 1" class="trigger-additional">
                (+{{(settings.control.value?.length || 0) - 1}} {{settings.control.value?.length === 2 ?
                'other' : 'others'}})
            </span>
        </mat-select-trigger>

        <!-- Search -->
        <div class="search" fxLayout="row" fxLayoutAlign="start center" [ngClass]="{'hidden': settings.hideSearch}">
            <input placeholder="Type to search..." class="search-input" [formControl]="searchControl" #searchInput
                (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
            <button mat-icon-button *ngIf="!isLoading && searchControl.value"
                (click)="searchControl.reset(''); searchInput.focus();" class="fade">
                <mat-icon>clear</mat-icon>
            </button>
            <mat-spinner [diameter]="24" *ngIf="isLoading" class="fade"></mat-spinner>
        </div>

        <!-- Filtered items from query. Displayed in a virtual scroll -->
        <cdk-virtual-scroll-viewport [itemSize]="rowHeight" [minBufferPx]="rowHeight * maxRows"
            [maxBufferPx]="(rowHeight + 2) * maxRows"
            [style.height]="(cache.length > maxRows ? (maxRows * rowHeight) : (cache.length * rowHeight)) + 'px'">
            <!-- Display mat option for each item -->
            <mat-option *cdkVirtualFor="let item of cache"
                [disabled]="isDisabled(settings.valueProperty ? item[settings.valueProperty] : item)"
                [value]="settings.valueProperty ? item[settings.valueProperty] : item">
                <ng-container *ngTemplateOutlet="matOptionContent, context: {$implicit: item}"></ng-container>
                <span *ngIf="!matOptionContent">{{item[searchProperty]}}</span>
            </mat-option>
            <!-- Display load more row if necessary -->
            <div class="count" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="16px"
                *ngIf="cache.length !== total">
                <p i18n>Loaded {{cache.length}} / {{total}} items</p>
                <p class="link" i18n (click)="loadMore()" *ngIf="!loadMoreOnScroll">Load more</p>
            </div>
        </cdk-virtual-scroll-viewport>

        <!-- No values matching search  -->
        <div class="no-values-warning" *ngIf="!cache.length && !settings.new">
            <p i18n> No items found </p>
        </div>

        <!-- Add new value  -->
        <mat-option class="new-value" *ngIf="!cache.length && settings.new" fxLayout="row" fxLayoutAlign="start center"
            fxLayoutGap="16px" (click)="insertNewItem()">
            <mat-icon>add</mat-icon>
            <p i18n *ngIf="searchControl.value.length">Add new value: <b>{{searchControl.value}}</b></p>
            <p i18n *ngIf="!searchControl.value.length"><i>Start typing to add a new value</i></p>
        </mat-option>

        <!-- Hidden mat-option for selected item in single select mode -->
        <mat-option *ngIf="!settings.multiple && settings.control.value" [value]="settings.control.value"
            class="hidden">
            <ng-container
                *ngTemplateOutlet="matOptionContent, context: {$implicit: settings.control.value}"></ng-container>
            <span *ngIf="!matOptionContent">{{settings.control.value[searchProperty]}}</span>
        </mat-option>

        <!-- Hidden mat-option for selecteded items in multiple mode -->
        <ng-container *ngIf="settings.multiple && settings.control.value?.length">
            <mat-option *ngFor="let item of settings.control.value"
                [value]="settings.valueProperty ? item[settings.valueProperty] : item" class="hidden">
                <ng-container *ngTemplateOutlet="matOptionContent, context: {$implicit: item}"></ng-container>
                <span *ngIf="!matOptionContent">{{item[searchProperty]}}</span>
            </mat-option>
        </ng-container>
    </mat-select>

    <!-- Clear button -->
    <mat-icon matSuffix class="clear-form-field" *ngIf="settings.control.value"
        (click)="$event.stopPropagation(); settings.control.reset()">clear</mat-icon>

    <!-- Mat error shown when settings.control is in invalid state -->
    <mat-error>{{settings.invalidControlErrorMessage || 'Invalid value'}}</mat-error>
</mat-form-field>