/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntilDestroy } from '@ngneat/until-destroy';
import { XMLBuilder } from 'fast-xml-parser';
import * as JSZip from 'jszip';
import {
    firstValueFrom, map,
} from 'rxjs';
import { ApolloService, getProductsToUpload_products } from 'src/gql-generated/generated';
import { FileService } from './file.service';

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class EprelUploadBuilderService {
    /**
     * Name that is used to attach text to a field with an attribute
     */
    private builderTextName = '@';

    /**
     * Default XML builder configuration
     */
    private builder: XMLBuilder = new XMLBuilder({
        suppressEmptyNode: true,
        attributeNamePrefix: '@@',
        textNodeName: this.builderTextName,
        ignoreAttributes: false,
        processEntities: false,
    });

    constructor(private apollo: ApolloService, private snackBar: MatSnackBar, private fileService: FileService) { }

    /**
     * Construct the XML file for the selected products
     * @param productIds ids of products to export
     * @param contact EPREL contact reference
     */
    async constructXML(productIds: string[], contact: string): Promise<ProductWithProperties[]> {
        // Fetch relevant data for provided productIds
        const products = await firstValueFrom(this.apollo.getProductsToUpload({ ids: productIds }).pipe(map((result) => result.data.products)));
        // Remap products into a more workable structure
        const productsWithProperties: ProductWithProperties[] = products.map((product) => ({
            ...product,
            Properties: product.Properties.map((property: any) => ({
                [property.key]: property.value,
            })),
            // Properties grouped in load profiles
            LoadProfiles: this.groupLoadProfiles(product),
            // Properties grouped in languages
            LanguageKeys: this.groupLanguages(products[0]),
        }));

        // A productOperation is one product that need to be inserted/updated in EPREL
        const productOperations: NestedObject[] = [];

        // Initialize final zip object
        const zip = new JSZip();

        // Download and add product attachments to zip
        await this.addAttachmentsToZip(zip, productsWithProperties);

        // Loop over the products and generate a JSON object for every single one
        for (const product of productsWithProperties) {
            let operation: NestedObject | undefined;
            switch (product.ProductType.name) {
                case 'Water heater':
                    operation = this.generateWaterHeaterXML(product, contact);
                    break;
                case 'Space heater':
                    operation = this.generateSpaceHeaterXML(product, contact);
                    break;
                case 'Combination heater':
                    operation = this.generateCombinationHeaterXML(product, contact);
                    break;
                default:
            }

            if (operation) {
                // Add the product to the XML
                productOperations.push(operation);
            }
        }

        // Create a zipfile consisting of a xml file called 'registration-data.xml' and a folder 'attachments'
        zip.file('registration-data.xml', this.builder.build({
            '?xml': {
                [this.formatAttributeKey('version')]: '1.0',
                [this.formatAttributeKey('encoding')]: 'UTF-8',
                [this.formatAttributeKey('standalone')]: 'yes',
            },
            'ns3:ProductModelRegistrationRequest': {
                [this.formatAttributeKey('xmlns:ns2')]: 'http://eprel.ener.ec.europa.eu/productModel/productCore/v2',
                [this.formatAttributeKey('xmlns:ns3')]: 'http://eprel.ener.ec.europa.eu/services/productModelService/modelRegistrationService/v2',
                // ID of the transaction to be provided by the supplier. It is a free text, but XML
                // reserved characters must be avoided (i.e. &, <, >, etc.)
                // TODO (I've found no further explanation, what should this be? Do we track all zipfiles?
                [this.formatAttributeKey('REQUEST_ID')]: '123',
                productOperation: productOperations,
            },
        }));

        zip.generateAsync({ type: 'blob' }).then((blob) => {
            // Zip name
            const zipName = `eprel-${new Date().toISOString().split('T')[0]}.zip`;

            // Temporarily add an <a> element and click it to download the zip
            if (blob) {
                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(blob);
                downloadLink.setAttribute('download', zipName);
                document.body.appendChild(downloadLink);
                downloadLink.click();
                downloadLink.remove();
            } else {
                this.snackBar.open(`Could not download ${zipName}`, 'Close');
            }
        });

        return productsWithProperties;
    }

    generateWaterHeaterXML(product: ProductWithProperties, contact: string): NestedObject {
        // Convert property array to property object
        const properties = this.getProductProperties(product);
        // Get base operation properties
        const operation = this.getBaseOperation(product, contact);

        // Add product details to base operation
        (operation['MODEL_VERSION'] as any).PRODUCT_GROUP_DETAIL = {
            [this.formatAttributeKey('xmlns:xsi')]: 'http://www.w3.org/2001/XMLSchema-instance',
            [this.formatAttributeKey('xmlns:ns5')]: 'http://eprel.ener.ec.europa.eu/productModel/productGroups/waterHeater/WaterHeater/v4',
            // this is either ns5:ConventionalWaterHeater or ns5:HeatPumpWaterHeater. (Intergas does not make SolarWaterHeaters, NOTE: only conventionalWaterHeaters will be used in v1)
            [this.formatAttributeKey('xsi:type')]: properties['Type'] === 'Conventional water heaters' ? 'ns5:ConventionalWaterHeater' : 'ns5:HeatPumpWaterHeater',
            ENERGY_CLASS: properties['ENERGY_CLASS'],
            THERMOSTAT_DEFAULT_SETTINGS: product.LanguageKeys.THERMOSTAT_DEFAULT_SETTINGS,
            NOISE: properties['NOISE'],
            ONLY_FOR_OFF_PEAK_PERIODS: properties['ONLY_FOR_OFF_PEAK_PERIODS'],
            SPECIFIC_PRECAUTIONS: product.LanguageKeys.SPECIFIC_PRECAUTIONS,
            SMART: properties['SMART'] === 1 ? properties['SMART'] : undefined,
            SMART_TEXTUAL_INDICATION: properties['SMART'] === 1 ? product.LanguageKeys.SMART_TEXTUAL_INDICATION : undefined,
            // Required for Heatpumps only
            OUTDOOR_NOISE: properties['OUTDOOR_NOISE'],
            LOAD_PROFILE_EFFICIENCY: product.LoadProfiles,
            SOLAR_COLLECTOR_SIZE: properties['SOLAR_COLLECTOR_SIZE'],
            ZERO_LOSS_EFFICIENCY: properties['ZERO_LOSS_EFFICIENCY'],
            FIRST_ORDER_COEFFICIENT: properties['FIRST_ORDER_COEFFICIENT'],
            SECOND_ORDER_COEFFICIENT: properties['SECOND_ORDER_COEFFICIENT'],
            INCIDENCE_ANGLE: properties['INCIDENCE_ANGLE'],
            SOLAR_TANK_VOLUME_LITRES: properties['SOLAR_TANK_VOLUME_LITRES'],
            PUMP_POWER_CONSUMPTION: properties['PUMP_POWER_CONSUMPTION'],
            STANDBY_CONSUMPTION: properties['STANDBY_CONSUMPTION'],

            // Fields for TemperatureControl productType
            // CLASS I to CLASS VIII, required property
            // TEMPERATURE_CONTROL_CLASS: product.Properties['TEMPERATURE_CONTROL_CLASS'],
            // Number, 1 decimal, required property
            // TEMPERATURE_CONTROL_EFFICIENCY: product.Properties['TEMPERATURE_CONTROL_EFFICIENCY'],
        };

        return operation;
    }

    generateSpaceHeaterXML(product: ProductWithProperties, contact: string): NestedObject {
        // Convert property array to property object
        const properties = this.getProductProperties(product);
        // Get base operation properties
        const operation = this.getBaseOperation(product, contact);

        const heaterTypes: { [key: string]: string } = {
            Boiler: 'ns5:BoilerSpaceHeater',
            Cogeneration: 'ns5:CogenerationSpaceHeater',
            'Heat pump': 'ns5:HeatPump',
            'Low-Temperature Heat pump': 'ns5:LowTemperatureHeatPump',
        };

        // Add product details to base operation
        (operation['MODEL_VERSION'] as any).PRODUCT_GROUP_DETAIL = {
            [this.formatAttributeKey('xmlns:xsi')]: 'http://www.w3.org/2001/XMLSchema-instance',
            [this.formatAttributeKey('xmlns:ns5')]: 'http://eprel.ener.ec.europa.eu/productModel/productGroups/spaceHeater/SpaceHeater/v3',
            [this.formatAttributeKey('xsi:type')]: heaterTypes[properties['Type']],
            SEASONAL_HEATING_ENERGY_EFFICIENCY: properties['SEASONAL_HEATING_ENERGY_EFFICIENCY'],
            ENERGY_ANNUAL_KWH: properties['ENERGY_ANNUAL_KWH'],
            ENERGY_ANNUAL_GJ: properties['ENERGY_ANNUAL_GJ'],
            INDOOR_NOISE: properties['INDOOR_NOISE'],
            SPECIFIC_PRECAUTIONS: product.LanguageKeys.SPECIFIC_PRECAUTIONS,
            OUTDOOR_NOISE: properties['OUTDOOR_NOISE'],
            RATED_OUTPUT_AVG_CLIM_LOW_TEMP: properties['RATED_OUTPUT_AVG_CLIM_LOW_TEMP'],
            RATED_OUTPUT_COLD_CLIM_LOW_TEMP: properties['RATED_OUTPUT_COLD_CLIM_LOW_TEMP'],
            RATED_OUTPUT_WARM_CLIM_LOW_TEMP: properties['RATED_OUTPUT_WARM_CLIM_LOW_TEMP'],
            SEASONAL_HEATING_ENERGY_EFFICIENCY_COLD_CLIM: properties['SEASONAL_HEATING_ENERGY_EFFICIENCY_COLD_CLIM'],
            SEASONAL_HEATING_ENERGY_EFFICIENCY_WARM_CLIM: properties['SEASONAL_HEATING_ENERGY_EFFICIENCY_WARM_CLIM'],
            ENERGY_ANNUAL_COLD_CLIM_KWH: properties['ENERGY_ANNUAL_COLD_CLIM_KWH'],
            ENERGY_ANNUAL_COLD_CLIM_GJ: properties['ENERGY_ANNUAL_COLD_CLIM_GJ'],
            ENERGY_ANNUAL_WARM_CLIM_KWH: properties['ENERGY_ANNUAL_WARM_CLIM_KWH'],
            ENERGY_ANNUAL_WARM_CLIM_GJ: properties['ENERGY_ANNUAL_WARM_CLIM_GJ'],
            ECO_LABEL: properties['ECO_LABEL'],
            ECO_LABEL_REGISTRATION_NUMBER: properties['ECO_LABEL_REGISTRATION_NUMBER'],
            ENERGY_CLASS_MEDIUM_TEMP: properties['ENERGY_CLASS_MEDIUM_TEMP'],
            ENERGY_CLASS_LOW_TEMP: properties['ENERGY_CLASS_LOW_TEMP'],
            RATED_OUTPUT_AVG_CLIM_MEDIUM_TEMP: properties['RATED_OUTPUT_AVG_CLIM_MEDIUM_TEMP'],
            RATED_OUTPUT_COLD_CLIM_MEDIUM_TEMP: properties['RATED_OUTPUT_COLD_CLIM_MEDIUM_TEMP'],
            RATED_OUTPUT_WARM_CLIM_MEDIUM_TEMP: properties['RATED_OUTPUT_WARM_CLIM_MEDIUM_TEMP'],
            SEASONAL_HEATING_ENERGY_EFFICIENCY_MEDIUM_TEMP: properties['SEASONAL_HEATING_ENERGY_EFFICIENCY_MEDIUM_TEMP'],
            SEASONAL_HEATING_ENERGY_EFFICIENCY_COLD_CLIM_MEDIUM_TEMP: properties['SEASONAL_HEATING_ENERGY_EFFICIENCY_COLD_CLIM_MEDIUM_TEMP'],
            SEASONAL_HEATING_ENERGY_EFFICIENCY_WARM_CLIM_MEDIUM_TEMP: properties['SEASONAL_HEATING_ENERGY_EFFICIENCY_WARM_CLIM_MEDIUM_TEMP'],
            ENERGY_ANNUAL_KWH_MEDIUM_TEMP: properties['ENERGY_ANNUAL_KWH_MEDIUM_TEMP'],
            ENERGY_ANNUAL_GJ_MEDIUM_TEMP: properties['ENERGY_ANNUAL_GJ_MEDIUM_TEMP'],
            ENERGY_ANNUAL_KWH_COLD_CLIM_MEDIUM_TEMP: properties['ENERGY_ANNUAL_KWH_COLD_CLIM_MEDIUM_TEMP'],
            ENERGY_ANNUAL_GJ_COLD_CLIM_MEDIUM_TEMP: properties['ENERGY_ANNUAL_GJ_COLD_CLIM_MEDIUM_TEMP'],
            ENERGY_ANNUAL_KWH_WARM_CLIM_MEDIUM_TEMP: properties['ENERGY_ANNUAL_KWH_WARM_CLIM_MEDIUM_TEMP'],
            ENERGY_ANNUAL_GJ_WARM_CLIM_MEDIUM_TEMP: properties['ENERGY_ANNUAL_GJ_WARM_CLIM_MEDIUM_TEMP'],
            ENERGY_CLASS: properties['ENERGY_CLASS'],
            ELECTRICAL_EFFICIENCY: properties['ELECTRICAL_EFFICIENCY'],
            RATED_OUTPUT: properties['RATED_OUTPUT'],
        };

        return operation;
    }

    generateCombinationHeaterXML(product: ProductWithProperties, contact: string): NestedObject {
        // Convert property array to property object
        const properties = this.getProductProperties(product);
        // Get base operation properties
        const operation = this.getBaseOperation(product, contact);

        const heaterTypes: { [key: string]: string } = {
            Boiler: 'ns5:BoilerCombinationHeater',
            Cogeneration: 'ns5:CogenerationCombinationSpaceHeater',
            'Heat pump': 'ns5:HeatPumpCombinationHeater',
        };

        // Add product details to base operation
        (operation['MODEL_VERSION'] as any).PRODUCT_GROUP_DETAIL = {
            [this.formatAttributeKey('xmlns:xsi')]: 'http://www.w3.org/2001/XMLSchema-instance',
            [this.formatAttributeKey('xmlns:ns5')]: 'http://eprel.ener.ec.europa.eu/productModel/productGroups/spaceHeater/SpaceHeater/v3',
            [this.formatAttributeKey('xsi:type')]: heaterTypes[properties['Type']],
            SEASONAL_HEATING_ENERGY_EFFICIENCY: properties['SEASONAL_HEATING_ENERGY_EFFICIENCY'],
            ENERGY_ANNUAL_KWH: properties['ENERGY_ANNUAL_KWH'],
            ENERGY_ANNUAL_GJ: properties['ENERGY_ANNUAL_GJ'],
            INDOOR_NOISE: properties['INDOOR_NOISE'],
            SPECIFIC_PRECAUTIONS: product.LanguageKeys.SPECIFIC_PRECAUTIONS,
            OUTDOOR_NOISE: properties['OUTDOOR_NOISE'],
            RATED_OUTPUT_AVG_CLIM_LOW_TEMP: properties['RATED_OUTPUT_AVG_CLIM_LOW_TEMP'],
            RATED_OUTPUT_COLD_CLIM_LOW_TEMP: properties['RATED_OUTPUT_COLD_CLIM_LOW_TEMP'],
            RATED_OUTPUT_WARM_CLIM_LOW_TEMP: properties['RATED_OUTPUT_WARM_CLIM_LOW_TEMP'],
            SEASONAL_HEATING_ENERGY_EFFICIENCY_COLD_CLIM: properties['SEASONAL_HEATING_ENERGY_EFFICIENCY_COLD_CLIM'],
            SEASONAL_HEATING_ENERGY_EFFICIENCY_WARM_CLIM: properties['SEASONAL_HEATING_ENERGY_EFFICIENCY_WARM_CLIM'],
            ENERGY_ANNUAL_COLD_CLIM_KWH: properties['ENERGY_ANNUAL_COLD_CLIM_KWH'],
            ENERGY_ANNUAL_COLD_CLIM_GJ: properties['ENERGY_ANNUAL_COLD_CLIM_GJ'],
            ENERGY_ANNUAL_WARM_CLIM_KWH: properties['ENERGY_ANNUAL_WARM_CLIM_KWH'],
            ENERGY_ANNUAL_WARM_CLIM_GJ: properties['ENERGY_ANNUAL_WARM_CLIM_GJ'],
            ECO_LABEL: properties['ECO_LABEL'],
            ECO_LABEL_REGISTRATION_NUMBER: properties['ECO_LABEL_REGISTRATION_NUMBER'],
            ENERGY_CLASS: properties['ENERGY_CLASS'],
            ENERGY_CLASS_MEDIUM_TEMP: properties['ENERGY_CLASS_MEDIUM_TEMP'],
            ENERGY_CLASS_LOW_TEMP: properties['ENERGY_CLASS_LOW_TEMP'],
            ELECTRICAL_EFFICIENCY: properties['ELECTRICAL_EFFICIENCY'],
            RATED_OUTPUT: properties['RATED_OUTPUT'],
            RATED_OUTPUT_AVG_CLIM_MEDIUM_TEMP: properties['RATED_OUTPUT_AVG_CLIM_MEDIUM_TEMP'],
            RATED_OUTPUT_COLD_CLIM_MEDIUM_TEMP: properties['RATED_OUTPUT_COLD_CLIM_MEDIUM_TEMP'],
            RATED_OUTPUT_WARM_CLIM_MEDIUM_TEMP: properties['RATED_OUTPUT_WARM_CLIM_MEDIUM_TEMP'],

            SEASONAL_HEATING_ENERGY_EFFICIENCY_MEDIUM_TEMP: properties['SEASONAL_HEATING_ENERGY_EFFICIENCY_MEDIUM_TEMP'],
            SEASONAL_HEATING_ENERGY_EFFICIENCY_COLD_CLIM_MEDIUM_TEMP: properties['SEASONAL_HEATING_ENERGY_EFFICIENCY_COLD_CLIM_MEDIUM_TEMP'],
            SEASONAL_HEATING_ENERGY_EFFICIENCY_WARM_CLIM_MEDIUM_TEMP: properties['SEASONAL_HEATING_ENERGY_EFFICIENCY_WARM_CLIM_MEDIUM_TEMP'],
            ENERGY_ANNUAL_KWH_MEDIUM_TEMP: properties['ENERGY_ANNUAL_KWH_MEDIUM_TEMP'],
            ENERGY_ANNUAL_GJ_MEDIUM_TEMP: properties['ENERGY_ANNUAL_GJ_MEDIUM_TEMP'],
            ENERGY_ANNUAL_KWH_COLD_CLIM_MEDIUM_TEMP: properties['ENERGY_ANNUAL_KWH_COLD_CLIM_MEDIUM_TEMP'],
            ENERGY_ANNUAL_GJ_COLD_CLIM_MEDIUM_TEMP: properties['ENERGY_ANNUAL_GJ_COLD_CLIM_MEDIUM_TEMP'],
            ENERGY_ANNUAL_KWH_WARM_CLIM_MEDIUM_TEMP: properties['ENERGY_ANNUAL_KWH_WARM_CLIM_MEDIUM_TEMP'],
            ENERGY_ANNUAL_GJ_WARM_CLIM_MEDIUM_TEMP: properties['ENERGY_ANNUAL_GJ_WARM_CLIM_MEDIUM_TEMP'],

            LOAD_PROFILE: properties['LOAD_PROFILE'],
            LOW_TEMPERATURE_APPLICATION: properties['LOW_TEMPERATURE_APPLICATION'],
            WATER_HEATING_EFFICIENCY_CLASS: properties['WATER_HEATING_EFFICIENCY_CLASS'],
            WATER_HEATING_EFFICIENCY: properties['WATER_HEATING_EFFICIENCY'],
            WATER_HEATING_EFFICIENCY_COLD_CLIM: properties['WATER_HEATING_EFFICIENCY_COLD_CLIM'],
            WATER_HEATING_EFFICIENCY_WARM_CLIM: properties['WATER_HEATING_EFFICIENCY_WARM_CLIM'],
            ONLY_FOR_OFF_PEAK_PERIODS: properties['ONLY_FOR_OFF_PEAK_PERIODS'],

            WATER_HEATING_ENERGY_ANNUAL_KWH: properties['WATER_HEATING_ENERGY_ANNUAL_KWH'],
            WATER_HEATING_ENERGY_ANNUAL_COLD_CLIM_KWH: properties['WATER_HEATING_ENERGY_ANNUAL_COLD_CLIM_KWH'],
            WATER_HEATING_ENERGY_ANNUAL_WARM_CLIM_KWH: properties['WATER_HEATING_ENERGY_ANNUAL_WARM_CLIM_KWH'],
            WATER_HEATING_ENERGY_ANNUAL_GJ: properties['WATER_HEATING_ENERGY_ANNUAL_GJ'],
            WATER_HEATING_ENERGY_ANNUAL_COLD_CLIM_GJ: properties['WATER_HEATING_ENERGY_ANNUAL_COLD_CLIM_GJ'],
            WATER_HEATING_ENERGY_ANNUAL_WARM_CLIM_GJ: properties['WATER_HEATING_ENERGY_ANNUAL_WARM_CLIM_GJ'],
        };

        return operation;
    }

    getBaseOperation(product: ProductWithProperties, contact: string): NestedObject {
        const properties = this.getProductProperties(product);
        // Unique list of files with added property technicalParts that includes all technical parts for that file
        const filesWithTechnicalParts = product.ProductFiles.map((productFile) => ({
            ...productFile.File,
            technicalParts: product.ProductFiles.filter((pf) => pf.file_id === productFile.file_id).map((pf) => pf.technical_part),
        })).filter((value, index, arr) => arr.findIndex((file) => file.id === value.id) === index);
            // A JS object containing all documents to add to the XML file.
        const TECHNICAL_DOCUMENTATION: any[] = [{
            [this.formatAttributeKey('xmlns:xsi')]: 'http://www.w3.org/2001/XMLSchema-instance',
            [this.formatAttributeKey('xsi:type')]: 'ns2:TechnicalDocumentationDetail',
            DOCUMENT: filesWithTechnicalParts.map((file) => ({
                // language of docs, can be multiple
                LANGUAGE: file.languages,
                // add all technical parts that are found in this doc ENUM: '[ADDITIONAL_PART, CALCULATIONS, GENERAL_DESCRIPTION,
                // MESURED_TECHNICAL_PARAMETERS, REFERENCES_TO_HARMONISED_STANDARDS, SPECIFIC_PRECAUTIONS, TESTING_CONDITIONS]
                TECHNICAL_PART: file.technicalParts,
                // must be in pdf, txt, docx, rtf, xlsx or pps format
                FILE_PATH: `/attachments/${file.name}`,
            })),
        }];

        // Set the product properties
        return {
            // Determines what kind of modification REGISTER_PRODUCT_MODEL, UPDATE_PRODUCT_MODEL, DECLARE_END_DATE_OF_PLACEMENT_ON_MARKET or PREREGISTER_PRODUCT_MODEL
            // For now only registrations and updates are supported
            [this.formatAttributeKey('OPERATION_TYPE')]: properties['REASON_FOR_CHANGE'] ? 'UPDATE_PRODUCT_MODEL' : 'REGISTER_PRODUCT_MODEL',
            // Unique identifier of the operation, to be defined by the supplier. Useful to match
            // the line from the final report with the line in the XML. It is a free text, but XML reserved characters
            // must be avoided (i.e. &, <, >, etc.) MAXlength: String32
            // TODO: do we declare this or does intergas want to use its own identifier?
            [this.formatAttributeKey('OPERATION_ID')]: product.id.slice(0, 32),
            // (OPTIONAL) Only needed for the operation of type "UPDATE_PRODUCT_MODEL" when the latest version of the product model is already in status
            // "PUBLISHED" in EPREL. options:
            // [CORRECT_TYPO, CHANGE_IN_STANDARDS, LABEL_SCALE_RANGE_CHANGE, CHANGE_REQUESTED_BY_MSA, ADDED_INFORMATION_NO_EFFECT_ON_DECLARATION, REQUEST_CHANGE_BY_EXTERNAL_BODY]
            [this.formatAttributeKey('REASON_FOR_CHANGE')]: properties['REASON_FOR_CHANGE'],
            // Required when updating a model
            [this.formatAttributeKey('REASON_COMMENT')]: properties['REASON_COMMENT'],
            MODEL_VERSION: {
                // Model identifier of the product model. I've not found any restriction on this or what is references.
                EPREL_MODEL_REGISTRATION_NUMBER: properties['EPREL_MODEL_REGISTRATION_NUMBER'],
                // Model identifier of the product model. I've not found any restriction on this or what is references.
                MODEL_IDENTIFIER: product.name,
                // The tm ref that was/should-be added to the eprel env, replace spaces with underscore, remove all non-alphanumberic characters
                TRADEMARK_REFERENCE: (product.trademark_ref || '').split(' ').join('_').replace(/[^a-zA-Z0-9_]/g, ''),
                // The Delegated Act is requested in order to
                // support the rescaling feature. The same product model (same supplier name/trademark, same
                // model identifier) might have to be registered multiple times with respect of successive revisions
                // of the EU delegated regulations.
                DELEGATED_ACT: 'EU_1254_2014',
                ENERGY_LABEL: {
                    [this.formatAttributeKey('xmlns:xsi')]: 'http://www.w3.org/2001/XMLSchema-instance',
                    [this.formatAttributeKey('xmlns:ns5')]: 'http://eprel.ener.ec.europa.eu/commonTypes/EnergyLabelTypes/v2',
                    [this.formatAttributeKey('xsi:type')]: 'ns5:GeneratedEnergyLabel',
                    // By setting
                    // the value of this field to "False", suppliers accept the label(s) generated by EPREL, as being
                    // relevant for compliance purposes. By setting the value of this field to "True", suppliers agree to
                    // upload their own label(s), which will be relevant for compliance purposes. In this case, uploading
                    // the suppliers' label(s) is(are) mandatory for the model to become Complete.
                    USE_SUPPLIER_UPLOADED_LABEL: 'false',
                },
                // Date on which the model will
                // be/has been placed on the market (or put into service). Since neither the public, the EC nor MSAs
                // will be able to access to product details before this date the registration will not be considered as
                // effective until the ON_MARKET_START_DATE has been reached.
                // o Time Zones: To specify a time zone, you can either enter a date in UTC time by adding
                // a "Z" behind the date - like this: <ON_MARKET_START_DATE>2002-09-
                // 24Z</ON_MARKET_START_DATE> or you can specify an offset from the UTC time by
                // adding a positive or negative time behind the date - like this:
                // <ON_MARKET_START_DATE>2002-09-24-06:00</ON_MARKET_START_DATE> or
                // <ON_MARKET_START_DATE>2002-09-24+06:00</ON_MARKET_START_DATE>
                ON_MARKET_START_DATE: this.eprelDate(properties['ON_MARKET_START_DATE']),
                ON_MARKET_END_DATE: this.eprelDate(properties['ON_MARKET_END_DATE']),
                // Either MANUFACTURER, IMPORTER or  AUTHORISED_REPRESENTATIVE (What is Intergas? is it always the same?)
                REGISTRANT_NATURE: 'MANUFACTURER',
                TECHNICAL_DOCUMENTATION,
                CONTACT_DETAILS: {
                    [this.formatAttributeKey('xmlns:xsi')]: 'http://www.w3.org/2001/XMLSchema-instance',
                    [this.formatAttributeKey('xsi:type')]: 'ns2:ContactByReference',
                    // The contact ref that was/should-be added in the eprel env check if this is always the same
                    CONTACT_REFERENCE: contact,
                },
            },
        };
    }

    getProductProperties(product: ProductWithProperties): {
        [key: string]: any;
    } {
        const propertyList = Object.entries(product.Properties);
        const properties: {
            [key: string]: any;
        } = {};
        for (const p of propertyList) {
            const key = Object.keys(p[1])[0];
            const value = Object.values(p[1])[0];
            properties[key] = value;
        }
        return properties;
    }

    /**
     * Helper function that adds a prefix to the attribute keys
     *
     * @param key Key to add a prefix to
     */
    private formatAttributeKey(key: string): string {
        return `@@${key}`;
    }

    /** Return a string containing a date in an eprel approved format */
    eprelDate(date: string): string | void {
        return date ? new Date(date).toISOString().split('T')[0] : undefined;
    }

    /**
     * This function groups the properties with the same integer in the loadprofiles
     *
     * @param product - Product to group properties of
     * @returns Object where keys are integers and values are arrays of properties with this integer
     */
    private groupLoadProfiles(product: getProductsToUpload_products): NestedObject[] {
        const loadProfileKeysArray: NestedObject[] = [];
        const max = Math.max(...product.Properties.filter((prop) => prop.key === 'LOAD_PROFILE').map((prop) => prop.integer) as number[]);

        for (let i = 0; i <= max; i++) {
            // Load profile for both declared and not declared
            const loadProfile: NestedObject = {
                LOAD_PROFILE: (product.Properties.find((object) => object.key === 'LOAD_PROFILE' && object.integer === i))?.value,
                IS_DECLARED_LOAD_PROFILE: i === 0 ? '1' : '0',
                WATER_HEATING_EFFICIENCY: (product.Properties.find((object) => object.key === 'WATER_HEATING_EFFICIENCY' && object.integer === i))?.value,
                WATER_HEATING_ANNUAL_ELECTRICITY_CONS: (product.Properties.find((object) => object.key === 'WATER_HEATING_ANNUAL_ELECTRICITY_CONS' && object.integer === i))?.value,
                WATER_HEATING_ANNUAL_ENERGY_GJ: (product.Properties.find((object) => object.key === 'WATER_HEATING_ANNUAL_ENERGY_GJ' && object.integer === i))?.value,
            };

            // These fields should only be added to the initial load profile (i === 0) accordign to eprel documentation,
            // but bizarely, the uploader of EPREL won't work if these fields are not given
            // for load profiles that aren't used, so we add it to all
            // if (i === 0) {
            Object.assign(loadProfile, {
                WATER_HEATING_ENERGY_ANNUAL_COLD_CLIM_KWH: (product.Properties.find((object) => object.key === 'WATER_HEATING_ENERGY_ANNUAL_COLD_CLIM_KWH'))?.value,
                WATER_HEATING_ENERGY_ANNUAL_COLD_CLIM_GJ: (product.Properties.find((object) => object.key === 'WATER_HEATING_ENERGY_ANNUAL_COLD_CLIM_GJ'))?.value,
                WATER_HEATING_ENERGY_ANNUAL_WARM_CLIM_KWH: (product.Properties.find((object) => object.key === 'WATER_HEATING_ENERGY_ANNUAL_WARM_CLIM_KWH'))?.value,
                WATER_HEATING_ENERGY_ANNUAL_WARM_CLIM_GJ: (product.Properties.find((object) => object.key === 'WATER_HEATING_ENERGY_ANNUAL_WARM_CLIM_GJ'))?.value,
                WATER_HEATING_EFFICIENCY_COLD_CLIM: (product.Properties.find((object) => object.key === 'WATER_HEATING_EFFICIENCY_COLD_CLIM'))?.value,
                WATER_HEATING_EFFICIENCY_WARM_CLIM: (product.Properties.find((object) => object.key === 'WATER_HEATING_EFFICIENCY_WARM_CLIM'))?.value,
            });
            // }

            loadProfileKeysArray.push(loadProfile);
        }

        return loadProfileKeysArray;
    }

    /**
     * Group all language values in 3 lists of keys and properties
     * @param product The product to group the languages for
     * @returns All language related keys
     */
    private groupLanguages(product: getProductsToUpload_products): LanguageKeys {
        const max = Math.max(...product.Properties.filter((prop) => prop.key === 'LANGUAGE').map((prop) => prop.integer) as number[]);
        const languageKeysObject: LanguageKeys = { THERMOSTAT_DEFAULT_SETTINGS: [], SMART_TEXTUAL_INDICATION: [], SPECIFIC_PRECAUTIONS: [] };

        for (let i = 0; i <= max; i++) {
            const lang = product.Properties.find((prop) => prop.integer === i && prop.key === 'LANGUAGE')?.value;
            // Smart textual indication for each language
            languageKeysObject.SMART_TEXTUAL_INDICATION.push({
                [this.formatAttributeKey('lang')]: lang,
                [this.builderTextName]: product.Properties.find((prop) => prop.integer === i && prop.key === 'SMART_TEXTUAL_INDICATION')?.value,
            });
            // Specific precautions for each language
            languageKeysObject.SPECIFIC_PRECAUTIONS.push({
                [this.formatAttributeKey('lang')]: lang,
                [this.builderTextName]: product.Properties.find((prop) => prop.integer === i && prop.key === 'SPECIFIC_PRECAUTIONS')?.value,
            });
            // thermostat default settings text for each language
            languageKeysObject.THERMOSTAT_DEFAULT_SETTINGS.push({
                [this.formatAttributeKey('lang')]: lang,
                [this.builderTextName]: product.Properties.find((prop) => prop.integer === i && prop.key === 'THERMOSTAT_DEFAULT_SETTINGS')?.value,
            });
        }

        return languageKeysObject;
    }

    private async addAttachmentsToZip(zip: JSZip, products: ProductWithProperties[], folder = 'attachments'): Promise<void> {
        // Find unique files between products
        const uniqueFiles = [
            ...new Map(
                products.flatMap((product) => product.ProductFiles
                    .map((productFile) => [productFile.File?.id, productFile.File])),
            ).values(),
        ];

        // Create blobs for all files
        const blobs = await Promise.all(uniqueFiles.map((file) => this.fileService.getFileAsBlob(file!.path)));

        // Add attachments
        uniqueFiles.forEach((file, index) => zip.file(`${folder}/${file?.name}`, blobs[index]!));
    }
}

// Override Properties of getProductsToUpload_products and create and sort them
type ProductWithProperties = Omit<getProductsToUpload_products, 'Properties'> & {
    Properties: {
        [x: string]: any;
    };
    LoadProfiles: {
        [x: string]: any;
    };
    LanguageKeys: LanguageKeys;
};

interface LanguageKeys {
    THERMOSTAT_DEFAULT_SETTINGS: { [key: string]: any }[];
    SMART_TEXTUAL_INDICATION: { [key: string]: any }[];
    SPECIFIC_PRECAUTIONS: { [key: string]: any }[];
}

interface NestedObject {
    [key: string]: string | undefined | NestedObject | NestedObject[] | null;
}
