<!-- Content visible for authenticated user -->
<div *ngIf="(connection.client.isAuthenticated | async) && user.role" fxLayout="column" class="full-height">

    <!-- Sidenav and content -->
    <mat-drawer-container fxFlex>

        <!-- Sidenav -->
        <mat-drawer mode="side" opened>
            <div fxLayout="column" class="sidenav-content-container">
                <img alt="Intergas Logo" src="../assets/img/intergas-logo.png">

                <mat-nav-list fxFlex fxLayout="column" disableRipple="true">
                    <!-- Overview -->
                    <div mat-subheader i18n>Pages</div>

                    <!-- Users -->
                    <mat-list-item routerLink="/products" routerLinkActive="link-active">
                        <mat-icon matListItemIcon>heat_pump</mat-icon>
                        <div matListItemTitle i18n>Products</div>
                    </mat-list-item>

                    <!-- Users -->
                    <mat-list-item routerLink="/users" routerLinkActive="link-active"
                        *appAccess="{ features: ['manage_permissions']}">
                        <mat-icon matListItemIcon>people</mat-icon>
                        <div matListItemTitle i18n>Users</div>
                    </mat-list-item>

                    <!-- Permissions -->
                    <mat-list-item routerLink="/permissions" routerLinkActive="link-active"
                        *appAccess="{ features: ['manage_permissions']}">
                        <mat-icon matListItemIcon>key</mat-icon>
                        <div matListItemTitle i18n>Permissions</div>
                    </mat-list-item>

                    <!-- Files -->
                    <mat-list-item routerLink="/files" routerLinkActive="link-active">
                        <mat-icon matListItemIcon>insert_drive_file</mat-icon>
                        <div matListItemTitle i18n>Files</div>
                    </mat-list-item>

                </mat-nav-list>

                <!-- User info -->
                <div fxLayout="row" class="user-info" fxLayoutGap="8px">
                    <!-- Profile picture -->
                    <div class="profile-picture">
                        <mat-icon>account_circle</mat-icon>
                    </div>
                    <!-- Name and logout button -->
                    <div fxLayout="column">
                        <p class="name">{{user.profile.display_name}}</p>
                        <p class="logout" (click)="connection.client.logout()">Logout</p>
                    </div>
                </div>
            </div>
        </mat-drawer>

        <!-- Content  -->
        <mat-drawer-content>
            <div class="content">

                <!-- Breadcrumbs -->
                <app-breadcrumbs></app-breadcrumbs>

                <!-- Router outlet -->
                <div class="outlet-container" fxLayout="column" fxLayoutAlign="start center">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </mat-drawer-content>
    </mat-drawer-container>

</div>

<!-- Content visible for non-authenticated user -->
<div *ngIf="(connection.client.isAuthenticated | async) === false" fxLayout="column" fxLayoutAlign="center center">
    <p i18n> Login failed</p>
    <button mat-stroked-button (click)="connection.client.logout()">Logout</button>
</div>