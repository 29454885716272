<!-- Page title -->
<h1 i18n>Users</h1>

<!-- Mat table displaying permissions with checkboxes -->
<table mat-table [dataSource]="users">

    <!-- Name column -->
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef i18n> Name </th>
        <td mat-cell *matCellDef="let user">
            {{user.display_name}}
        </td>
    </ng-container>

    <!-- Groups column -->
    <ng-container matColumnDef="groups">
        <th mat-header-cell *matHeaderCellDef i18n> Roles </th>
        <td mat-cell *matCellDef="let user">
            <div fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="row wrap" fxLayoutGap="8px grid" fxLayoutAlign="start center">
                    <mat-chip routerLink="/permissions/groups/{{userGroup.Group.id}}" routerLinkWrap
                        *ngFor="let userGroup of user.UserGroups">{{userGroup.Group.name}}</mat-chip>
                    <i *ngIf="!user.UserGroups.length">None</i>
                </div>
                <button mat-icon-button (click)="editUserGroups(user)">
                    <mat-icon>edit</mat-icon>
                </button>
            </div>

            <!-- Select groups dialog -->
            <app-dynamic-dialog>
                <h1 title i18n>Change roles</h1>

                <div fxLayout="column" fxLayoutGap="16px">
                    <p i18n>Change what roles {{user.display_name}} belongs to. These changes impact what users are
                        allowed to do in the system
                    </p>

                    <mat-form-field>
                        <mat-label i18n>Roles</mat-label>
                        <mat-select multiple [formControl]="userGroupControl">
                            <mat-option *ngFor="let group of groups$ | async"
                                [value]="group.id">{{group.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!-- Confirmation button -->
                <button confirm-button mat-flat-button color="primary" i18n>Update
                    roles</button>

            </app-dynamic-dialog>
        </td>
    </ng-container>

    <!-- Permissions column -->
    <ng-container matColumnDef="permissions">
        <th mat-header-cell *matHeaderCellDef i18n> Permissions </th>
        <td mat-cell *matCellDef="let user">
            <i *ngIf="!user.Permissions.length" i18n>
                None
            </i>
            <ul>
                <li *ngFor="let permission of user.Permissions" [matTooltip]="permission.Feature.description"
                    matTooltipShowDelay="300">
                    {{permission.Feature.name}}
                </li>
            </ul>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
</table>