import {
    AfterViewInit, Component, EventEmitter, Input, OnChanges, SimpleChanges, ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    EMPTY,
    catchError,
    map, merge, startWith, switchMap,
    tap,
} from 'rxjs';
import {
    ApolloService, Order_By, getProductUpdates_updates, getProduct_products_by_pk,
} from 'src/gql-generated/generated';

@UntilDestroy()
@Component({
    selector: 'app-product-history',
    templateUrl: './product-history.component.html',
    styleUrls: ['./product-history.component.scss'],
})
export class ProductHistoryComponent implements AfterViewInit {
    /**
     * Paginator reference
     */
    @ViewChild(MatPaginator) paginator?: MatPaginator;

    /**
     * Mat sort reference
     */
    @ViewChild(MatSort) sort?: MatSort;

    /**
     * Product id fetched from URL
     */
    productId = this.route.snapshot.paramMap.get('productId');

    /**
     * Observable that emits the product related to productId
     */
    @Input() product?: getProduct_products_by_pk;

    /**
     * List of updates related to product
     */
    updates: getProductUpdates_updates[] = [];

    /**
     * Columsn to display in updates table
     */
    tableColumns = ['date', 'user', 'action', 'changes'];

    /**
     * Eventemitter to fire when something changes so the product history needs to be reloaded
     */
    update = new EventEmitter<void>();

    constructor(public route: ActivatedRoute, private apollo: ApolloService, private snackBar: MatSnackBar) {
    }

    /**
     * Load data
     */
    ngAfterViewInit(): void {
        if (this.sort && this.paginator && this.productId) {
            // Get data everytime sort or paginator changes
            merge(
                this.update,
                // Reset paginator when sort changes
                this.sort.sortChange.pipe(
                    tap(() => { this.paginator!.pageIndex = 0; }),
                ),
                this.paginator.page,
            ).pipe(
                // Trigger first time data loading
                startWith(null),

                // Get product updates via apollo query
                switchMap(() => this.apollo.getProductUpdates({
                    // Only include results related to the product
                    where: { product_id: { _eq: this.productId } },
                    limit: this.paginator!.pageSize,
                    offset: this.paginator!.pageIndex * this.paginator!.pageSize,
                    // Ad sorting if active
                    ...(this.sort?.active === 'date' && { order_by: [{ time: (this.sort.direction || 'asc') as Order_By }] }),
                    ...(this.sort?.active === 'user' && {
                        order_by: [
                            // If user sort is active, also apply secondary sort on time (desc)
                            { UpdatedBy: { display_name: (this.sort.direction || 'asc') as Order_By } },
                            { time: 'desc' as Order_By },
                        ],
                    }),
                }).pipe(
                    // Catch apollo error and return EMPTY observable to keep outer observable alive
                    catchError(() => {
                        this.snackBar.open('Could not load updates', 'Close');
                        return EMPTY;
                    }),
                    // Update paginator length
                    tap((result) => { this.paginator!.length = result.data.updates_aggregate.aggregate?.count; }),
                    // Map result to updates
                    map((result) => result.data.updates),
                )),
                // Automatically unsubscribe on component destruction
                untilDestroyed(this),
            ).subscribe((updates) => {
                // Store updates in local variable
                this.updates = updates;
            });
        }
    }
}
