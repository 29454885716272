import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot, Router, UrlTree,
} from '@angular/router';
import { HasuraRole, UserService } from './user.service';

@Injectable({
    providedIn: 'root',
})
export class AccessService {
    constructor(private user: UserService, private userService: UserService, private router: Router) { }

    /**
     * Check if a user has access based on the requirements defined in the appAccess object
     * @param appAccess Object defining requirements that user should pass
     * @returns True if user has access, false otherwise
     */
    public hasAccess(appAccess: AppAccess): boolean {
        // Check hasura role (allow hasura admins to access everything)
        if (appAccess.role && !(this.user.role === appAccess.role) && this.user.role !== 'admin') {
            return false;
        }
        // Check feature
        if (appAccess.feature && !this.user.profile.Permissions
            .find((permission) => permission.Feature?.id === appAccess.feature)) {
            return false;
        }
        // Check features
        if (appAccess.features && !appAccess.features.every((feature) => this.user.profile.Permissions
            .find((permission) => permission.Feature?.id === feature))) {
            return false;
        }
        // Check featuresOr
        if (appAccess.featuresOr && !appAccess.featuresOr.some((feature) => this.user.profile.Permissions
            .find((permission) => permission.Feature?.id === feature))) {
            return false;
        }
        // If all checks passed, return true
        return true;
    }

    async canActivate(route?: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
        // If user is authenticated but user object is not yet set, wait for it
        if (!this.userService.initialized.value) {
            await new Promise<void>((resolve) => {
                this.userService.initialized.subscribe((init) => {
                    if (init) {
                        resolve();
                    }
                });
            });
        }
        // Match appAccess if provided
        // If access check fails, remain on same page or go to home when redirecting after login failed (still on magic link page)
        if (route?.data && !this.hasAccess(route.data)) {
            return this.router.parseUrl('/products');
        }

        // If all checks passed, return true
        return true;
    }
}

/**
 * Defines the requirements that can be set to limit access
 */
export interface AppAccess {
    // User must be this hasura role
    role?: HasuraRole;
    // User must have permissions that allow this feature
    feature?: string;
    // User must have permissions that allow all these features
    features?: string[];
    // User must have permissions that allows one of these features
    featuresOr?: string[];
}
