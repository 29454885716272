import { TextFieldModule } from '@angular/cdk/text-field';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { ApolloModule } from 'apollo-angular';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AttachmentUploadComponent } from './components/attachment-upload/attachment-upload.component';
import { AttachmentComponent } from './components/attachment/attachment.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { DynamicDialogComponent } from './components/dynamic-dialog/dynamic-dialog.component';
import { PermissionsComponent } from './components/permissions/permissions.component';
import { ProductAttachmentsComponent } from './components/products/product-attachments/product-attachments.component';
import { ProductDetailComponent } from './components/products/product-detail/product-detail.component';
import { ProductHistoryComponent } from './components/products/product-history/product-history.component';
import { ProductComponent } from './components/products/product/product.component';
import { ProductsComponent } from './components/products/products.component';
import { RoleEditComponent } from './components/role-edit/role-edit.component';
import { SmartSelectComponent } from './components/smart-select/smart-select.component';
import { UsersComponent } from './components/users/users.component';
import { AccessDirective } from './directives/access.directive';
import { RouterLinkWrapDirective } from './directives/router-link-wrap.directive';
import { ProductDetailInputComponent } from './components/products/product-detail/product-detail-input/product-detail-input.component';
import { FileSizePipe } from './pipes/file-size.pipe';
import { FilesComponent } from './components/files/files.component';

@NgModule({
    declarations: [
        AppComponent,
        PermissionsComponent,
        RoleEditComponent,
        DynamicDialogComponent,
        UsersComponent,
        AccessDirective,
        RouterLinkWrapDirective,
        BreadcrumbsComponent,
        ProductsComponent,
        ProductComponent,
        ProductDetailComponent,
        ProductDetailInputComponent,
        ProductHistoryComponent,
        ProductAttachmentsComponent,
        AttachmentUploadComponent,
        AttachmentComponent,
        FileSizePipe,
        SmartSelectComponent,
        FilesComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        ApolloModule,
        BrowserAnimationsModule,
        MatButtonModule,
        MatInputModule,
        MatRadioModule,
        MatCheckboxModule,
        MatPaginatorModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        MatSnackBarModule,
        MatTableModule,
        MatIconModule,
        MatTabsModule,
        MatFormFieldModule,
        TextFieldModule,
        MatDialogModule,
        MatCardModule,
        MatTooltipModule,
        MatChipsModule,
        MatSelectModule,
        MatListModule,
        MatSidenavModule,
        MatSortModule,
        MatProgressBarModule,
        MatNativeDateModule,
        MatDatepickerModule,
        HttpClientModule,
        MatProgressSpinnerModule,
        ScrollingModule,
    ],
    providers: [
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } },
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
