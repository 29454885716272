import {
    Directive, ElementRef,
    Renderer2,
} from '@angular/core';
import { RouterLink } from '@angular/router';

@Directive({
    selector: '[routerLinkWrap]',
})
export class RouterLinkWrapDirective {
    constructor(private el: ElementRef, private renderer: Renderer2, private routerLink: RouterLink) {}

    ngOnInit(): void {
        if (this.el && this.routerLink) {
            // Create href from urlTree
            const href = this.routerLink.urlTree?.root.children['primary'].segments.map((segment) => segment.path).join('/')!;

            // Create new <a> tag, set the href and class
            const linkElement = this.renderer.createElement('a');
            this.renderer.setAttribute(linkElement, 'href', href);
            this.renderer.addClass(linkElement, 'router-link-wrapper');

            // Add click event handler to disable left click. This prevents the browser from doing a 'real' redirect causing
            // a page refresh.
            this.renderer.listen(linkElement, 'click', (event: MouseEvent) => {
                if (event.button === 0) {
                    event.preventDefault();
                }
            });

            // Move the content of the element inside the new <a> tag and append it in the right place
            const parent = this.renderer.parentNode(this.el.nativeElement);
            this.renderer.insertBefore(parent, linkElement, this.el.nativeElement);
            this.renderer.appendChild(linkElement, this.el.nativeElement);
        }
    }
}
